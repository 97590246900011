import React from 'react';
import {handleCloseModal, handleForgotModal, login} from '../../actions/auth'
import Form from "./Form";
import {connect} from "react-redux";
import {Container, HeaderForget, HeaderForgetContainer, Wrapper} from "./styles";
import {Row} from "../styles";
import {Button, Input, InputContainer} from "finform";
import {NotifyManager} from "notify-component";
import {handleTitle} from "#app/actions/interface";
import LocalStorageHelper from "#app/helpers/Cache/LocalStorageHelper";

class Login extends Form {
  constructor(props) {
    super(props);

    let email = localStorage.getItem(LocalStorageHelper.AUTH_EMAIL) || '';

    this.state = {
      ...this.state,
      form: {
        email: email,
        password: '',
        captchaValue: '',
        form: 'client'
      },
      type: '',
      formErrors: {},
      submitted: false,
      checked: false
    };

  }

  submitForm()
  {
    this.setState({
      submitted: true
    }, () => {
      this.props.login({
        form: this.state.form,
        success: (response, status, xhr) => {
          var header = xhr.getResponseHeader('Authorization');

          if(header)
          {
            localStorage.setItem(LocalStorageHelper.API_TOKEN, header);
            
            this.props.handleCloseModal();

            this.setState({
              form: {
                email: '',
                password: '',
              },
              submitted: false
            }, () => {
              if(typeof this.props.callback === 'function')
              {
                this.props.callback();
              }
            });
          }else{
            NotifyManager.error('Ошибка авторизации')
          }
        },
        error: (xhr, responseData) => {
          if(this.state.type === '')
          {
            let enable = false;

            if (responseData?.meta?.errors?.code?.[0])
            {
              if(responseData.meta.errors.code[0] === 'Неверный 2FA код')
              {
                enable = true
              }

              this.setState({
                type: enable ? '2fa' : '',
                submitted: false
              });
            }
          }else{
            this.setState({
              submitted: false
            });
          }
        },
        obj: this,
      });
    });
  }

  handleCheckbox(e)
  {
    e.preventDefault();
    this.setState({
      checked: !this.state.checked
    })
  }

  handleEnter(e)
  {
    if (e.key === 'Enter' || e.keyCode === 13) {
      this.submitForm();
    }
  }

  render() {
    return <Wrapper>
      <Container>
        <Row>
          <InputContainer error={this.getError('email')}>
            <Input
                id='login-email'
                name="email"
                onKeyDown={this.handleEnter.bind(this)}
                autoComplete='on'
                value={this.state.form.email}
                onChange={(value) => {
                  this.setState((prv) => {
                    prv.form.email = value;
                    if(prv.formErrors)
                    {
                      prv.formErrors.email = {};
                    }

                    return prv;
                  });
                }}
                placeholder={'Email'}
            />
          </InputContainer>
        </Row>
        <Row>
          <InputContainer error={this.getError('password')}>
            <Input
                id='login-password'
                type="password"
                name="password"
                onKeyDown={this.handleEnter.bind(this)}
                value={this.state.form.password}
                onChange={(value) => {
                  this.setState((prv) => {
                    prv.form.password = value;
                    if(prv.formErrors)
                    {
                      prv.formErrors.password = {};
                    }

                    return prv;
                  });
                }}
                placeholder={'Пароль'}
            />
          </InputContainer>
        </Row>
        {this.state.type === '2fa' && <Row>
          <InputContainer error={this.getError('code')}>
            <Input
                id='login-code'
                type="code"
                name="code"
                value={this.state.form.code}
                onChange={(value) => {
                  this.setState((prv) => {
                    prv.form.code = value;
                    if(prv.formErrors)
                    {
                      prv.formErrors.code = {};
                    }

                    return prv;
                  });
                }}
                placeholder={this.props.i18n.auth.fa}
            />
          </InputContainer>
        </Row>}
        <Row>
          <HeaderForgetContainer>
            <HeaderForget id={'forgot-button'} onClick={(e) =>
            {
              e.preventDefault();

              this.props.handleForgotModal();
            }}>{this.props.i18n.auth.forgotPassword}</HeaderForget>
          </HeaderForgetContainer>
        </Row>
        <Row>
          <Button wide id='login-submit' onClick={() => {
            this.submitForm();
          }}>{this.props.i18n.auth.enter}</Button>
        </Row>
      </Container>
    </Wrapper>
  }
}

const enhance = connect(
  state => ({
    i18n: state.i18n,
  }),
  { login, handleTitle, handleForgotModal, handleCloseModal}
)

export {Login};

export default enhance(Login)

