import styled from 'styled-components'
import {scrollVerticalSharedStyle} from "../../../../interface/scroll";

export const Container = styled.div`
  display: block;
  flex-wrap: wrap;
  width: 100%;
  flex: 1;
  margin-top: 15px;
  font-size: 14px;
  align-items: center;
  flex-basis: 100%;
  max-height: 380px;
  overflow-y: auto;
  
  ${scrollVerticalSharedStyle}
`
export const Progress = styled.div`
  height: 8px;  /* Can be anything */
	position: relative;
	background: #D2D1D1;
	border-radius: 8px;
	margin: 16px 0 0 30px;
	width: 100%;
	
	span{
    display: block;
    height: 100%;
    border-radius: 8px;
    background-color: #4378FF;
    position: relative;
    overflow: hidden;
	}
`

export const Number = styled.div`
  margin: 10px 0 0 30px;
  color: #4378FF;
  font-size: 16px;
`
export const Sign = styled.div`
  width: 32px;
  display: flex;
  alignItems: center;
  
  img{
    margin: 0 auto;
  }
`