import styled from 'styled-components'
import {scrollVerticalSharedStyle} from "../../../interface/scroll";

export const AccountContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  
  ${scrollVerticalSharedStyle}
`

export const Number = styled.div`
  font-size: 24px;
  line-height: 72px;
  color: #000;
  width: 100%;
  text-align: right;
  margin: 0 30px;
  font-weight: bold;
`
export const DeleteContainer = styled.div`

  align-items: center;
  height: 40px;
  text-align: left;
  
  img{
    display: inline-block;
    width: 24px;
    height: 24px;
    cursor: pointer;
    vertical-align: middle;
    margin-top: 5px;
    margin-left: 20px;
    margin-right: 18px;
  }
  
  h2{
    font-size: 18px;
    margin-left: 20px;
    display: inline-block;
  }
`

export const Title = styled.h1`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  color: #7F818D;
  
  ${props => props.flex && `
    flex: 1;
  `}
`


export const SubContainer = styled.div`
  //margin: 10px auto 0 10px;
  width: 192px;
  height: 102px;
  border-radius: 24px;
  text-align: center;
  align-items: center;
 
  
      
  &.bks{
    background: linear-gradient(270deg, #162634 0%, #003FC7 100%);
     margin-left: 0!important;
  }
  
  &.aton{
    background: linear-gradient(270deg, #5E3AA9 0%, #ECB3C9 100%);
    margin-left: 24px!important;
  }
  
  & > div > a{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    flex: 1;
    margin-left: 21px;
    text-align: left;
    cursor: pointer;
  }
  
  & > div{
    display: flex;
    flex-wrap: nowrap;
    margin-top: 5px;
  }
  
  span{
    height: 24px;
    width: 24px;
    border-radius: 72px;
    background-color: #F5F5F5;
    line-height: 24px!important;
    flex-basis: 24px;
    margin: 0 10px;
    cursor: pointer;
  }
  
  img.bks{
    margin-top: 15px;
    margin-right: 20px;
  }
  
  img.aton{
    margin-top: 7px;
  }
`
