import React from 'react';
import {Row} from "#app/pages/styles";
import {GroupSelect, InputContainer} from "finform";
import {getAccounts} from "#app/pages/Relations/Trades/form/utils/accounts";
import {Account, AccountConstants, ActiveConstants} from "finhelper";
import AddAccountButton from "#app/pages/Relations/components/AddAccountButton";
import SelectAccountWrapper from "#app/pages/Relations/components/SelectAccountWrapper";

const CommonAccounts = ({
                            accounts,
                            fromAccountId,
                            setFromAccountId,
                            catalogItem,
                            getError
                          }) => {

    let groupedAccounts = getAccounts(accounts)

    let fromAccounts = [];
    let types = [];

    switch (catalogItem?.type_id)
    {
        case ActiveConstants.CRYPTO:
            types = [AccountConstants.DIGIT_MONEY];
            fromAccounts = Account.getFiltered(fromAccountId, groupedAccounts, types);
            break;
        default:
            types = [AccountConstants.BROKER_ACCOUNT, AccountConstants.BANK_ACCOUNT];
            fromAccounts = Account.getFiltered(fromAccountId, groupedAccounts, types);
            break;
    }

    return <Row>
        <SelectAccountWrapper
            types={types}
            placeholder='Счет списания'
            id={'from_account'}
            value={fromAccountId}
            onChange={(item) => {
                if(item)
                {
                    setFromAccountId(item.id);
                }
            }}
            options={fromAccounts}
            error={getError('from_account_id')}
        />
    </Row>
}

export default CommonAccounts;

