import React, {useEffect} from 'react';
import {GroupSelect, InputContainer} from "finform";
import {Account, AccountConstants, ActiveConstants, TradeConstants} from "finhelper";
import {getAccounts} from "#app/pages/Relations/Trades/form/utils/accounts";
import {setAccountModalVisible, setAvailableTypes} from "#app/actions/account";
import AddAccountButton from "#app/pages/Relations/components/AddAccountButton";
import SelectAccountWrapper from "#app/pages/Relations/components/SelectAccountWrapper";

const GetAccount = ({
                        accounts,
                        typeId,
                        fromAccountId,
                        getAccountId,
                        setGetAccountId,
                        catalogItem,
                        getError
                      }) => {

    //если сменили тип на продажу или тип актива не акция или облигация, то нужно очищать поле getAccountId
    useEffect(() => {
        if(typeId === TradeConstants.SELL)
        {
            setGetAccountId('');
        }

        if(catalogItem && catalogItem.type_id && [
            ActiveConstants.CURRENCY
        ].indexOf(catalogItem.type_id) !== -1)
        {
            setGetAccountId('');
        }
    }, [typeId, catalogItem])

    //если указали счет покупки, а счет зачисления пуст, то автоматически проставим счет зачисления
    useEffect(() => {
        if(fromAccountId && !getAccountId)
        {
            setGetAccountId(fromAccountId);
        }
    }, [fromAccountId])



    if(typeId === TradeConstants.SELL)
    {
        return <div style={{flex: '1'}}></div>;
    }

    if(catalogItem && catalogItem.type_id && [
        ActiveConstants.CURRENCY
    ].indexOf(catalogItem.type_id) !== -1)
    {
        return <div style={{flex: '1'}}></div>;
    }


    let groupedAccounts = getAccounts(accounts)

    let filteredAccounts = [];
    let types = [];

    switch (catalogItem?.type_id)
    {
        case ActiveConstants.CRYPTO:
            types = [AccountConstants.DIGIT_MONEY];
            filteredAccounts = Account.getFiltered(fromAccountId, groupedAccounts, types);
            break;
        default:
            types = [AccountConstants.BROKER_ACCOUNT, AccountConstants.BANK_ACCOUNT];
            filteredAccounts = Account.getFiltered(fromAccountId, groupedAccounts, types);
            break;
    }


    //поле используется для акций и облигаций, где есть выплаты
    return <SelectAccountWrapper
                types={types}
                placeholder='Счет зачисления'
                id={'get_account'}
                value={getAccountId}
                onChange={(item) => {
                    if(item)
                    {
                        setGetAccountId(item.id);
                    }
                }}
                options={filteredAccounts}
                error={getError('get_account_id')}
            />

}

export default GetAccount;

