import {createReducer} from 'redux-act'

import * as actions from "#app/actions/trade";

let trade = {
  modalVisible: false,
  form: {}
};

const setTradeModalVisible = (state, bool) => {
  return {
    ...state,
    modalVisible: bool,
  }
}

const setTradeForm = (state, form) => {
  return {
    ...state,
    form: form
  }
}

const reducer = createReducer({
  [actions.setTradeModalVisible]: setTradeModalVisible,
  [actions.setTradeForm]: setTradeForm,
}, trade)

export default reducer
