import React, {useEffect, useRef, useState} from "react";
import ModalForm from "#app/components/ModalForm/ModalForm";
import {connect} from "react-redux";
import {Row} from "#app/pages/styles";
import {Button, Input, InputContainer, Search, Select} from "finform";
import DeleteButton from "#app/pages/Relations/components/DeleteButton";
import {AccountConstants} from "finhelper";
import useAccountActions from "#app/pages/Relations/Accounts/hooks/useAccountActions";
import {useAccountForm} from "#app/pages/Relations/Accounts/hooks/useAccountForm";
import {Api} from "laravel-request";
import SubAccount from "#app/pages/Relations/Accounts/form/parts/SubAccount";
import SumAccounts from "#app/pages/Relations/Accounts/form/parts/SumAccounts";
import {Title2} from "#app/components/Text/styles";
import useAccountDemoActions from "#app/pages/Relations/Accounts/hooks/useAccountDemoActions";
import HiddenInput from "#app/pages/Relations/components/HiddenInput";

//TODO нет deposits
//TODO нет восстановления restore
//TODO нет логики для работы в демо режиме, заглушить созранение на сервер и апдейт

const getAccountActionsHook = (isDemo: boolean) => {
    if (isDemo) {
        return useAccountDemoActions;
    }
    return useAccountActions;
};

// @ts-ignore
const AccountForm = ({
                       modalVisible,
                       setModalVisible,
                       form,
                       setForm,
                       callback,

                       client,
                       availableTypes,
                       i18n,
                       demo,
                       tutorial
}: any) => {

    if (!modalVisible) {
        return null;
    }

    if (!client) {
        return null;
    }

    const useAccountActionMethod = getAccountActionsHook(demo || tutorial);

    const [banks, setBanks] = useState([]);
    const [brokers, setBrokers] = useState([]);
    const [types, setTypes] = useState(AccountConstants.types());
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        //отфильтруем
        if (!availableTypes || availableTypes.length === 0) {
            setTypes(AccountConstants.types());
        } else {
            setTypes(
                AccountConstants.types().filter((item: any) =>
                    availableTypes.includes(item.id)
                )
            );
        }
    }, [availableTypes]);

    const {
        id, setId,
        userId, setUserId,
        typeId, setTypeId,
        name, setName,
        bankId, setBankId,
        customBankId, setCustomBankId,
        subaccounts, setSubaccounts,
        searchBank, setSearchBank,

        formErrors, changeFormErrors,
        hasError, getError,
        closeForm, clearForm,
    } = useAccountForm(form, client, setModalVisible, setForm, types, banks, brokers);

    const handleSearchValidation = (searchText: string) => {
        const options = typeId === AccountConstants.BANK_ACCOUNT ? banks : brokers;

        // Check if the search text matches any option
        const matchedOption = options.find(option =>
            // @ts-ignore
            option.name.toLowerCase() === searchText.toLowerCase()
        );

        if (matchedOption) {
            // If a match is found, update bankId and customBankId
            // @ts-ignore
            setBankId(matchedOption.id);
            // @ts-ignore
            setCustomBankId(matchedOption.type_id || '');
        } else {
            // If no match is found, reset bankId and customBankId
            setBankId('');
            setCustomBankId('');
        }
        setSearchBank(searchText)
    };

    // Храним предыдущее значение typeId
    const prevTypeIdRef = useRef();

    useEffect(() => {
        //если поменяли typeId значит нужно очистить его bankId и customBankId и searchBank

        if (prevTypeIdRef.current !== undefined)
        {
            setBankId('')
            setCustomBankId('')
            setSearchBank('')
        }

        // @ts-ignore
        prevTypeIdRef.current = typeId;

    }, [typeId]);

    useEffect(() => {
        //догружаем только если нужен банковский счет
        if(typeId === AccountConstants.BANK_ACCOUNT)
        {
            Api.get('user-account', 'banks', { user_id: client.id }).call((responseBanks: any) => {
                setBanks(responseBanks.data);
            });
        }
    }, [client.id, typeId]);

    useEffect(() => {
        //догружаем только если нужен брокерский счет
        if(typeId === AccountConstants.BROKER_ACCOUNT)
        {
            Api.get('user-account', 'brokers', {user_id: client.id}).call((responseBrokers: any) => {
                setBrokers(responseBrokers.data);
            });
        }
    }, [client.id, typeId]);

    const { handleDelete, handleRestore, handleUpdate, handleCreate, addSubAccount, deleteSubAccount } = useAccountActionMethod(
        id, setId,
        userId,
        //@ts-ignore
        typeId, setTypeId,
        name, setName,
        bankId, setBankId,
        customBankId, setCustomBankId,
        subaccounts, setSubaccounts,

        searchBank,
        closeForm, callback,
        changeFormErrors,
        loading, setLoading
    );

    const updateSubaccount = (index: number, updatedFields: any) => {
        setSubaccounts((prevSubaccounts) =>
            prevSubaccounts.map((item, i) =>
                i === index ? { ...item, ...updatedFields } : item
            )
        );
    };

    const renderButtons = () => {
        if (id) {
            return <Row>
                <DeleteButton handleDelete={handleDelete} />
                <Row style={{ display: 'block', textAlign: 'center' }}>
                    <Button type="cancel" withMargin narrow onClick={closeForm} id='create-account-cancel'>
                        Отмена
                    </Button>
                    <Button withMargin narrow loading={loading} onClick={handleUpdate} id='create-account-save'>
                        Сохранить
                    </Button>
                </Row>
            </Row>
        } else {
            return <Row style={{ display: 'block', textAlign: 'center' }}>
                <Button type="cancel" withMargin narrow onClick={closeForm} id='create-account-cancel'>
                    Отмена
                </Button>
                <Button withMargin narrow loading={loading} onClick={handleCreate} id='create-account-save'>
                    Сохранить
                </Button>
            </Row>
        }
    }


    // @ts-ignore
    return <ModalForm
        header={
            id
                ? 'Редактирование счёта'
                : 'Новый счёт'
        }
        modalVisible={modalVisible}
        closeForm={closeForm}
        width='886px'
        id='account-form'
    >
        <Row>
            <HiddenInput id='account-id' value={id}/>
            <InputContainer style={{border: 'none'}} error={getError('type_id')}>
                <Select
                    disabled={!!id}
                    id={'type'}
                    name='type_id'
                    value={typeId}
                    onChange={(item: any) => {
                        if(item){
                            setTypeId(item.id)
                        }
                    }}
                    options={types}
                    placeholder={i18n.accounts.typeAccount}
                />
            </InputContainer>
            {[AccountConstants.BANK_ACCOUNT, AccountConstants.BROKER_ACCOUNT].indexOf(typeId) !== -1 &&<InputContainer error={getError('bank_id')}>
                <Search
                    name="bank_id"
                    id='bank'
                    clearOnClickOutside={false}
                    placeholder={typeId === AccountConstants.BANK_ACCOUNT ? i18n.accounts.bankName : i18n.accounts.brokerName}
                    options={typeId === AccountConstants.BANK_ACCOUNT ? banks : brokers}
                    value={bankId}
                    onChange={(searchInput: any): void => {
                        if (searchInput) {
                            //тут могут быть кастомные бумаги, только у них есть поле searchInput.type_id
                            if(searchInput.type_id)
                            {
                                setBankId(searchInput.id)
                                setCustomBankId(searchInput.type_id)
                            }else{
                                setBankId(searchInput.id)
                                setCustomBankId('')
                            }

                            setSearchBank(searchInput.name)
                        }else{
                            setBankId('')
                            setCustomBankId('')
                            setSearchBank('')
                        }
                    }}
                    search={searchBank}
                    onSearch={handleSearchValidation}
                />
            </InputContainer>}
            <InputContainer error={getError('name')}>
                <Input
                    id='account-name'
                    name='account-name'
                    value={name}
                    onChange={setName}
                    placeholder={i18n.accounts.nameAccount}
                />
            </InputContainer>
        </Row>
        <Row>
            <Title2 style={{marginLeft: '10px'}}>Субсчета</Title2>
            {subaccounts.map((item: any, index: number) => {
                return <SubAccount
                    key={index}
                    number={index}
                    item={item}
                    showDelete={index > 0}
                    getError={getError}
                    deleteSubAccount={deleteSubAccount}
                    onUpdate={(updatedFields: any) => updateSubaccount(index, updatedFields)}
                />
            })}
        </Row>
        <Row>
            <div style={{flex: '1'}}/>
            <Button id='add-sub-account' style={{minWidth: '340px'}} flex onClick={addSubAccount}>{i18n.accounts.addSubAccount}</Button>
        </Row>
        <SumAccounts subaccounts={subaccounts} deposits={[]} />
        {renderButtons()}
    </ModalForm>
}


const enhance = connect(
    (state: any) => ({
        client: state.interfaceComponents.client,
        currencies: state.interfaceComponents.currencies,
        accounts: state.account.accounts,
        availableTypes: state.account.availableTypes,
        i18n: state.i18n,
        demo: state.scenario.demo,
        tutorial: state.scenario.tutorial,
    }),
    {}
);

export default enhance(AccountForm);