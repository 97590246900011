import {createReducer} from 'redux-act'

import * as actions from "#app/actions/payment";

let payment = {
  modalVisible: false,
  form: {}
};

const setPaymentModalVisible = (state, bool) => {
  return {
    ...state,
    modalVisible: bool,
  }
}

const setPaymentForm = (state, form) => {
  return {
    ...state,
    form: form
  }
}

const reducer = createReducer({
  [actions.setPaymentModalVisible]: setPaymentModalVisible,
  [actions.setPaymentForm]: setPaymentForm,
}, payment)

export default reducer
