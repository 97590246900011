import {Row} from "#app/pages/styles";
import React, {useMemo, useState} from "react";
import {InputContainer, Select} from "finform";
import {CurrencyConstants, Money} from "finhelper";
import {connect} from "react-redux";
import {Number} from "#app/pages/Accounting/Accounts/styles";

const SubAccount = ({
    subaccounts,
    deposits,
    currencies,
    i18n
}) =>
{
    const [currencyId, setCurrencyId] = useState(CurrencyConstants.RUBBLE_ID);

    // Мемоизация валюты
    const currency = useMemo(() => CurrencyConstants.getCurrencyById(currencyId), [currencyId]);

    // Мемоизация вычисления суммы
    const sum = useMemo(() => {
        if (!currency) return 0;

        const totalFromSubaccounts = subaccounts?.reduce((acc, item) => {
            return acc + Money.convert(Money.toDigits(item.sum), currencyId, item.currency_id);
        }, 0);

        const totalFromDeposits = deposits?.reduce((acc, item) => {
            return acc + Money.convert(Money.toDigits(item.buy_sum), currencyId, item.buy_currency_id);
        }, 0);

        return totalFromSubaccounts + totalFromDeposits;
    }, [subaccounts, deposits, currencyId]);

    return <Row>
        <div style={{flex: '1'}}/>
        <Row flex>
            <Number><span id='account-sum'>{Money.format(sum)}</span> {currency ? currency.sign : ''}</Number>
        </Row>
        <InputContainer style={{border: 'none'}}>
            <Select
                value={currencyId}
                onChange={(item) => {
                    if(item){
                        setCurrencyId(item.id)
                    }
                }}
                options={currencies}
                placeholder={i18n.accounts.currencySum}
            />
        </InputContainer>
    </Row>
}

const enhance = connect(
    state => ({
        // @ts-ignore
        currencies: state.interfaceComponents.currencies,
        // @ts-ignore
        i18n: state.i18n,
    }),
    {}
);

export default enhance(SubAccount);