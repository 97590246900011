import {createReducer} from 'redux-act'

import * as actions from "#app/actions/account";
import {ModelHelper} from "finhelper";

let account = {
  modalVisible: false,
  form: {},
  availableTypes: [],//какие типы счетов показывать при создании, чтобы при покупке акций сразу показывал создание брокерского счета
  accounts: [],
  accountLoading: true,
};

const setAccountModalVisible = (state, bool) => {
  return {
    ...state,
    modalVisible: bool,
  }
}

const setAccountForm = (state, form) => {
  return {
    ...state,
    form: form
  }
}

const setAvailableTypes = (state, availableTypes) => {
  return {
    ...state,
    availableTypes: availableTypes
  }
}

const handleUserAccounts = (state, response) => {
  if(typeof response === 'object' && response.result === 'success')
  {
    ModelHelper.accounts = response.data;

    return {
      ...state,
      accounts: response.data,
      accountLoading: false
    }
  }else{
    return {
      ...state,
      accountLoading: false
    }
  }
}

const reducer = createReducer({
  [actions.setAccountModalVisible]: setAccountModalVisible,
  [actions.setAccountForm]: setAccountForm,
  [actions.setAvailableTypes]: setAvailableTypes,
  [actions.handleUserAccounts]: handleUserAccounts,
}, account)

export default reducer
