import {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';

const Portal = ({ id, children }) => {
  const el = useRef(document.createElement('div'));
  const root = useRef(null);

  useEffect(() => {
    root.current = document.getElementById(id);
    try {
      root.current.appendChild(el.current);
    } catch (e) {}

    return () => {
      try {
        root.current.removeChild(el.current);
      } catch (e) {}
    };
  }, [id]);

  return ReactDOM.createPortal(children, el.current);
};

export default Portal;