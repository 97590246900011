
export default class FileHelper
{
  /**
   *
   * @type {{}}
   */
  static data = {};

  static getMaxPostSize()
  {
    return FileHelper?.data?.max_post_size;
  }
}