import {createAction} from 'redux-act'

export const login = createAction('LOGIN')
export const handleLoginModal = createAction('HANDLE_LOGIN_MODAL')
export const handleForgotModal = createAction('HANDLE_FORGOT_MODAL')
export const handleRegisterModal = createAction('HANDLE_REGISTER_MODAL')
export const handleSubscribeModal = createAction('HANDLE_SUBSCRIBE_MODAL')
export const handleCloseModal = createAction('HANDLE_CLOSE_MODAL')

export const register = createAction('REGISTER')
export const requestRegister = createAction('REQUEST_REGISTER')

export const forgot = createAction('FORGOT')
export const requestForgot = createAction('REQUEST_FORGOT')

export const logout = createAction('LOGOUT')
export const handleLogout = createAction('HANDLE_LOGOUT')


export const getUser = createAction('GET_USER')
export const getServer = createAction('GET_SERVER')

export const postUser = createAction('POST_USER')
export const handleGetUser = createAction('HANDLE_GET_USER')
export const handleAuth = createAction('HANDLE_AUTH')


export const handleLanguage = createAction('HANDLE_LANGUAGE')

export const socketsConnecting = createAction('SOCKETS_CONNECTING');



