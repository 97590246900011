import {useEffect, useRef, useState} from 'react';
import {Api} from "laravel-request";
import SearchAllTypesHelper from "#app/helpers/SearchAllTypesHelper";

function useDebouncedSearch( options, clientId ) {
    const { debounceTime = 300 } = options;

    // Состояние для загрузки и результатов
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    // Рефы для управления очередью запросов и таймера
    const lastSearchQuery = useRef('');
    const lastTimeoutId = useRef(null);
    const cancelTokenSource = useRef(null);

    // Очистка таймера при размонтировании компонента
    useEffect(() => {
        return () => {
            if (lastTimeoutId.current) {
                clearTimeout(lastTimeoutId.current);
            }
            if (cancelTokenSource.current) {
                cancelTokenSource.current.cancel();
            }
        };
    }, []);

    // Функция для обработки ввода
    const handleSearch = (searchQuery) => {
        lastSearchQuery.current = searchQuery;

        if (lastTimeoutId.current) {
            clearTimeout(lastTimeoutId.current);
        }

        lastTimeoutId.current = setTimeout(() => {
            executeSearch(lastSearchQuery.current);
        }, debounceTime);
    };

    // Функция для выполнения API-запроса
    const executeSearch = (searchQuery) => {
        const trimmedQuery = searchQuery.trim();
        if (!trimmedQuery || trimmedQuery.length <= 2) {
            setIsLoadingSearch(false);
            setSearchResults([]);
            return;
        }

        if (cancelTokenSource.current) {
            cancelTokenSource.current.cancel();
        }

        setIsLoadingSearch(true);
        setSearchResults([]);

        cancelTokenSource.current = Api.get('active', SearchAllTypesHelper.ALL_TYPES, {
            item_search: searchQuery,
            user_id: clientId,
            search_stock: 1
        }).call(({data}) => {
            setSearchResults(data);
            setIsLoadingSearch(false);
        }, () => {
            setSearchResults([]);
            setIsLoadingSearch(false);
        }).getSource()
    };

    return {
        isLoadingSearch,
        searchResults,
        setSearchResults,
        handleSearch,
    };
}

export default useDebouncedSearch;