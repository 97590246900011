import styled from 'styled-components'
import {scrollVerticalSharedStyle} from "../../../interface/scroll";

export const Block = styled.div`
  margin: 5px 10px;
`

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex: 1;
  font-size: 14px;
  align-items: center;
  flex-basis: 100%;
  margin: 15px 0;
  overflow: unset;
  //max-height: 380px;
  //overflow-y: auto;
  
  ${scrollVerticalSharedStyle}
`

export const Number = styled.div`
  margin: 10px 0 0 30px;
  color: #4378FF;
  font-size: 16px;
`