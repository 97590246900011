import {AccountConstants} from "finhelper";

export default () =>
{
  const importCreate = async (callback) => {
    // try {
    //   await import("../pages/Accounting/Accounts/HiddenAccountCreate").then(module => callback(module).catch((e) => {
    //     console.warn(e)
    //   }))
    // } catch (e) {
    //   console.log(e)
    // }
  }
  const importEdit = async (callback) => {
    // try {
    //   await import("../pages/Accounting/Accounts/HiddenAccountEdit").then(module => callback(module).catch((e) => {
    //     console.warn(e)
    //   }))
    // } catch (e) {
    //   console.log(e)
    // }
  }

  return {
    importCreate: importCreate,
    importEdit: importEdit,
    loadModuleCreate: importCreate,
    loadModuleEdit: importEdit,
    getText: (account) => {return AccountConstants.getText(account)},
  }
};