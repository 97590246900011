import {useEffect, useState} from 'react';
import {AccountConstants, CurrencyConstants} from "finhelper";

export const useAccountForm = (
    form: any,
    client: any,
    setModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
    setForm: React.Dispatch<React.SetStateAction<any>>,
    types: any[],
    banks: any[],
    brokers: any[],
) => {
  const [id, setId] = useState(null);
  const [userId, setUserId] = useState(client ? client.id : '');
  const [typeId, setTypeId] = useState<any>('');
  const [name, setName] = useState('');
  const [bankId, setBankId] = useState('');
  const [customBankId, setCustomBankId] = useState('');
  const [searchBank, setSearchBank] = useState('');

  const [subaccounts, setSubaccounts] = useState([
    {
      account_id: id,
      currency_id: CurrencyConstants.RUBBLE_ID, // ID первой валюты
      sum: 0, // Начальная сумма
    }
  ]);

  const [formErrors, changeFormErrors] = useState<any>({});

  useEffect(() => {
    if (form) {
      setId(form.id);
      setTypeId(form.type_id || '');
      setBankId(form.bank_id || '');
      setCustomBankId(form.custom_bank_id || '');
      setName(form.name || '');
      setSubaccounts(form.accounts || [{
        account_id: id,
        currency_id: CurrencyConstants.RUBBLE_ID, // ID первой валюты
        sum: 0, // Начальная сумма
      }]);
    }else{
      if (types.length > 0 && !typeId) {
        setTypeId(types[0].id ?? '');
      }
    }
  }, [form]);

  useEffect(() => {
    if (banks.length > 0 && typeId && typeId === AccountConstants.BANK_ACCOUNT && !bankId) {
      const matchingBank = banks.find(bank => bank.id === form?.bank_id);
      if (matchingBank) {
        setBankId(matchingBank.id);
      }
    }
  }, [banks]);

  useEffect(() => {
    if (brokers.length > 0 && typeId && typeId === AccountConstants.BROKER_ACCOUNT && !bankId) {
      // Ищем брокера, который соответствует нужному условию
      const matchingBroker = brokers.find(broker => broker.id === form?.bank_id);
      if (matchingBroker) {
        setBankId(matchingBroker.id);
      }
    }
  }, [brokers]);

  // Функция для очистки формы
  const clearForm = () => {
    console.log('Clearing form, setting typeId to:', types[0]?.id ?? '');
    //TODO есть баг при повторном открытии формы в трейде после созднаия счета, показывает как будто тип счета выбран, но текст пустой

    setId(null);
    setTypeId(types[0].id ?? '');
    setBankId('');
    setCustomBankId('');
    setName('');
    setSearchBank('');
    setSubaccounts([
      {
        account_id: id,
        currency_id: CurrencyConstants.RUBBLE_ID, // ID первой валюты
        sum: 0, // Начальная сумма
      }
    ]);
  };

  const closeForm = () => {
    clearForm();
    changeFormErrors({});
    setModalVisible(false);
    setForm(null);
  };

  const hasError = (fieldName: string) => {
    if (formErrors && formErrors[fieldName] && formErrors[fieldName].length > 0) {
      return true;
    }
    return false;
  };

  const getError = (fieldName: string) => {
    if (formErrors && typeof formErrors[fieldName] !== 'undefined' && formErrors[fieldName].length > 0) {
      return formErrors[fieldName][0];
    }
    return '';
  };


  return {
    id, setId,
    typeId, setTypeId,
    userId, setUserId,
    name, setName,
    bankId, setBankId,
    customBankId, setCustomBankId,
    subaccounts, setSubaccounts,
    searchBank, setSearchBank,

    formErrors, changeFormErrors,
    hasError, getError,
    closeForm, clearForm
  };
};