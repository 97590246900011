import React, { useState } from 'react';
import { connect } from 'react-redux';
import ModalForm from "#app/components/ModalForm/ModalForm";
import { Row } from "#app/pages/styles";
import { Button } from "finform";

const ConfirmModal = ({
                          action,
                          trigger,
                          confirmText,
                          confirmButtonId,

                          i18n,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleConfirm = () => {
    if (action) {
      action();
    }
    closeModal();
  };

  return (
      <>
        {trigger && (
            <div style={{ display: 'inline-block' }} onClick={openModal}>
              {trigger}
            </div>
        )}
        <ModalForm
            header={confirmText}
            modalVisible={modalVisible}
            closeForm={closeModal}
            width="886px"
        >
          <Row style={{ justifyContent: 'center' }}>
            <Button slim type="cancel" onClick={closeModal}>
              {i18n.common.cancelButton}
            </Button>
            <Button slim id={confirmButtonId || ''} onClick={handleConfirm}>
              Подтвердить
            </Button>
          </Row>
        </ModalForm>
      </>
  );
};

const enhance = connect(
    (state) => ({
      client: state.interfaceComponents.client,
      i18n: state.i18n,
      tutorial: state.scenario.tutorial,
      demo: state.scenario.demo,
    }),
    {}
);
export default enhance(ConfirmModal);