/**
 * Сервис, содержащий чистые функции для расчёта sum, price, count.
 * Никакого React здесь нет. Только бизнес-логика.
 */

import {Money} from "finhelper";

/** Считать count = sum / price. */
export function calcCount(sumValue, priceValue, lotSize) {
    const sumParsed = Money.toDigits(sumValue);
    const priceParsed = Money.toDigits(priceValue);
    const lotSizeParsed = Money.toDigits(lotSize) ?? 1;
    if (sumParsed > 0 && priceParsed > 0) {
        const rawResult = sumParsed / priceParsed / lotSizeParsed;
        const rounded = Math.round(rawResult * 1e8) / 1e8;
        return Money.format(rounded, 8);
    }
    return "";
}

/** Считать price = sum / count. */
export function calcPrice(sumValue, countValue, lotSize) {
    const sumParsed = Money.toDigits(sumValue);
    const countParsed = Money.toDigits(countValue);
    const lotSizeParsed = Money.toDigits(lotSize) ?? 1;
    if (sumParsed > 0 && countParsed > 0) {
        const rawResult = sumParsed / countParsed / lotSizeParsed;
        const rounded = Math.round(rawResult * 1e8) / 1e8;
        return Money.format(rounded, 8);
    }
    return "";
}

/** Считать sum = price * count. */
export function calcSum(countValue, priceValue, lotSize) {
    const countParsed = Money.toDigits(countValue);
    const priceParsed = Money.toDigits(priceValue);
    const lotSizeParsed = Money.toDigits(lotSize) ?? 1;

    if (countParsed > 0 && priceParsed > 0) {
        const rawResult = countParsed * priceParsed * lotSizeParsed;
        const rounded = Math.round(rawResult * 1e8) / 1e8;
        return Money.format(rounded, 8);
    }
    return "";
}
