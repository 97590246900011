import React, {useLayoutEffect, useState} from 'react';
import {connect} from "react-redux";
import {PROCESSING, STARTED} from "#app/middlewares/utils/webSocketHelper";
import {enqueueEvent} from "#app/middlewares/webSocketMiddleware";
import {ActiveConstants} from "finhelper";
import TradeForm from "#app/pages/Relations/Trades/form/TradeForm";
import {GroupSelect, Input, InputContainer, Select} from "finform";
import {Row} from "#app/pages/styles";

const TestPage = ({  }) => {
    if (['production'].indexOf(process.env.REACT_APP_ENV) !== -1) {
        return null;
    }

    const contacts = [];
    const [formErrors, changeFormErrors] = useState({});
    const [text, setText] = useState('');
    const [contact, setContact] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [toAccountId, setToAccountId] = useState('');
    const [itemId, setItemId] = useState('');
    const [expandedContacts, setExpandedContacts] = useState({}); // Состояние для управления раскрытием контактов


    const options = [
        {id: 1, name: '1'},
        {id: 2, name: '2'},
        {id: 3, name: '3'},
        {id: 4, name: '4'},
        {id: 5, name: '5'},
        {id: 6, name: '6'},
    ];


    const getError = (fieldName) => {
        if (formErrors && typeof formErrors[fieldName] !== 'undefined' && formErrors[fieldName].length > 0) {
            return formErrors[fieldName][0];
        }
        return '';
    };

    // useLayoutEffect(() => {
    //     let infoId = NotifyManager.info('1111', '2222', 999999999);
    //     let errorId = NotifyManager.error('1111', '2222', 999999999);
    //     let jobId = 'someId';
    //     NotifyManager.loadingOnce(jobId, '', 'some text');
    //
    //     let loadingId = NotifyManager.loading('1111', '2222');
    //     let percent = 0;
    //     const interval = setInterval(() => {
    //         percent++;
    //         NotifyManager.update(jobId, parseFloat(percent));
    //         if (percent >= 100) {
    //             NotifyManager.delete(infoId);
    //             NotifyManager.delete(errorId);
    //             NotifyManager.delete(loadingId);
    //             clearInterval(interval); // Очистка интервала после завершения
    //         }
    //     }, 100);
    //
    //     return () => clearInterval(interval); // Очистка при размонтировании компонента
    // }, []);

    useLayoutEffect(() => {


        let jobId = 'someId';

        let job = {
            job_id: jobId,
            type_id: ActiveConstants.ZENMONEY,
            status: STARTED,
            percent: 0,
        }

        let store = {
            state: {
                jobs: {}
            },
            getState: function(){
                return this.state;
            },
            dispatch: function(action){

                switch (action.type)
                {
                    case 'HANDLE_CREATE_JOB':
                        this.state.jobs[action.payload] = {percent: 0}
                        break;
                }
                // ;
                //
                // return state
                console.log('Dispatched action:', action);
                console.log(this);

                return this.state;
            }
        }

        enqueueEvent(store, job)

        setTimeout(() => {
            job.status = PROCESSING;
            job.percent = 20;

            enqueueEvent(store, job)
        }, 2000)

        return () => {}; // Очистка при размонтировании компонента
    }, []);

    let toAccounts = [
        {id: 1, name: 'aaaaaaaaaaaaaaaaaaaaaaa', items: [
            {id: 1, name: 'so sososos sosos so so sosos so oso s so s os osos os os oso so os oso s'}
        ]}
    ]

    return (
        <div className={'cwhite'}>
            <div>
                <button onClick={() => {setModalVisible(true)}}>
                    aaa
                </button>
            </div>
            <Row>
                <InputContainer>
                    <Input
                        placeholder={'Example'}
                        value={text}
                        onChange={setText}
                    />
                </InputContainer>
                <InputContainer>
                    <Input
                        placeholder={'Example'}
                        value={text}
                        onChange={setText}
                    />
                </InputContainer>
                <InputContainer>
                    <Input
                        placeholder={'Example'}
                        value={text}
                        onChange={setText}
                    />
                </InputContainer>
            </Row>

            <Row>
                <InputContainer error={getError('to_account_id')}>
                    <GroupSelect
                        placeholder='Счет зачисления'
                        id={'to_account'}
                        value={toAccountId}
                        onChange={(item) => {
                            if(item)
                            {
                                setToAccountId(item.id)
                            }
                        }}
                        options={toAccounts}
                    />
                </InputContainer>
                <InputContainer error={getError('to_account_id')}>
                    <GroupSelect
                        placeholder='Счет зачисления'
                        id={'to_account'}
                        value={toAccountId}
                        onChange={(item) => {
                            if(item)
                            {
                                setToAccountId(item.id)
                            }
                        }}
                        options={toAccounts}
                    />
                </InputContainer>
                <InputContainer error={getError('to_account_id')}>
                    <GroupSelect
                        placeholder='Счет зачисления'
                        id={'to_account'}
                        value={toAccountId}
                        onChange={(item) => {
                            if(item)
                            {
                                setToAccountId(item.id)
                            }
                        }}
                        options={toAccounts}
                    />
                </InputContainer>
                <InputContainer error={getError('to_account_id')}>
                    <GroupSelect
                        placeholder='Счет зачисления'
                        id={'to_account'}
                        value={toAccountId}
                        onChange={(item) => {
                            if(item)
                            {
                                setToAccountId(item.id)
                            }
                        }}
                        options={toAccounts}
                    />
                </InputContainer>
            </Row>
            <Row>
                <InputContainer>
                    <Select
                        placeholder='Что-то'
                        id={'item'}
                        value={itemId}
                        onChange={(item) => {
                            if(item)
                            {
                                setItemId(item.id)
                            }
                        }}
                        options={options}
                    />
                </InputContainer>
            </Row>

            {/* Отображение списка контактов */}
            <div>
                <h3>Contacts</h3>
                {contacts.length === 0 ? (
                    <p>No contacts available</p>
                ) : (
                    contacts.map((contact, index) => (
                        <div key={index} style={{ marginBottom: '10px' }}>
                            <button onClick={() => toggleContact(index)}>
                                {expandedContacts[index] ? '▼' : '▶'} Contact {index + 1}
                            </button>
                            {expandedContacts[index] && (
                                <pre>
                                    {JSON.stringify(contact, null, 2)}
                                </pre>
                            )}
                        </div>
                    ))
                )}
            </div>


            <pre>
                Total Contacts: {JSON.stringify(contacts.length, null, 2)}
            </pre>
        </div>
    );
};

const enhance = connect(
    (state) => ({

    }),
    {  }
);

export default enhance(TestPage);