import {Api} from "laravel-request";
import {AccountConstants, ActiveModel, IndexedDBCache, TradeConstants} from "finhelper";

export default class TacticsHelper
{
  /**
   *
   * @type {boolean}
   */
  static loading = false;

  /**
   *
   * @param client
   * @param clientId
   * @param startDate
   * @param endDate
   * @param currencyId
   * @param prevData
   * @param cacheKey
   * @return {Promise<unknown>}
   */
  static async loadData(client, clientId, startDate, endDate, currencyId, prevData, cacheKey)
  {
    let rememberTime = 1000 * 60 * 60 * 24;//1000 дней

    return (new Promise(async (resolve, reject) => {

        let activeData = {
          client_id: clientId,
          currency_id: currencyId,
          start_date: startDate,
          end_date: endDate,
          hash: prevData?.data?.hash,//чтобы отправить прошлые данные
        }

        Api.get('cabinet', 'tactics-by-month', activeData).call(async (response) => {

          const keys = Object.keys(response.data);

          //все остальные ключи должны быть удалены, если возвращаем через кеш
          if(keys.length === 1 && keys.includes('hash'))
          {
            resolve(prevData);
          }else{
            await IndexedDBCache.set(cacheKey, response, rememberTime);

            resolve(response);
          }
        });
    }));
  }

  /**
   *
   * @param response
   * @param accounts
   * @param currency
   * @param rawActivesRef
   * @param setPreparedActives
   * @param callback
   * @param groupType
   */
  static async setInvestResponse(response, accounts, currency, rawActivesRef, setPreparedActives, callback, groupType)
  {
    const newActives = [];
    const indexActives = [];

    response.data.forEach((item) =>
    {
      const active = new ActiveModel(item);
      if (!indexActives.includes(item.id))
      {
        indexActives.push(item.id);
        newActives.push(active);
      } else
      {
        const index = newActives.findIndex((a) => a.id === item.id);
        newActives[index] = active;
      }
    });

    let blockedItems = [];
    accounts.map((account) =>
    {
      account.accounts.map((subAccount) =>
      {
        subAccount.blocked.map((blocked) =>
        {
          //берем только блокировки продажи чтобы дополнить ими
          if(blocked.is_blocked && blocked?.operation?.active_relation && blocked?.operation?.type_id === TradeConstants.SELL)
          {
            let active = {...blocked?.operation?.active_relation};

            //нужен трейд покупки, а в blocked связь с продажей
            active.name_text = active.name_text + ' (заблокировано)';
            active.sell_trades = [];
            active.is_blocked = true;

            blockedItems.push(active);
          }
        })
      })
    });

    let items = AccountConstants.appendCurrencyActives(accounts, currency);

    items = items.map((item) => {
      return  new ActiveModel(item);
    })

    blockedItems = blockedItems.map((item) => {
      return  new ActiveModel(item);
    })

    // Сохраняем данные в rawActivesRef
    rawActivesRef.current = [...newActives, ...items, ...blockedItems];


    let activeGroups = InvestHelper.prepareActives(rawActivesRef.current, groupType);

    await InvestHelper.processActiveGroups(activeGroups);

    setPreparedActives(activeGroups);
    callback && callback();
  }

  /**
   *
   * @param response
   * @param accounts
   * @param currency
   * @param rawActivesRef
   * @param setPreparedActives
   * @param callback
   * @param groupType
   */
  static async setArchiveInvestResponse(response, accounts, currency, rawActivesRef, setPreparedActives, callback, groupType)
  {
    const updatedData = response.data;
    const newActives = [];
    const newIndexActives = [];

    updatedData.forEach((item) => {
      if (newIndexActives.indexOf(item.id) === -1) {
        newIndexActives.push(item.id);
        newActives.push(new ActiveModel(item));
      } else {
        newActives.forEach((activeItem, key) => {
          if (activeItem.id === item.id) {
            newActives[key] = new ActiveModel(item);
          }
        });
      }
    });

    // Handle blocked actives
    const blockedActiveIds = [];
    accounts.forEach((account) => {
      account.accounts.forEach((subAccount) => {
        subAccount.blocked.forEach((blocked) => {
          if (blocked.is_blocked && blocked?.operation?.active_relation && blocked?.operation?.type_id === TradeConstants.SELL) {
            blockedActiveIds.push(blocked.operation.active_relation.id);
          }
        });
      });
    });

    const filteredActives = newActives.filter((active) => blockedActiveIds.indexOf(active.id) === -1);

    rawActivesRef.current = [...filteredActives];

    let activeGroups = InvestHelper.prepareArchiveActives(rawActivesRef.current, groupType);

    await InvestHelper.processActiveGroups(activeGroups);

    setPreparedActives(activeGroups);

    callback && callback();
  }
}