import React, {useMemo} from 'react';
import {Money} from "finhelper";
import {Caption} from "#app/components/Text/styles";

const NominalPriceInfo = ({ percent, faceValue, currencyCode }) => {

    // Проверяем, есть ли необходимые данные для отображения
    if (!faceValue || !currencyCode) {
        return null; // Если данных нет, ничего не отображаем
    }

    // Вычисляем цену за бумагу
    const nominalPrice = useMemo(() => {
        const calculatedPrice = ((Money.toDigits(percent) || 0) / 100) * (faceValue || 0);
        return isNaN(calculatedPrice) ? '' : Money.format(calculatedPrice);
    }, [percent, faceValue]);

    return (
        <Caption style={{marginLeft: 20, marginBottom: 12}}>
            Цена за бумагу: <span id='calulcate-price'>{nominalPrice}</span> {currencyCode} (номинальная стоимость <span id='nominal-price'>{faceValue}</span> {currencyCode})
        </Caption>
    );
};

export default NominalPriceInfo;