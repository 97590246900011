import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {
    HeaderContainer,
    HeaderLogo,
    HeaderMenu,
    HeaderOther,
    HeaderOtherComment,
    HeaderOtherLink,
    HeaderPersonal,
    HeaderWrapper,
    Wrap,
} from './styles';
import {getUserAccounts} from "#app/actions/account";
import {handleClient, handleCurrency} from "../../../actions/interface";
import {startScenario} from "../../../actions/scenario";
import {
    handleCloseModal,
    handleForgotModal,
    handleLoginModal,
    handleRegisterModal,
    handleSubscribeModal,
    logout
} from "../../../actions/auth";
import withRouter from "../../../components/WithRouter/withRouter";
import HeaderModals from "#app/interface/landing/header/components/HeaderModals";

const Header = (props) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setIsScrolled(true);
    } else if (window.pageYOffset === 0) {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <HeaderWrapper>
      <HeaderContainer className={isScrolled ? 'scrolled' : ''}>
        <Wrap>
          <HeaderLogo><Link to="/">whiteswan</Link></HeaderLogo>
          <HeaderMenu style={isScrolled ? { transform: 'translate3d(0,-50%,0)', opacity: 1 } : {}}>
            <div>
              {/*<Link to="/#functions">Что умеет</Link>*/}
              {/*<Link to="/#prices">Сколько стоит</Link>*/}
              {/*<Link to="/#buy">Купить</Link>*/}
            </div>
          </HeaderMenu>
        </Wrap>
        <HeaderPersonal>
          <a id={'login-button'} className='style2' onClick={(e) => {
            e.preventDefault();

            if (props.auth) {
              props.navigate('/cabinet')
            } else {
              props.handleLoginModal();
            }
          }}>{props.i18n.auth.enter}</a>
        </HeaderPersonal>
        <HeaderOther>
          <a href="https://blackswan.finance">
            <HeaderOtherLink />
            <HeaderOtherComment style={isScrolled ? { display: 'none' } : {}}>{props.i18n.auth.crm}</HeaderOtherComment>
          </a>
        </HeaderOther>
      </HeaderContainer>
      <HeaderModals {...props} />
    </HeaderWrapper>
  );
};

const enhance = connect(
  (state) => ({
    auth: state.auth.auth,
    data: state.auth.data,
    client: state.interfaceComponents.client,
    clients: state.interfaceComponents.clients,
    currency: state.interfaceComponents.currency,
    currencies: state.interfaceComponents.currencies,
    showLoginModal: state.auth.showLoginModal,
    showRegisterModal: state.auth.showRegisterModal,
    showForgotModal: state.auth.showForgotModal,
    showSubscribeModal: state.auth.showSubscribeModal,
    i18n: state.i18n,
  }),
  {
    handleClient,
    handleCurrency,
    getUserAccounts,
    logout,
    startScenario,
    handleLoginModal,
    handleCloseModal,
    handleRegisterModal,
    handleForgotModal,
    handleSubscribeModal
  }
)

export default withRouter(enhance(Header));