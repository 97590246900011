import {Url} from "finhelper";
import {Api} from "laravel-request";
import {SourceMapConsumer} from 'source-map';

const originalError = console.error;
console.error = (...args) => {
  if (/React does not recognize the/.test(args[0])) {
    return; // Игнорируем конкретное предупреждение
  }
  if (/for a non-boolean attribute/.test(args[0])) {
    return; // Игнорируем конкретное предупреждение
  }
  originalError(...args);
};

const originalWarn = console.warn;
console.warn = (...args) => {
  if (/styled-components: it looks like an unknown prop/.test(args[0])) {
    return; // Игнорируем конкретное предупреждение
  }
  originalWarn(...args);
};

async function decodeErrorWithSourceMap(error, sourceMapUrl) {
  try {
    const response = await fetch(sourceMapUrl);
    const rawSourceMap = await response.json();

    // Используем SourceMapConsumer асинхронно
    const consumer = await new SourceMapConsumer(rawSourceMap);

    const stack = error.stack.split("\n");
    const parsedErrors = [];

    for (const line of stack) {
      const match = line.match(/(.*):(\d+):(\d+)/);
      if (match) {
        const [, file, lineNumber, columnNumber] = match;
        const originalPosition = consumer.originalPositionFor({
          line: parseInt(lineNumber, 10),
          column: parseInt(columnNumber, 10)
        });

        if (originalPosition.source) {
          parsedErrors.push(
            `${originalPosition.source}:${originalPosition.line}:${originalPosition.column}`
          );
        } else {
          parsedErrors.push(line); // Если не удается найти оригинальную позицию, оставляем как есть
        }
      } else {
        parsedErrors.push(line); // Если строка не подходит под шаблон, просто добавляем её
      }
    }

    return parsedErrors;
  } catch (e) {
    console.error("Error loading or parsing source map:", e);
    return error.stack.split("\n"); // В случае ошибки возвращаем исходный стек
  }
}

const sentErrors = new Set();

window.onerror = function (msg, url, line, column, error) {
  try {
    // Формируем уникальный идентификатор ошибки
    const errorId = `${msg}-${url}-${line}-${column}`;

    // Проверяем, была ли ошибка уже отправлена
    if (sentErrors.has(errorId)) {
      return; // Если ошибка уже отправлена, прекращаем выполнение
    }

    // Добавляем идентификатор ошибки в множество
    sentErrors.add(errorId);

    const sourceMapUrl = url + '.map'; // Предполагается, что source map находится рядом с bundle

    decodeErrorWithSourceMap(error, sourceMapUrl).then((decodedStack) => {
      let currentUrl = Url.getCurrentUrl();

      Api.post('error-log', 'store', {
        message: msg,
        trace: decodedStack.join("\n"),
        line: line,
        column: column,
        url: currentUrl,
        file: url
      }).call();
    });
  } catch (e) {
    console.error(e);
  }
};