import {ApiRequest, Builder} from "laravel-request";
import {NotifyManager} from "notify-component";
import LocalStorageHelper from "#app/helpers/Cache/LocalStorageHelper";
import moment from "moment";
import {Url} from "finhelper";
import {register} from './serviceWorker';

register();

Error.stackTraceLimit = 30;

ApiRequest.notifyClass = NotifyManager;

Builder.availableMethod = [
  ...Builder.availableMethod,
  ...[
    'whereAccount',
    'whereIncomeType',
    'whereSalaryType',
    'whereSpendingType',
    'whereObligationType',
    'wherePropertyType',
    'whereInvestType',
    'whereCurrencyType',
    'whereAccountType',
    'whereInsuranceType',
  ]
]

const setLanguage = (langId, locale) => {
  localStorage.setItem(LocalStorageHelper.LANGUAGE, langId);
  moment.locale(locale);
};

const url = Url.getCurrentUrl();
const lang = localStorage.getItem(LocalStorageHelper.LANGUAGE);

if (url.includes('/ru/')) {
  setLanguage(1, 'ru');
  window.i18n = {};
} else if (url.includes('/en/')) {
  setLanguage(2, 'en');
} else if (parseInt(lang) === 1 || lang === null) {
  moment.locale('ru');
  window.i18n = {};
} else {
  moment.locale('en');
}