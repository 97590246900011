import styled from 'styled-components'
import dots from './../assets/icons/dots.svg'
import {widthContentStyle} from './../interface/width'

export const Container = styled.div`

  ${widthContentStyle};
`

export const FullWidthContainer = styled.div`
  position: relative;
  width: 100%;
  min-width: 100%;
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;

  span {
    display: inline-block;
  }

  span.green {
    color: #66C838 !important;
  }

  span.red {
    color: #EF5E70 !important;
  }
`

export const CenteredWrapper = styled.div`
  background-color: #fff;
  //box-shadow: 0 2px 4px rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 5px 20px 25px 20px;
  overflow: visible;
  min-height: 600px;
`

export const Title = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin: 15px 0;

  ${props => props.flex && `
    flex: 1;
  `}
`
export const SmallTitle = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  margin: 15px 0;

  ${props => props.flex && `
    flex: 1;
  `}
`
export const FullWidthTitle = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin: 15px 0;
  width: 100%;
`
export const InvestTitle = styled.div`
  display: flex;
  margin: 10px 0;
  width: 100%;
  //border-bottom: 1px solid ;
  //box-shadow: 0px 0px 0px 8px rgba(0,0,0,0.3);

  div:nth-child(1) {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    flex: 1;
    text-align: left;
  }

  div:nth-child(2) {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    flex: 1;
    text-align: right;
  }
  div:nth-child(3) {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    flex: 1;
    text-align: right;
  }
`
export const InvestTitleHover = styled.h1`
  display: flex;
  margin: 10px 0;
  width: 100%;
  cursor: pointer;

  div:nth-child(1) {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    flex: 1;
    text-align: left;
  }

  div:nth-child(2) {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    flex: 1;
    text-align: right;
  }

 &:hover {
    background-color: #F5F5F5;
  }
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  align-items: center;
  box-sizing: border-box;

  ${props => props.flex && `
    flex: 1;
  `}

  ${props => props.flexWidth && `
      flex-basis: ${props.flexWidth};
  `}

  ${props => props.right && `
    justify-content: end;
  `}
`

export const TopRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  margin: 13px 10px 10px 0;
  flex: 1;
`

export const ButtonRow = styled.div`
  text-align: left;
  flex: 1;

  ${props => props.align === 'right' && `
    text-align: right;
  `}
  ${props => props.align === 'center' && `
    text-align: center;
  `}
  ${props => props.flex && `
      display: flex;
      flex-basis: 100%;
  `}
  ${props => props.justify && `
      justify-content: space-between;
  `}
`

export const Checkboxes = styled.div`
  margin-bottom: 5px;
`

export const CheckSection = styled.div`
  margin: 0 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: rgb(249, 249, 249);
  text-align: left;
`

export const CheckboxContainer = styled.div`
  display: flex;
  padding: 14px;
  flex-wrap: wrap;
`

export const Dots = styled.div`
  align-items: center;
  border-radius: inherit;
  color: inherit;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  transition: .3s cubic-bezier(.25, .8, .5, 1);
  white-space: nowrap;
  width: inherit;

  span {
    width: 20px;
    height: 5px;
    background: url(${dots}) no-repeat center;
    display: block;
  }
`

export const Column = styled.div`
  flex: 1;
`

export const Chart = styled.div`
  display: inline-block;
`