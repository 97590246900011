import React, {useCallback, useEffect, useRef, useState} from "react";

/**
 * Хук для обработки перетаскивания модального окна.
 */
export function useDraggable() {
    const dragRef = useRef<HTMLDivElement | null>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [offset, setOffset] = useState({ x: 0, y: 0 });

    const handleMouseDown = useCallback((e: React.MouseEvent) => {
        if (dragRef.current) {
            setIsDragging(true);
            dragRef.current.style.transform = 'scale(0.94)';
            const rect = dragRef.current.getBoundingClientRect();
            setOffset({
                x: e.clientX - rect.left,
                y: e.clientY - rect.top
            });
        }
    }, []);

    const handleMouseMove = useCallback((e: MouseEvent) => {
        if (isDragging && dragRef.current) {
            const modalRect = dragRef.current.getBoundingClientRect();
            const modalWidth = modalRect.width;
            const modalHeight = modalRect.height;
            let newX = e.clientX - offset.x;
            let newY = e.clientY - offset.y;
            const rightMargin = 20;
            newX = Math.max(0, Math.min(newX, window.innerWidth - modalWidth - rightMargin));
            newY = Math.max(0, Math.min(newY, window.innerHeight - modalHeight));
            dragRef.current.style.position = 'absolute';
            dragRef.current.style.left = `${newX}px`;
            dragRef.current.style.top = `${newY}px`;
        }
    }, [isDragging, offset]);

    const handleMouseUp = useCallback(() => {
        setIsDragging(false);
        if (dragRef.current) {
            dragRef.current.style.transform = 'scale(1)';
        }
    }, []);

    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        }
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, handleMouseMove, handleMouseUp]);

    return { dragRef, isDragging, handleMouseDown };
}
