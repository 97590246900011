import React, {useEffect, useState} from 'react';
import {Sign} from '../../../deprecated/styles'
import {Row} from "#app/pages/styles";
import EqualSvg from "#app/assets/icons/equal.svg";
import MultiSvg from "#app/assets/icons/multi.svg";
import {InputContainer, NumberInput} from "finform";
import PlusTradeSvg from "#app/assets/icons/plus_trade.svg";
import {CurrencyConstants, Money} from "finhelper";
import NominalPriceInfo from "#app/pages/Relations/Trades/form/parts/SumPriceCount/NominalPriceInfo";
import LocalStorageHelper from "#app/helpers/Cache/LocalStorageHelper";
import styled from "styled-components";

const calculatePriceFromPercent = (percentValue, faceValue) => {
    if (!percentValue || !faceValue) return '';
    return  Money.format(faceValue * Money.toDigits(percentValue) / 100);
};

// Функция для расчета процента из цены
const calculatePercentFromPrice = (priceValue, faceValue) => {
    if (!priceValue || !faceValue) return '';
    return  Money.format((Money.toDigits(priceValue) / faceValue * 100));
};

const getInitialPriceMode = () => {
    const storedMode = localStorage.getItem(LocalStorageHelper.TRADE_FORM_MODE_FOR_PRICE);
    return storedMode || 'absolute'; // Если значение отсутствует, используем 'absolute' как дефолтное
};

export const ButtonContainer = styled.div`
    position: absolute;
    display: flex;
    top: -4px;
    left: 10px;
    min-width: 196px;
`;

export const Button = styled.button`
    border-radius: 0; // Убираем все закругления по умолчанию
    border: 1px solid #3C6CE6;
    background-color: transparent; // Убираем фон по умолчанию
    //padding: 8px 16px; // Устанавливаем внутренние отступы
    flex: 1;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s, color 0.3s; // Плавный переход при наведении

    &:first-child {
        border-top-left-radius: 12px; // Левый верхний угол
    }

    // Закругляем углы только для последней кнопки
    &:last-child {
        border-top-right-radius: 12px; // Правый верхний угол
    }
    
    border-bottom: none;
`;

const ObligationFormFields = ({
                              sum, setSum,
                              price, setPrice,
                              count, setCount,
                              accumulatedCoupon, setAccumulatedCoupon,
                              isSumBlocked,
                              isPriceBlocked,
                              isCountBlocked,
                              catalogItem,
                              getError,
                          }) => {
    const [priceMode, setPriceMode] = useState(getInitialPriceMode());
    const [percent, setPercent] = useState('');
    const faceValue = catalogItem?.facevalue || 0;

    const buttonStyles = {
        active: {
            backgroundColor: '#1A73E8', // Активный фон
            color: '#FFFFFF',           // Активный текст
        },
        inactive: {
            backgroundColor: '#EFF2F5', // Неактивный фон
            color: '#7F818D',           // Неактивный текст
        },
    };

    // Безопасное получение валюты
    const currency = CurrencyConstants.getCurrencyById(catalogItem?.currency_id) || {};

    // Обработчик изменения процента
    const handlePercentChange = (value) => {
        setPercent(value);
        if (value && faceValue) {
            const newPrice = calculatePriceFromPercent(value, faceValue);
            setPrice(newPrice);
        } else {
            setPrice('');
        }
    };

    // Обработчик изменения цены
    const handlePriceChange = (value) => {
        setPrice(value);
        if (value && faceValue) {
            const newPercent = calculatePercentFromPrice(value, faceValue);
            setPercent(newPercent);
        } else {
            setPercent('');
        }
    };

    const handlePriceModeChange = (newMode) => {
        setPriceMode(newMode);
    };

    // Эффект для инициализации процента при первом рендере
    useEffect(() => {
        const initialPercent = calculatePercentFromPrice(price, faceValue);
        setPercent(initialPercent);
    }, [price]);

    useEffect(() => {
        localStorage.setItem(LocalStorageHelper.TRADE_FORM_MODE_FOR_PRICE, priceMode);
    }, [priceMode]);

    return <>
        <Row style={{marginTop: 10}}>
            <ButtonContainer>
                <Button
                    style={{
                        ...buttonStyles[priceMode === 'absolute' ? 'active' : 'inactive'],
                    }}
                    id='absolute-mode'
                    narrow
                    onClick={() => handlePriceModeChange('absolute')}
                >
                    Цена
                </Button>
                <Button
                    style={{
                        ...buttonStyles[priceMode === 'percentage' ? 'active' : 'inactive'],
                    }}
                    id='percent-mode'
                    narrow
                    onClick={() => handlePriceModeChange('percentage')}
                >
                    %
                </Button>
            </ButtonContainer>
            <InputContainer style={{flexBasis: 40, borderTopRightRadius: 0, borderTopLeftRadius: 0}} error={getError('price')}>
                {priceMode === 'absolute' ? (
                    <NumberInput
                        key={0}//важно иначе неправильно работает
                        id='price'
                        placeholder='Цена'
                        value={price}
                        onChange={handlePriceChange}
                        disabled={isPriceBlocked}
                    />
                ) : (
                    <NumberInput
                        key={1}//важно иначе неправильно работает
                        id='percent'
                        placeholder='Процент'
                        value={percent}
                        onChange={handlePercentChange}
                        disabled={isPriceBlocked}
                    />
                )}
            </InputContainer>
            <Sign><img src={MultiSvg} alt=''/></Sign>
            <InputContainer error={getError('count')}>
                <NumberInput
                    id='count'
                    placeholder='Количество'
                    value={count}
                    onChange={setCount}
                    disabled={isCountBlocked}
                    min={0}
                />
            </InputContainer>
            <Sign><img src={EqualSvg} alt=''/></Sign>
            <InputContainer error={getError('sum')}>
                <NumberInput
                    id='sum'
                    placeholder='Сумма'
                    value={sum}
                    onChange={setSum}
                    disabled={isSumBlocked}
                />
            </InputContainer>
            <Sign><img src={PlusTradeSvg} alt=''/></Sign>
            <InputContainer error={getError('accumulated_coupon')}>
                <NumberInput
                    id='accumulated_coupon'
                    placeholder='Выпл. НКД'
                    value={accumulatedCoupon}
                    onChange={setAccumulatedCoupon}
                />
            </InputContainer>
        </Row>
        {priceMode === 'percentage' && (
            <NominalPriceInfo
                percent={percent}
                faceValue={catalogItem?.facevalue}
                currencyCode={currency.sign ?? currency.code}
            />
        )}
    </>
}

export default ObligationFormFields

