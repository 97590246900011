import React from 'react'
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux'
import {createRoot} from 'react-dom/client';
import configureStore from './configureStore'

import PagesRouter from './pagesRouter/pagesRouter'
import "./helpers";
import "./log";
import "./error";
import "./init";
import theme from './theme';

import "moment/locale/ru";

import './assets/css/normalize.css'
import './assets/css/main.css'
import './assets/css/treemap.css'
import './assets/css/tactics.css'
import './assets/css/case.css'
import './assets/css/bigcase.css'
import './assets/css/balance.css'
import './assets/css/insurance.css'
import './assets/css/rc-slider.css'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import './assets/css/popup.css'
import {ThemeProvider} from "styled-components";

const store = configureStore()

const root = createRoot(document.getElementById('app'));

root.render(<BrowserRouter
  future={{
    v7_startTransition: true,
    v7_relativeSplatPath: true
  }}
>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PagesRouter />
      </Provider>
    </ThemeProvider>
</BrowserRouter>);

