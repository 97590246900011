import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 10px 10px 10px 10px;
  cursor: pointer;
  
  div:nth-child(1)
  {
    text-align: left;
    flex-basis: 30%;
  }
  div:nth-child(2)
  {
    color: #7B7B7B;
    text-align: left;
    flex-basis: 20%;
  }
  div:nth-child(3)
  {
    flex-basis: 30%;
    text-align: right;
  }
  div:nth-child(4)
  {
    flex-basis: 20%;
    text-align: right;
  }
  
  &:hover {
    background-color: #F5F5F5;
  }
`