import React from 'react';
import {Container} from './styles';

function InnerTab({
                    id,
                    style,
                    onClick,
                    className,
                    active,
                    children
}) {
  return (
    <Container
      id={id}
      style={style}
      onClick={onClick}
      className={`${className || ''}${active ? ' active' : ''}`}
    >
      {children}
    </Container>
  );
}

export default InnerTab;
