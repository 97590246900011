import React, {useEffect, useState} from 'react';
import AddAccountButton from "#app/pages/Relations/components/AddAccountButton";
import {GroupSelect, InputContainer} from "finform";
import {AccountConstants, ActiveConstants} from "finhelper";

const SelectAccountWrapper = ({
                                  placeholder,
                                  types,
                                  id,
                                  value,
                                  onChange,
                                  options,
                                  error
                              }: any) => {

    return  <InputContainer style={{border: 'none'}} error={error}>
        <GroupSelect
            addButton={<AddAccountButton types={types}/>}
            placeholder={placeholder}
            id={id}
            value={value}
            onChange={onChange}
            options={options}
            getText={(item: any, subItem: any): string => {
                return ActiveConstants.getAccountName(item) +  ' ' + subItem.name;
            }}
        />
    </InputContainer>
}

export default SelectAccountWrapper