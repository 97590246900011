import React from 'react';
import {connect} from "react-redux";
import {Button, ModalForm} from "finform/old";
import {ModalFooter} from "../../interface/modal/Modal";
import {Money} from "finhelper"
import {Api} from "laravel-request";
import LoaderWhite from '#app/assets/images/loader_white.gif';

class Form extends ModalForm
{
  withNotify = true;

  static defaultProps = {
    data: {},
  };

  clearData(data, keys)
  {
    let count = keys.length;

    for(let n = 0; n < count; n++)
    {
      let parts = keys[n].split('.');
      let link = data;

      if(parts.length === 1)
      {
        if(typeof link[keys[n]] !== 'undefined')
        {
          delete link[keys[n]];
        }

      }else{
        for(let i = 0; i < parts.length; i++)
        {
          if((i + 1) === parts.length)
          {
            if(typeof link[keys[n]] !== 'undefined')
            {
              delete link[parts[i]];
            }
          }else{
            if(parts[i].isNumber())
            {
              parts[i] = parseInt(parts[i]);
            }

            link = link[parts[i]];
          }
        }
      }
    }

    return data;
  }

  onStore(response, form)
  {
    if(typeof this.props.onStore === 'function')
    {
      this.props.onStore(response, form);
    }
  }

  onUpdate(response, form)
  {
    if(typeof this.props.onUpdate === 'function')
    {
      this.props.onUpdate(response, form);
    }
  }

  onDelete(response, form)
  {
    if(typeof this.props.onDelete === 'function')
    {
      this.props.onDelete(response, form);
    }
  }

  onClose() {

  }

  /**
   *
   * @param accountField
   * @param currencyField
   */
  changeCurrency(accountField, currencyField)
  {
    if(this.state.form[accountField] && this.state.form[accountField].currency)
    {
      this.setState((prv) => {
        prv.form[currencyField + '_id'] = this.state.form[accountField].currency.id;
        prv.form[currencyField] = this.state.form[accountField].currency;

        return prv;
      });
    }
  }

  clearAccount(accountField, currencyField)
  {
    if(this.state.form[accountField] && this.state.form[currencyField] && this.state.form[accountField].currency && this.state.form[accountField].currency.id !== this.state.form[currencyField].id)
    {
      this.setState((prv) => {
        prv.form[accountField + '_id'] = '';
        prv.form[accountField] = null;

        return prv;
      });
    }
  }

  showMoneyFrom(accountField, sumField)
  {
    if(this.state.form[accountField] && Money.toDigits(this.state.form[sumField]) > 0 && Money.toDigits(this.state.form[sumField]) > Money.toDigits(this.state.form[accountField].sum))
    {
    //   this.setState((prv) => {
    //     prv.formErrors[accountField + '_id'] = [
    //       'Недостаточно средств для покупки на указанном счёте'
    //     ];
    //     prv.showMoneyFrom = true;
    //
    //     return prv;
    //   });
    // }else{
    //   this.setState((prv) => {
    //     prv.formErrors[accountField + '_id'] = [
    //
    //     ];
    //
    //     return prv;
    //   });
    }
  }


  getModel()
  {

  }

  submit(callback)
  {
    if(this.state.submitted === false || typeof this.state.submitted === 'undefined')
    {
      this.setState({
        submitted: true
      }, () => {
        if(typeof callback === 'function'){
          callback();
        }
      })
    }
  }

  loading(callback)
  {
    this.setState({
      loading: true
    }, () => {
      if(typeof callback === 'function'){
        callback();
      }
    })
  }

  clearThenCloseCallback()
  {

  }

  clearThenClose(action)
  {
    let form = {};
    switch (action)
    {
      case 'hold':
        form = {...this.state.form};
        this.setState((prv) => {
          prv.formErrors = {};
          prv.submitted = false;
          prv.edited = true;

          return prv;
        }, () => {
          this.getModel();
          this.props.callback(form);
          this.clearThenCloseCallback();
        });
        break;
      case 'row-hold':
        this.setState((prv) => {
          prv.submitted = false;
          prv.formErrors = {};

          return prv;
        });
        break;
      default:
        form = {...this.state.form};
        this.setState((prv) => {
          prv.form = this.getClearForm(this.props);
          prv.formErrors = {};
          prv.submitted = false;
          prv.edited = true;

          return prv;
        }, () => {
          this.handleClose();
          this.props.callback(form);
          this.clearThenCloseCallback();
        });
        break;
    }
  }

  getModelId()
  {
    return this.props.id
  }

  getModelName()
  {

  }

  handleStore(action, successCallback, errorCallback)
  {
    let data = {...this.state.form, ...this.props.data};

    this.submit(() => {
      let query = Api.post(this.getModelName(), 'store', data)

        if(this.withNotify === false)
        {
          query.withoutNotify()
        }

        query.withValidateForm(this)
        .call((response) => {
          this.onStore(response);
          this.setState((prv) => {
            prv.form.id = response.meta.id;
            prv.form.relation_id = response.meta.relation_id;
            prv.logs = response.logs;

            return prv;
          }, () => {
            this.clearThenClose(action);

            if(typeof this.props.onClose === 'function')
            {
              this.props.onClose();
            }

            if(typeof successCallback === 'function'){
              successCallback()
            }
          })
        }, () => {
          this.setState((prv) => {
            prv.submitted = false;

            return prv;
          }, () => {
            if(typeof errorCallback === 'function'){
              errorCallback()
            }
          });
        });
    })
  }

  handleUpdate(action, successCallback, errorCallback)
  {
    let data = {...this.state.form, ...this.props.data};

    this.submit(() => {
      let query = Api.putArg(this.getModelName(), 'update', this.getModelId(), data)

      if(this.withNotify === false)
      {
        query.withoutNotify()
      }

      query.withValidateForm(this)
      .call((response) => {
        this.setState((prv) => {
          prv.form.id = response.meta.id;
          prv.logs = response.logs;

          return prv;
        }, () => {
          this.clearThenClose(action);

          if(typeof successCallback === 'function'){
            successCallback()
          }
        })
      }, () => {
        this.setState((prv) => {
          prv.submitted = false;

          return prv;
        }, () => {
          if(typeof errorCallback === 'function'){
            errorCallback()
          }
        });
      });
    });
  }

  handleDestroy(action, successCallback, errorCallback)
  {
    this.submit(() => {
      if(this.props?.tutorial || this.props?.demo)
      {
        if(typeof this.tutorialDestroy === 'function')
        {
          this.tutorialDestroy(action)
        }
      }else{
        Api.delete(this.getModelName(), 'destroy', this.getModelId(), {user_id: this.props.client.id})
          .withValidateForm(this)
          .call((response) => {
            this.setState((prv) => {
              prv.submitted = false;

              return prv;
            }, () => {
              this.clearThenClose();

              if(typeof this.props.callback === 'function')
              {
                this.props.callback();
              }

              if(typeof this.props.onDelete === 'function')
              {
                let form = {...this.state.form};

                this.props.onDelete(response, form);
              }
            });
          }, () => {
            this.setState((prv) => {
              prv.submitted = false;

              return prv;
            });
          });
      }
    })
  }

  /**
   *
   * @returns {*}
   */
  renderFooterItem()
  {
    return this.renderEmpty();
  }

  renderFooter()
  {
    if(this.state.form.id){
      return <ModalFooter style={{
        flexWrap: 'nowrap'
      }}>
        <div style={{margin: '0 auto'}}>
          {this.renderFooterItem()}
          {this.state.submitted ?
            <Button size='save' slim className='style2'><img src={LoaderWhite}/></Button>
            :
            (this.state.edited ?
                <Button size='save' slim className='style2' id={this.getButtonId() + '-edit'} onClick={() => {this.handleEdit()}}>{this.props.i18n.common.editButton}</Button>
                :
                <Button size='save' slim className='style2' id={this.getButtonId() + '-save'} onClick={() => {this.handleUpdate('hold')}}>{this.props.i18n.common.saveButton}</Button>
            )}
        </div>
      </ModalFooter>
    }else{
      return <ModalFooter style={{
        flexWrap: 'nowrap'
      }}>
        <div style={{margin: '0 auto'}}>
          <Button slim id={this.getButtonId() + '-cancel-save'} className='style1' onClick={() => {this.handleClose()}}>{this.props.i18n.common.cancelButton}</Button>
          {this.state.submitted ?
            <Button size='save' slim className='style2'><img src={LoaderWhite}/></Button>
            :
            <Button size='save' slim className='style2' id={this.getButtonId() + '-save'} onClick={() => {this.handleStore('hold')}}>{this.props.i18n.common.saveButton}</Button>
          }
        </div>
      </ModalFooter>
    }
  }
}

const enhance = connect(
  (state) => ({
    client: state.interfaceComponents.client,
    i18n: state.i18n,
  }),
  {}
)

export {Form}

export default enhance(Form);
