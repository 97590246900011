import {put, select, takeLatest} from 'redux-saga/effects'
import * as tacticsActions from '../actions/tactics'
import {Api} from "laravel-request";
import {ActiveConstants} from "finhelper"


function* getSpendingsWithFilter(action)
{
  //TODO вшить логику demo и tutorial тут
  const state = yield select();

  const promise = new Promise((resolve, reject) => {
    Api.get('active', 'index-grid', {
      currency_id: state.interfaceComponents.currency.id ,
      user_id: state.interfaceComponents.client.id,
    })
      .whereHas('income_account', (query) => query.where('is_visible', 1))
      .whereIn('type_id', [...ActiveConstants.SPENDING_GROUP, ActiveConstants.CUSTOM_SPEND, ...ActiveConstants.INSURANCES_GROUP])
      .with('operation', 'custom_type', 'subcategory', 'tags.tags')
      .whereHas('payments', (query) => {
        if (state.tactics.start) {
          const formattedStart = state.tactics.start.split('-').reverse().join('-');
          query.where('paid_at', '>=', formattedStart);
        }
        if (state.tactics.end) {
          const formattedEnd = state.tactics.end.split('-').reverse().join('-');
          query.where('paid_at', '<', formattedEnd);
        }

        return query.where('sum', '<', 0)
          .where('is_confirmed', 1)
      })
      .with('payments')
      .orderBy('buy_at', 'DESC')
      .all((response) => {
        resolve(response)
      }, () => {
        reject();
      });
  });

  let response = yield promise;

  yield put(tacticsActions.handleSpendingsWithFilter(response));
}

export default function* watcher() {
  // yield takeEvery('*', function* logger(action) {
  //   const state = yield select()
  //
  //   // console.log('action', action)
  //   // console.log('state after', state)
  // })
  yield takeLatest(tacticsActions.getSpendingsWithFilter, getSpendingsWithFilter)
}
