import React, {Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';
import {
    ArchiveInvests,
    Case,
    Clients,
    CommonConfig,
    CommonProperty,
    Dashboard,
    Dynamic,
    Goals,
    Incomes,
    Insurances,
    Invests,
    Logs,
    Notifications,
    OldBalance,
    Page1,
    Plan,
    Quizes,
    RoundDiagramsAndGroups,
    RoundDiagramView,
    SalaryPlan,
    Spendings,
    Tactics,
    TradingViewChart,
    Ui,
    UnexpectedError
} from './LazyLoadedPages';
import CabinetLayout from './CabinetLayout';
import NotFound from "#app/interface/error/NotFound";
import InvestsProvider from "#app/contexts/InvestsProvider";
import {isManager} from "#app/pages/Config/Security/Security";
import TestPage from "#app/pages/Test/TestPage";

const CabinetRoutesWrapper = ({ authData, isLoaded, client, demo, scenarioName }) => {

  if(!isLoaded && !client && isManager(authData))
  {
    //если под менеджером не загружен клиент, например когда ещё нет ни одного клиента
    return <Routes>
      <Route element={<CabinetLayout isLoaded={true} client={null} demo={demo} scenarioName={scenarioName} />}>
        <Route path="accounting">
          <Route path="dashboard" element={<div/>} />
          <Route path="clients" element={<Suspense><Clients /></Suspense>} />
        </Route>

        <Route path="config" element={<Suspense><CommonConfig /></Suspense>} />
        <Route path="quizes" element={<Suspense><Quizes /></Suspense>} />
        <Route path="notifications" element={<Suspense><Notifications /></Suspense>} />
        <Route path="error" element={<Suspense><UnexpectedError /></Suspense>} />
        <Route path="ui" element={<Suspense><Ui /></Suspense>} />
      </Route>
    </Routes>
  }


  return (
    <Routes>
      <Route element={<CabinetLayout isLoaded={isLoaded} client={client} demo={demo} scenarioName={scenarioName} />}>
        <Route path="accounting">
          <Route path="dashboard" element={<Suspense><Dashboard /></Suspense>} />
          <Route path="balance" element={<Suspense>
            <InvestsProvider>
              <OldBalance />
            </InvestsProvider>
          </Suspense>} />
          <Route path="dynamic" element={<Suspense><Dynamic /></Suspense>} />
          <Route path="accounts-properties-obligations" element={<Suspense><CommonProperty /></Suspense>} />
          <Route path="logs" element={<Suspense><Logs /></Suspense>} />
          <Route path="clients" element={<Suspense><Clients /></Suspense>} />
        </Route>

        <Route path="plan">
          <Route path="plan" element={<Suspense><Plan /></Suspense>} />
          <Route path="salary-plan" element={<Suspense><SalaryPlan /></Suspense>} />
          <Route path="goals" element={<Suspense><Goals /></Suspense>} />
          <Route path="insurances" element={<Suspense><Insurances /></Suspense>} />
        </Route>

        <Route path="register">
          <Route path="page1" element={<Suspense><Page1 /></Suspense>} />
        </Route>

        <Route path="income-and-expenses">
          <Route path="tactics" element={<Suspense><Tactics /></Suspense>} />
          <Route path="income" element={<Suspense><Incomes /></Suspense>} />
          <Route path="spendings" element={<Suspense><Spendings /></Suspense>} />
        </Route>

        <Route path="investing">
          <Route path="case" element={<Suspense><Case /></Suspense>} />
          <Route path="invest" element={<Suspense><Invests /></Suspense>} />
          <Route path="archive-invest" element={<Suspense><ArchiveInvests /></Suspense>} />
          <Route path="diagrams/view/:id" element={<Suspense><RoundDiagramView /></Suspense>} />
          <Route path="diagrams" element={<Suspense><RoundDiagramsAndGroups /></Suspense>} />
          <Route path="chart" element={<Suspense><TradingViewChart /></Suspense>} />
        </Route>

        <Route path="config" element={<Suspense><CommonConfig /></Suspense>} />
        <Route path="quizes" element={<Suspense><Quizes /></Suspense>} />
        <Route path="notifications" element={<Suspense><Notifications /></Suspense>} />
        <Route path="error" element={<Suspense><UnexpectedError /></Suspense>} />
        <Route path="test" element={<Suspense><TestPage /></Suspense>} />
        <Route path="ui" element={<Suspense><Ui /></Suspense>} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default CabinetRoutesWrapper;