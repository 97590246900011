import React from 'react';
import ReactDOM from 'react-dom';
import CloseIcon from "./images/close.svg";
import {Modal, ModalClose, ModalFooter, ModalHeader, Overlay, Wrapper} from "#app/components/ModalForm/styles";
import {useDraggable} from "#app/components/ModalForm/hooks/useDraggable";

interface ModalFormProps {
    header: string;           // Текст заголовка модального окна
    modalVisible: boolean;    // Управляет видимостью модального окна
    closeForm: () => void;    // Функция для закрытия модального окна
    width?: string;           // Ширина модального окна
    height?: string;          // Высота модального окна
    id?: string;              // ID модального окна
    children: React.ReactNode; // Содержимое модального окна
}

const ModalForm: React.FC<ModalFormProps> = ({
                                                 modalVisible,
                                                 closeForm,
                                                 children,
                                                 header = '',
                                                 width = 'auto',
                                                 height = '90%',
                                                 id = undefined
                                             }) => {
    if (!modalVisible) return null;
    const modalElement = document.getElementById('modal');
    if (!modalElement) return null;

    const { dragRef, isDragging, handleMouseDown } = useDraggable();

    return ReactDOM.createPortal(
        <Overlay>
            <Wrapper>
                <Modal
                    ref={dragRef}
                    id={id}
                    style={{
                        top: 100,
                        transition: 'box-shadow 0.3s ease, transform 0.3s ease',
                        minWidth: width,
                        maxWidth: width
                    }}
                >
                    <ModalHeader onMouseDown={handleMouseDown} style={{ cursor: isDragging ? 'grabbing' : 'grab', flex: 1 }}>
                        {header && <div>{header}</div>}
                        <ModalClose
                            onClick={closeForm}
                            onMouseDown={(e) => e.stopPropagation()}
                        >
                            <img src={CloseIcon} alt="Close" />
                        </ModalClose>
                    </ModalHeader>
                    <ModalFooter>
                        {children}
                    </ModalFooter>
                </Modal>
            </Wrapper>
        </Overlay>,
        modalElement
    );
};

export default ModalForm;
