import React, {useCallback, useMemo} from 'react';
import {Row} from './styles';
import {AccountConstants, CurrencyConstants, Money} from 'finhelper';
import {Checkbox} from "finform/old";
import {Api} from 'laravel-request';

const TradePaymentRow = ({
                        clientId,
                        item,
                        randomId,
                        onClick,
                        callbackItem
                    }) => {
    // Мемоизация вычисления аккаунта
    const accountName = useMemo(() => {
        const account = AccountConstants.getSubAccountById(item.account_id);
        return account ? AccountConstants.getName(account) : '';
    }, [item.account_id]);

    // Мемоизация форматирования значения
    const formattedValue = useMemo(() => {
        return `${Money.format(item.sum)} ${CurrencyConstants.getCurrencySignById(item.currency_id)}`;
    }, [item.sum, item.currency_id]);

    // Мемоизация получения даты
    const paymentDate = useMemo(() => {
        return item.paid_at_datetime || '';
    }, [item.paid_at_datetime]);

    // Оптимизация обработчика клика
    const handleClick = useCallback(() => {
        onClick(item);
    }, [onClick, item]);

    // Оптимизация обработчика изменения чекбокса
    const handleCheckboxToggle = useCallback(() => {
        const oldIsConfirmed = item.is_confirmed; // Сохраняем старое значение is_confirmed
        const newItem = { ...item };

        // Инвертируем статус подтверждения
        newItem.is_confirmed = !newItem.is_confirmed;

        // Вызываем коллбэк для родительского компонента
        if (callbackItem) {
            callbackItem(newItem);
        }

        // Отправляем запрос на сервер
        const apiCall = newItem.id
            ? Api.putArg('active-payment', 'update', newItem.id, { ...newItem, user_id: clientId })
            : Api.post('active-payment', 'store', { ...newItem, user_id: clientId });

        apiCall.call(({data}) => {

            // Добавляем randomId из текущего элемента
            const updatedItemWithRandomId = {
                ...data,
                randomId: item.randomId, // Присваиваем randomId из текущего элемента
            };

            // Вызываем коллбэк для родительского компонента с обновленным элементом
            if (callbackItem) {
                callbackItem(updatedItemWithRandomId);
            }
        }, () => {
            console.error('Ошибка при обновлении is_confirmed');

            // Откатываем is_confirmed к старому значению
            newItem.is_confirmed = oldIsConfirmed;

            // Вызываем коллбэк для родительского компонента с откатом
            if (callbackItem) {
                callbackItem(newItem);
            }
        });
    }, [item, callbackItem, clientId]);

    return (
        <Row id={`trade-payment-${item.id || randomId}`}>
            <div onClick={handleClick}>{accountName}</div>
            <div onClick={handleClick}>{paymentDate}</div>
            <div onClick={handleClick}>{formattedValue}</div>
            <div>
                <Checkbox
                    id=""
                    text=""
                    style={{ margin: '0 0 0 85px' }}
                    form={{}}
                    textStyle={{}}
                    checked={item.is_confirmed}
                    toggleCallback={handleCheckboxToggle}
                />
            </div>
        </Row>
    );
};

export default TradePaymentRow