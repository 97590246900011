import {call, put, select, takeLatest} from 'redux-saga/effects'
import {Api} from "laravel-request";
import * as interfaceActions from "../actions/interface";
import {ClientModel, IndexedDBCache} from "finhelper"
import moment from "moment";
import CacheHelper from "#app/helpers/Cache/CacheHelper";

function* getUserNotifications(action) {
  try {
    const state = yield select();

    if(state.interfaceComponents.client)
    {
      const promise = new Promise((resolve, reject) => {
        Api.get('user-notification', 'index', {user_id: state.interfaceComponents.client.id})
          .orderBy('created_at', 'DESC')
          .limit(5)
          .all((response) => {
            resolve(response);
          }, (response) => {
            reject();
          })
      });

      let response = yield promise;

      yield put(interfaceActions.handleUserNotifications(response));
    }else{
      yield put(interfaceActions.handleUserNotifications({data: []}));
    }

    if(action.payload && typeof action.payload.callback === "function")
    {
      yield call(action.payload.callback);
    }
  } catch (err) {
    console.error(err);
  }

  return true;
}

function* getAtonFiles(action)
{
  const state = yield select();

  const promise = new Promise((resolve, reject) => {
    Api.get('profile', 'path-download-aton-files', { user_id: state.interfaceComponents.client.id })
      .call((response) => {
        resolve(response)
      }, () => {
        reject();
      });
  });

  let response = yield promise;

  yield put(interfaceActions.handleAtonFiles(response));
}

function* getClients(action) {
  try {
    const promise = new Promise((resolve, reject) => {
      ClientModel.fetch().orderBy('id', 'ASC').all((response) => {
        resolve(response);
      }, (response) => {
        reject();
      })
    });

    let response = yield promise;

    yield put(interfaceActions.handleClients(response));

    let crmClientId = localStorage.getItem('crm_client_id') ? localStorage.getItem('crm_client_id') : null;

    if(crmClientId)
    {
      let id = parseInt(crmClientId);

      if(id)
      {
        response.data.map((item) =>
        {
          if(parseInt(item.id) === id)
          {
            localStorage.setItem('client', JSON.stringify(item))
            localStorage.removeItem('crm_client_id')
          }
        });
      }
    }



    let client = localStorage.getItem('client') ? JSON.parse(localStorage.getItem('client')) : null;

    if(client)
    {
      let id = parseInt(client.id);

      if(id)
      {
        let clientFound = null;
        response.data.map((item) =>
        {
          if(parseInt(item.id) === id)
          {
            clientFound = item;
          }
        });

        yield put(interfaceActions.handleCurrentClient(clientFound));
      }
    }else{
      yield put(interfaceActions.handleCurrentClient((response.data[0] ? response.data[0] : null)));
    }

    if(action.payload && typeof action.payload.callback === "function")
    {
      yield call(action.payload.callback);
    }
  } catch (err) {
    console.error(err);
  }
  
  return true;
}

function* getCurrencies(action) {
  try {

    const cachePromise = new Promise(async (resolve, reject) => {
      let data = await IndexedDBCache.get(CacheHelper.CURRENCIES);

      resolve(data);
    })

    let data = yield cachePromise;

    if(!data)
    {
      const promise = new Promise((resolve, reject) => {
        Api.get('currency', 'index').with('cb_currency').orderBy('order').all((response) => {
          resolve(response);
        }, (response) => {
          reject();
        })
      })

      let response = yield promise;

      data = response.data;

      IndexedDBCache.set(CacheHelper.CURRENCIES, data, 1000 * 60 * 60 * 24)
    }

    yield put(interfaceActions.handleCurrencies(data));

    if(action.payload && typeof action.payload.callback === "function")
    {
      yield call(action.payload.callback);
    }
  } catch (err) {
    console.error(err);
  }
}


function* getCourses(action) {
  try {
    let now = moment().format('YYYY-MM-DD');

    const cacheKey = CacheHelper.generateKey(CacheHelper.COURSES, {
      date: now
    });

    const cachePromise = new Promise(async (resolve, reject) => {
       let data = await IndexedDBCache.get(cacheKey);

       resolve(data);
    })

    let data = yield cachePromise;

    if(!data)
    {
      const promise = new Promise((resolve, reject) => {
        Api.get('cb-history-currency-course', 'latest')
          .all((response) => {
            resolve(response)
          }, (xhr) => {
            reject();
          });
      });

      let response = yield promise;

      data = response.data;

      IndexedDBCache.set(cacheKey, data, 1000 * 60 * 60 * 24)
    }

    yield put(interfaceActions.handleCourses(data));

    if(action.payload && typeof action.payload.callback === "function")
    {
      yield call(action.payload.callback);
    }
  } catch (err) {
    console.error(err);
  }
}

function* getSpendTypes(action) {
  try {
    const state = yield select();

    if(state.interfaceComponents.client)
    {
      const promise = new Promise((resolve, reject) => {
        Api.get('active', 'spending-types', {
          user_id: state.interfaceComponents.client.id,
          all: true
        }).call((response) => {
          resolve(response)
        }, () => {
          reject();
        });
      });

      let response = yield promise;

      yield put(interfaceActions.handleSpendTypes(response));

      if(action.payload && typeof action.payload.callback === "function")
      {
        yield call(action.payload.callback);
      }
    }
  } catch (err) {
    console.error(err);
  }
}

function* getIncomeTypes(action) {
  try {
    const state = yield select();

    if(state.interfaceComponents.client)
    {
      const promise = new Promise((resolve, reject) => {
        Api.get('active', 'income-types', {
          user_id: state.interfaceComponents.client.id,
          all: true
        }).call((response) => {
          resolve(response)
        }, () => {
          reject();
        });
      });

      let response = yield promise;

      yield put(interfaceActions.handleIncomeTypes(response));

      if(action.payload && typeof action.payload.callback === "function")
      {
        yield call(action.payload.callback);
      }
    }
  } catch (err) {
    console.error(err);
  }
}
function* getActiveTags(action) {
  try {
    const state = yield select();

    if(state.interfaceComponents.client)
    {
      const promise = new Promise((resolve, reject) => {
        Api.get('active-tag', 'index', {
          user_id: state.interfaceComponents.client.id,
        }).all((response) => {
          resolve(response)
        }, () => {
          reject();
        });
      });

      let response = yield promise;

      yield put(interfaceActions.handleActiveTags(response));

      if(action.payload && typeof action.payload.callback === "function")
      {
        yield call(action.payload.callback);
      }
    }
  } catch (err) {
    console.error(err);
  }
}

function* getActiveSubcategories(action) {
  try {
    const state = yield select();

    if(state.interfaceComponents.client)
    {
      const promise = new Promise((resolve, reject) => {
        Api.get('active-subcategory', 'index', {
          user_id: state.interfaceComponents.client.id
        }).all((response) => {
          resolve(response)
        }, () => {
          reject();
        });
      });

      let response = yield promise;

      yield put(interfaceActions.handleActiveSubcategories(response));

      if(action.payload && typeof action.payload.callback === "function")
      {
        yield call(action.payload.callback);
      }
    }
  } catch (err) {
    console.error(err);
  }
}

function* getPropertyTypes(action) {
  try {
    const state = yield select();

    if(state.interfaceComponents.client)
    {
      const promise = new Promise((resolve, reject) => {
        Api.get('active', 'property-types', {
          user_id: state.interfaceComponents.client.id
        }).all((response) => {
          resolve(response)
        }, () => {
          reject();
        });
      });

      let response = yield promise;

      yield put(interfaceActions.handlePropertyTypes(response));

      if(action.payload && typeof action.payload.callback === "function")
      {
        yield call(action.payload.callback);
      }
    }
  } catch (err) {
    console.error(err);
  }
}

function* getInvestTypes(action) {
  try {
    const state = yield select();

    if(state.interfaceComponents.client)
    {
      const promise = new Promise((resolve, reject) => {
        Api.get('active', 'invest-types', {
          user_id: state.interfaceComponents.client.id
        }).all((response) => {
          resolve(response)
        }, () => {
          reject();
        });
      });

      let response = yield promise;

      yield put(interfaceActions.handleInvestTypes(response));

      if(action.payload && typeof action.payload.callback === "function")
      {
        yield call(action.payload.callback);
      }
    }
  } catch (err) {
    console.error(err);
  }
}

function* getGroupTypes(action) {
  try {
    const state = yield select();

    if(state.interfaceComponents.client)
    {
      const promise = new Promise((resolve, reject) => {
        Api.get('active', 'group-types', {
          user_id: state.interfaceComponents.client.id
        }).all((response) => {
          resolve(response)
        }, () => {
          reject();
        });
      });

      let response = yield promise;

      yield put(interfaceActions.handleGroupTypes(response));

      if(action.payload && typeof action.payload.callback === "function")
      {
        yield call(action.payload.callback);
      }
    }
  } catch (err) {
    console.error(err);
  }
}

function* getClassTypes(action) {
  try {
    const state = yield select();

    if(state.interfaceComponents.client)
    {
      const promise = new Promise((resolve, reject) => {
        Api.get('active', 'class-types', {
          user_id: state.interfaceComponents.client.id
        }).all((response) => {
          resolve(response)
        }, () => {
          reject();
        });
      });

      let response = yield promise;

      yield put(interfaceActions.handleClassTypes(response));

      if(action.payload && typeof action.payload.callback === "function")
      {
        yield call(action.payload.callback);
      }
    }
  } catch (err) {
    console.error(err);
  }
}

function* postCustomGroupType(action) {
  try {
    const promise = new Promise((resolve, reject) => {
      Api.post('active-custom-group-type', 'store', action.payload.form)
        .withoutNotify()
        .call((response) => {
          action.payload.success(response);

          resolve(response)
        }, (xhr, responseData) => {
          action.payload.error(xhr, responseData);

          reject();
        });
    });

    let response = yield promise;

    yield put(interfaceActions.getGroupTypes());

    if(action.payload && typeof action.payload.callback === "function")
    {
      yield call(action.payload.callback);
    }
  } catch (err) {
    console.error(err);
  }
}

function* postCustomClassType(action) {
  try {
    const promise = new Promise((resolve, reject) => {
      Api.post('active-custom-class-type', 'store', action.payload.form)
        .withoutNotify()
        .call((response) => {
          action.payload.success(response);

          resolve(response)
        }, (xhr, responseData) => {
          action.payload.error(xhr, responseData);

          reject();
        });
    });

    let response = yield promise;

    yield put(interfaceActions.getClassTypes());

    if(action.payload && typeof action.payload.callback === "function")
    {
      yield call(action.payload.callback);
    }
  } catch (err) {
    console.error(err);
  }
}

function* postCustomType(action) {
  try {
    const promise = new Promise((resolve, reject) => {
      Api.post('active-custom-type', 'store', action.payload.form)
        .withoutNotify()
        .call((response) => {
          action.payload.success(response);

          resolve(response)
        }, (xhr, responseData) => {
          action.payload.error(xhr, responseData);

          reject();
        });
    });

    let response = yield promise;

    yield put(interfaceActions.getSpendTypes());
    yield put(interfaceActions.getIncomeTypes());
    yield put(interfaceActions.getActiveTags());
    yield put(interfaceActions.getActiveSubcategories());
    yield put(interfaceActions.getPropertyTypes());
    yield put(interfaceActions.getInvestTypes());

    if(action.payload && typeof action.payload.callback === "function")
    {
      yield call(action.payload.callback);
    }
  } catch (err) {
    console.error(err);
  }
}

function* postCustomInsuranceCompany(action) {
  try {
    const promise = new Promise((resolve, reject) => {
      Api.post('active-custom-insurance-company', 'store', action.payload.form)
        .withoutNotify()
        .call((response) => {
          action.payload.success(response);

          resolve(response)
        }, (xhr, responseData) => {
          action.payload.error(xhr, responseData);

          reject();
        });
    });

    let response = yield promise;

    yield put(interfaceActions.getInsuranceCompanies());

    if(action.payload && typeof action.payload.callback === "function")
    {
      yield call(action.payload.callback);
    }
  } catch (err) {
    console.error(err);
  }
}

function* getInsuranceCompanies(action) {
  try {
    const state = yield select();

    if(state.interfaceComponents.client)
    {
      const promise = new Promise((resolve, reject) => {
        Api.get('active', 'insurance-companies', {
          user_id: state.interfaceComponents.client.id
        }).all((response) => {
          resolve(response)
        }, () => {
          reject();
        });
      });

      let response = yield promise;

      yield put(interfaceActions.handleInsuranceCompanies(response));

      if(action.payload && typeof action.payload.callback === "function")
      {
        yield call(action.payload.callback);
      }
    }
  } catch (err) {
    console.error(err);
  }
}

function* putCustomType(action) {
  try {
    const promise = new Promise((resolve, reject) => {
      Api.putArg('active-custom-type', 'update', action.payload.form.id, action.payload.form)
        .withoutNotify()
        .call((response) => {
          action.payload.success(response);

          resolve(response)
        }, (xhr, responseData) => {
          action.payload.error(xhr, responseData);

          reject();
        });
    });

    let response = yield promise;

    yield put(interfaceActions.getSpendTypes());
    yield put(interfaceActions.getIncomeTypes());
    yield put(interfaceActions.getActiveTags());
    yield put(interfaceActions.getActiveSubcategories());
    yield put(interfaceActions.getPropertyTypes());
    yield put(interfaceActions.getInvestTypes());

    if(action.payload && typeof action.payload.callback === "function")
    {
      yield call(action.payload.callback);
    }
  } catch (err) {
    console.error(err);
  }
}



export default function* watcher() {
  yield takeLatest(interfaceActions.getCurrencies, getCurrencies)
  yield takeLatest(interfaceActions.getClients, getClients)
  yield takeLatest(interfaceActions.getAtonFiles, getAtonFiles)
  yield takeLatest(interfaceActions.getCourses, getCourses)
  yield takeLatest(interfaceActions.getSpendTypes, getSpendTypes)
  yield takeLatest(interfaceActions.getIncomeTypes, getIncomeTypes)
  yield takeLatest(interfaceActions.getActiveTags, getActiveTags)
  yield takeLatest(interfaceActions.getActiveSubcategories, getActiveSubcategories)
  yield takeLatest(interfaceActions.getPropertyTypes, getPropertyTypes)
  yield takeLatest(interfaceActions.getInvestTypes, getInvestTypes)
  yield takeLatest(interfaceActions.getGroupTypes, getGroupTypes)
  yield takeLatest(interfaceActions.getClassTypes, getClassTypes)
  yield takeLatest(interfaceActions.postCustomType, postCustomType)
  yield takeLatest(interfaceActions.putCustomType, putCustomType)
  yield takeLatest(interfaceActions.postCustomGroupType, postCustomGroupType)
  yield takeLatest(interfaceActions.postCustomClassType, postCustomClassType)
  yield takeLatest(interfaceActions.getUserNotifications, getUserNotifications)
  yield takeLatest(interfaceActions.postCustomInsuranceCompany, postCustomInsuranceCompany)
  yield takeLatest(interfaceActions.getInsuranceCompanies, getInsuranceCompanies)
}
