import {Api} from "laravel-request";
import {AccountConstants, CurrencyConstants} from "finhelper";

const useAccountActions = (
        id: any,
        setId: (id: any) => void,
        userId: any,
        typeId: any,
        setTypeId: () => void,
        name: any,
        setName: () => void,
        bankId: any,
        setBankId: (bankId: any) => void,
        customBankId: any,
        setCustomBankId: (bankId: any) => void,
        subaccounts: any,
        setSubaccounts: (data: any) => void,
        searchBank: any,
        closeForm: () => void,
        callback: (data: any) => void,
        changeFormErrors: (errors: any) => void,
        loading: boolean,
        setLoading: (loading: boolean) => void
    ) => {

        const addSubAccount = () => {
            let rubCurrency = CurrencyConstants.getCurrencyById(CurrencyConstants.RUBBLE_ID);

            // Создаем новый подаккаунт
            const newSubaccount = {
                account_id: id,
                currency: rubCurrency, // Берем первую валюту из списка
                //@ts-ignore
                currency_id: rubCurrency.id, // ID первой валюты
                sum: 0, // Начальная сумма
            };

            // Обновляем список подаккаунтов через setSubaccounts
            //@ts-ignore
            setSubaccounts((prevSubaccounts: any) => [...prevSubaccounts, newSubaccount]);
        };

        // Delete SubAccount Logic
        const deleteSubAccount = (key: number) => {
            //@ts-ignore
            setSubaccounts((prevSubaccounts) => {
                // Создаем новый массив, исключая элемент с индексом key
                //@ts-ignore
                const updatedSubaccounts = prevSubaccounts.filter((_, index) => index !== key);
                return updatedSubaccounts;
            });
        };


        const handleBankStore = () => {
            return new Promise<{ bankId: number | null, customBankId: number | null }>((resolve, reject) => {
                if (typeId === AccountConstants.BANK_ACCOUNT && !bankId && searchBank !== '') {
                    Api.post('user-account-custom-bank', 'store', {
                        user_id: userId,
                        name: searchBank
                    })
                        .withoutNotify()
                        .call((response: any) => {
                            if (response.meta && response.meta.id) {
                                const newBankId = AccountConstants.CUSTOM_BANK;
                                const newCustomBankId = response.meta.id;
                                setBankId(newBankId);
                                setCustomBankId(newCustomBankId);
                                resolve({bankId: newBankId, customBankId: newCustomBankId});
                            } else {
                                reject(new Error("Ошибка при создании банка"));
                            }
                        }, (xhr: any, responseData: any) => {
                            if (responseData?.meta?.errors) {
                                changeFormErrors(responseData?.meta?.errors);
                                reject(new Error("Ошибка валидации"));
                            }
                        });
                } else if (typeId === AccountConstants.BROKER_ACCOUNT && !bankId && searchBank !== '') {
                    Api.post('user-account-custom-broker', 'store', {
                        user_id: userId,
                        name: searchBank
                    })
                        .withoutNotify()
                        .call((response: any) => {
                            if (response.meta && response.meta.id) {
                                const newBankId = AccountConstants.CUSTOM_BROKER;
                                const newCustomBankId = response.meta.id;
                                setBankId(newBankId);
                                setCustomBankId(newCustomBankId);
                                resolve({bankId: newBankId, customBankId: newCustomBankId});
                            } else {
                                reject(new Error("Ошибка при создании брокера"));
                            }
                        }, (xhr: any, responseData: any) => {
                            if (responseData?.meta?.errors) {
                                changeFormErrors(responseData?.meta?.errors);
                                reject(new Error("Ошибка валидации"));
                            }
                        });
                } else {
                    resolve({bankId: bankId, customBankId: customBankId}); // Возвращаем текущие значения
                }
            });
        };


        const handleDelete = () => {
            setLoading(true);
            Api.delete('user-account', 'destroy', id, {user_id: userId})
                .call(
                    () => {
                        setLoading(false);
                        callback(null);
                    },
                    (xhr: any, responseData: any) => {
                        if (responseData?.meta?.errors) {
                            changeFormErrors(responseData?.meta?.errors);
                        }
                        setLoading(false);
                    }
                );
        };

        const handleRestore = () => {
            setLoading(true);
            Api.delete('user-account', 'restore', id, {user_id: userId})
                .call(
                    () => {
                        setLoading(false);
                        callback(null);
                    },
                    (xhr: any, responseData: any) => {
                        if (responseData?.meta?.errors) {
                            changeFormErrors(responseData?.meta?.errors);
                        }
                        setLoading(false);
                    }
                );
        };

        const handleUpdate = async () => {
            setLoading(true);

            const { bankId: updatedBankId, customBankId: updatedCustomBankId } = await handleBankStore();

            const data = {
                id: id,
                bank_id: updatedBankId,
                custom_bank_id: updatedCustomBankId,
                type_id: typeId,
                name: name,
                accounts: subaccounts
            };

            Api.putArg('user-account', 'update', id, data)
                .call(
                    (response: any) => {
                        callback(response);
                        setSubaccounts(response?.data?.accounts)
                        changeFormErrors({});
                        setLoading(false);
                    },
                    (xhr: any, responseData: any) => {
                        if (responseData?.meta?.errors) {
                            changeFormErrors(responseData?.meta?.errors);
                        }
                        setLoading(false);
                    }
                );
        };

        const handleCreate = async () => {
            setLoading(true);

            const { bankId: updatedBankId, customBankId: updatedCustomBankId } = await handleBankStore();

            const data = {
                user_id: userId,
                bank_id: updatedBankId,
                custom_bank_id: updatedCustomBankId,
                type_id: typeId,
                name: name,
                accounts: subaccounts
            };

            Api.post('user-account', 'store', data)
                .call(
                    (response: any) => {
                        setId(response.meta.id);
                        callback(response);
                        setSubaccounts(response?.data?.accounts)
                        changeFormErrors({});
                        setLoading(false);
                    },
                    (xhr: any, responseData: any) => {
                        if (responseData?.meta?.errors) {
                            changeFormErrors(responseData?.meta?.errors);
                        }
                        setLoading(false);
                    }
                );
        };

        return {
            loading,
            handleDelete,
            handleRestore,
            handleUpdate,
            handleCreate,
            addSubAccount,
            deleteSubAccount,
            handleBankStore
        };
    }
;

export default useAccountActions;