import React, {useState} from 'react';
import ModalForm from "#app/components/ModalForm/ModalForm";
import {Row} from "#app/pages/styles";
import {Button} from "finform";

interface DeleteButtonProps {
    handleDelete: () => void; // Функция для управления состоянием загрузки
}

const DeleteButton: React.FC<DeleteButtonProps> = ({
                                                       handleDelete,
                                                   }) => {
    const [modalVisible, setModalVisible] = useState(false);

    const openModal = () => {
        setModalVisible(true);
    };

    // Функция для закрытия модального окна
    const closeForm = () => {
        setModalVisible(false);
    };

    // Функция для подтверждения удаления
    const confirmDelete = () => {
        handleDelete(); // Вызываем переданную функцию удаления
        closeForm();   // Закрываем модальное окно после удаления
    };

    return <>
        <div style={{
            position: 'absolute',
            bottom: '14px',
            zIndex: '1'
        }}>
            <img
                style={{
                    cursor: 'pointer',
                    marginLeft: '10px',
                }}
                title="Удалить"
                src={require('#app/assets/icons/trash.svg').default}
                onClick={openModal}
            />
        </div>
        <ModalForm
            header={'Подтвердите удаление'}
            modalVisible={modalVisible}
            closeForm={closeForm}
            width='886px'
        >
            <Row style={{
                justifyContent: 'center'
            }}>
                <Button slim type="cancel" onClick={closeForm}>Отмена</Button>
                <Button slim id='confirm' onClick={confirmDelete}>Подтвердить</Button>
            </Row>
        </ModalForm>
    </>
};

export default DeleteButton;