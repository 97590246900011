import {Row} from "#app/pages/styles";
import React from "react";
import {Input, InputContainer, NumberInput, Select} from "finform";
import {connect} from "react-redux";
import InfoPopup from "#app/pages/Relations/Accounts/form/parts/InfoPopup";
import ConfirmModal from "#app/components/ConfirmModal";
import HiddenInput from "#app/pages/Relations/components/HiddenInput";

const SubAccount = ({
    number,
    item,
    showDelete,
    deleteSubAccount,
    onUpdate,
    getError,
    i18n,
    currencies
}) =>
{
    const updatedCurrencies = currencies.map(currency => ({
        ...currency, // Копируем все поля объекта
        name: currency.code, // Переопределяем поле code
    }));

    const handleNameChange = (value) => {
        onUpdate({ name: value });
    };

    const handleSumChange = (value) => {
        onUpdate({ sum: value });
    };

    const handleCurrencyChange = (selectedItem) => {
        if (selectedItem) {
            onUpdate({ currency_id: selectedItem.id });
        }
    };

    return <Row style={{marginLeft: '10px'}} id={'user-account-currency-' + item.id}>
        {showDelete ?
            <ConfirmModal
                action={() => {deleteSubAccount(number)}}
                confirmButtonId={'confirm-delete-account-' + number}
                confirmText={'Подтвержить удаление субсчёта'}
                trigger={<img id={'delete-account-' + number} style={{cursor: "pointer"}} src={require('#app/assets/icons/delete.svg').default} alt=''/>}
            />
            :
            <img style={{cursor: "pointer"}} src={require('#app/assets/icons/undelete.svg').default} alt=''/>
        }
        <HiddenInput id={'accounts.' + number + '.id'} value={item.id}/>
        <InputContainer error={getError('accounts.' + number + '.name')}>
            <Input
                id={'accounts.' + number + '.name'}
                name={'accounts.' + number + '.name'}
                value={item.name}
                onChange={handleNameChange}
                placeholder={i18n.accounts.nameSubAccount}
            />
        </InputContainer>
        <InputContainer error={getError('accounts.' + number + '.sum')}>
            <NumberInput
                id={'accounts.' + number + '.sum'}
                name={'accounts.' + number + '.sum'}
                value={item.sum}
                onChange={handleSumChange}
                placeholder={i18n.accounts.balance}
            />
        </InputContainer>
        <InputContainer style={{maxWidth: '120px', border: 'none'}} error={getError('accounts.' + number + '.currency_id')}>
            <Select
                id={'accounts.' + number + '.currency_id'}
                name={'accounts.' + number + '.currency_id'}
                disabled={!!item.id}//валюту не меняем у сохраненных записей, может быть
                value={item.currency_id}
                onChange={handleCurrencyChange}
                options={updatedCurrencies}
                placeholder={i18n.accounts.typeAccount}
            />
        </InputContainer>
        {!!item.update_account ? <InfoPopup content={item.update_account} id={'popup-repayment-' + item.id}/> : <div style={{width: '20px'}}/>}
    </Row>
}

const enhance = connect(
    state => ({
        i18n: state.i18n,
        currencies: state.interfaceComponents.currencies,
    }),
    {}
);

export default enhance(SubAccount);