import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {setAccountForm, setAccountModalVisible, setAvailableTypes} from "#app/actions/account";
import styled from "styled-components";

export const Container = styled.div`
    cursor: pointer;
    color: #4378FF;
    display: flex;
    align-items: center;
    margin-left: 10px;
    padding: 10px 6px;
    border-radius: 12px 0 0 12px; /* Закругление только для левых углов */
    width: 55px;
    margin-top: 10px;
    position: absolute;
    background-color: #4378FF;
    left: -76px;
    top: 10px;
    
    img:hover{
        opacity: 0.9;
    }
`

const AddAccountButton = ({
                              types,
                              setAccountModalVisible,
                              setAvailableTypes,
                          }: {
    types: any,
    setAccountModalVisible: any,
    setAvailableTypes: any,
}) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    useEffect(() => {
        // Создаем скрытый элемент <img> для предварительной загрузки изображения
        const img = new Image();
        img.src = require('#app/assets/icons/ic_add.svg').default;
        img.onload = () => setIsImageLoaded(true); // Устанавливаем флаг после загрузки
    }, []); // Выполняется один раз при монтировании компонента

    if (!isImageLoaded) {
        return null; // Return null instead of false
    }

    return (
        <Container id='add_account' onClick={() => {
            setAccountModalVisible(true);
            // @ts-ignore
            setAvailableTypes(types);
        }} title={'Создать счёт'}>
            <img
                src={require('#app/assets/icons/ic_add.svg').default}
                alt=''
            />
        </Container>
    );
};

const enhance = connect(
    (state: any) => ({
        accountModalVisible: state.account.modalVisible,
        accountForm: state.account.form,
    }),
    {
        setAccountModalVisible,
        setAvailableTypes,
        setAccountForm
    }
);

export default enhance(AddAccountButton);