import {Api} from "laravel-request";
import {ActiveConstants, Money} from "finhelper";
import CalcHelper from "#app/helpers/CalcHelper";

const ORDER_TYPES = [
  ActiveConstants.STOCK,
  ActiveConstants.OBLIGATION,
  ActiveConstants.STRUCTURE_PRODUCT,
  ActiveConstants.INVESTMENT_LIFE_INSURANCE,
  ActiveConstants.EXCHANGE_NOTE,
  ActiveConstants.OBLIGATION_NOTE,
  ActiveConstants.STRATEGY_DU,
  ActiveConstants.OPTION,
  ActiveConstants.FUTURES,
];

const ASSET_TYPES = [
  'Акции',
  'Акции привилегированные',
  'Валюта',
  'Депозитарная расписка',
  'ОФЗ',
  'Облигация центрального банка',
  'Региональная облигация',
  'Муниципальная облигация',
  'Корпоративная облигация',
  'Биржевая облигация',
  'Облигация МФО',
  'Еврооблигации',
  'Пай открытого ПИФа',
  'Пай интервального ПИФа',
  'Индекс РТС',
  'Пай закрытого ПИФа',
  'Ипотечный сертификат',
  'ETF',
  'Индекс фондового рынка',
  'Пай биржевого ПИФа',
  'Депозит с ЦК',
  'Коммерческая облигация',
  'Государственная облигация',
  'Валютный фиксинг',
  'Валютный фиксинг',
  'Бивалютная корзина',
  'Металл золото',
  'Металл серебро',
  'Валютный фьючерс',
  'Товарный фьючерс',
  'Средневзвешенный курс',
  'Фьючерс',
  'Опцион',
  'Сахар',
];

export default class SearchAllTypesHelper
{
  static ALL_TYPES = 'search-all-types';
  static STOCKS = 'search-stocks';
  static BONDS = 'search-bonds';
  static CURRENCIES = 'search-currencies';
  static FUTURES = 'search-futures';

  static getSearchString(active) {
    if (active && active.item) {
      return active.item.symbol;
    } else if (active) {
      return active.name;
    }
    return '';
  }

  /**
   *
   * @param item
   * @param active
   * @return {*|boolean}
   */
  static findMatchingItem(item, active)
  {
    return item && active &&
      (item.id === active?.item_id || item.id === active?.id) &&
      (item.ticker === active?.item_type || !active?.item_type);
  };

  /**
   *
   * @param item
   * @param prv
   */
  static updateFormWithItem(item, prv)
  {
    prv.form.item_id = `${item.id}-${item.type_id}`;
    prv.form.item_type = item.type_id;
    prv.form.item_search = item.name;
    prv.form.item = item;
  };

  /**
   *
   * @param search
   * @param component
   * @param method
   * @param bindString
   * @param loadingKey
   * @param callback
   * @param data
   */
  static handleResponse(search, component, method, bindString, loadingKey, callback, data)
  {
    if(component.requests.length === 0)
    {
      component.setState({
        [loadingKey]: false,
        [bindString]: SearchAllTypesHelper.sortResponse(search, data)
      }, () => {
        if(typeof callback === 'function')
        {
          callback()
        }
      });
    }else{
      SearchAllTypesHelper.handleStockSearch(component, method, bindString, true, loadingKey, callback);
    }
  }

  /**
   *
   * @param component
   * @param method
   * @param bindString
   * @param force
   * @param loadingKey
   * @param callback
   */
  static handleStockSearch(component, method, bindString = 'stocks', force = false, loadingKey = 'stockLoading', callback)
  {
    let lastIndex = component.requests.length - 1;

    if(component.state[loadingKey] === false || force)
    {
      let search = component.requests[lastIndex];
      component.requests.splice(0, lastIndex + 1);

      component.setState({
        [loadingKey]: true,
        [bindString]: []
      }, () => {
        Api.get('active', method, {
          item_search: search,
          user_id: component.props.client ? component.props.client.id : '',
          search_stock: 1
        }).call((response) => {
          SearchAllTypesHelper.handleResponse(search, component, method, bindString, loadingKey, callback, response.data)
        }, () => {
          SearchAllTypesHelper.handleResponse(search, component, method, bindString, loadingKey, callback, [])
        });
      });
    }
  }

  /**
   *
   * @param component
   * @param search
   * @param method
   * @param bindString
   * @param loadingKey
   */
  static onSearch(component, search, method, bindString = 'stocks', loadingKey = 'stockLoading')
  {
    component.requests.push(search);

    if(component.lastTimeoutId)
    {
      clearTimeout(component.lastTimeoutId)
    }

    component.lastTimeoutId = setTimeout(() => {
      SearchAllTypesHelper.handleStockSearch(component, method, bindString, false, loadingKey)
    }, component.msTimeout)
  }

  /**
   *
   * @param search
   * @param array
   * @returns {*}
   */
  static sortResponse(search, array)
  {
    // Проверяем, состоит ли строка поиска из трех латинских букв
    const isThreeLatinLetters = /^[A-Za-z]{3}$/.test(search);

    const sortedArray = array.sort((a, b) => {
      // Приоритет для валюты type_id = 3102, если запрос состоит из трех латинских букв
      if (isThreeLatinLetters) {
        if (a.type_id === 3102 && b.type_id !== 3102) return -1;
        if (a.type_id !== 3102 && b.type_id === 3102) return 1;
      }

      // Стандартная сортировка по ASSET_TYPES
      const aIndex = ASSET_TYPES.indexOf(a.name);
      const bIndex = ASSET_TYPES.indexOf(b.name);

      // Если type_id не найден в справочнике, считаем его индекс как Infinity (последнее место)
      const aFinalIndex = aIndex === -1 ? Infinity : aIndex;
      const bFinalIndex = bIndex === -1 ? Infinity : bIndex;

      return aFinalIndex - bFinalIndex;
    });

    // Внутренняя сортировка элементов внутри групп
    sortedArray.forEach((item) => {
      if (item.items && item.items.length > 0) {
        item.items.sort((a, b) => {
          const aListLevel = a.listlevel !== undefined ? a.listlevel : -Infinity;
          const bListLevel = b.listlevel !== undefined ? b.listlevel : -Infinity;

          // Сортируем по убыванию, объекты без listlevel в конец
          return bListLevel - aListLevel;
        });
      }
    });

    return sortedArray;
  }

  /**
   *
   * @param component
   * @param active
   * @param client
   * @param force
   * @param itemsKey
   * @param loadingKey
   */
  static search(component, active, client, force = false, itemsKey = 'stocks', loadingKey = 'stockLoading') {
    let search = SearchAllTypesHelper.getSearchString(active);

    component.setState({
      [loadingKey]: true
    }, () => {
      Api.get('active', SearchAllTypesHelper.ALL_TYPES, {
        item_search: search,
        user_id: client ? client.id : '',
        search_stock: Number(force)
      }).call((stockResponse) => {
        component.setState((prv) => {

          prv[itemsKey] = SearchAllTypesHelper.sortResponse(search, stockResponse.data);
          prv[loadingKey] = false;

          stockResponse.data?.forEach((group) => {
            group?.items?.forEach((item) => {
              if (SearchAllTypesHelper.findMatchingItem(item, active)) {
                SearchAllTypesHelper.updateFormWithItem(item, prv);
              }
            });
          });

          if (!prv.form.item) {
            stockResponse.data?.forEach((group) => {
              group?.items?.forEach((item) => {
                if (SearchAllTypesHelper.findMatchingItem(item, active)) {
                  SearchAllTypesHelper.updateFormWithItem(item, prv);
                }
              });
            });
          }

          return prv;
        }, () => {
          SearchAllTypesHelper.handleCurrentCount(component, component.state.form.item);
        });
      });
    })
  }

  /**
   *
   * @param component
   * @param typeId
   * @return {ApiRequest}
   */
  static buildCurrentCountQuery(component, typeId) {
    let query = Api.get('active', 'index', { user_id: component.props.client.id });

    if (ActiveConstants.GROUP_QUERY_CATALOG.includes(typeId) && component.state.form.item && component.state.form.item.ticker) {
      query.where('item_id', component.state.form.item.id)
        .where('item_type', component.state.form.item.ticker)
        .whereIn('type_id', ActiveConstants.GROUP_QUERY_CATALOG);
    } else if (ActiveConstants.GROUP_QUERY_CATALOG.includes(typeId)) {
      query.where('id', component.state.form.item.id)
        .whereIn('type_id', ActiveConstants.GROUP_QUERY_CATALOG)
        .whereNull('item_id');
    } else if (typeId === ActiveConstants.CURRENCY && component.state.form.item) {
      query.where('item_id', component.state.form.item.id)
        .where('type_id', ActiveConstants.CURRENCY);
    } else if (component.state.form.item) {
      query.where('id', component.state.form.item.id);
    }

    return query;
  }


  /**
   *
   * @param component
   * @param typeId
   */
  static handleCurrentCountQuery(component, typeId)
  {
    let query = SearchAllTypesHelper.buildCurrentCountQuery(component, typeId);

    //TODO показываем количество бумаг по субсчету, когда нужно показывать количество всем субсчетам данного счета
    query.where((query) =>
    {
      return query.whereDoesntHave('sell_trades')
        .orWhereDoesntHave('buy_trades');
    })
      .with('buy_trades', (query) => {
        query.where((query) => {
          return query.where('from_account_id', component.state.form.from_account_id)
            .orWhere('to_account_id', component.state.form.from_account_id);
        });

        if (component.state.form.place_id) {
          query.where('place_id', component.state.form.place_id);
        }

        return query;
      })
      .with('sell_trades', (query) => {
        query.where((query) => {
          return query.where('from_account_id', component.state.form.from_account_id)
            .orWhere('to_account_id', component.state.form.from_account_id);
        });

        if (component.state.form.place_id) {
          query.where('place_id', component.state.form.place_id);
        }

        return query;
      })
      .all((response) => {
        SearchAllTypesHelper.handleCurrentCountResponse(component, response);
      })
  }

  /**
   *
   * @param component
   * @param response
   */
  static handleCurrentCountResponse(component, response) {
    if (response && response.data.length) {
      let count = 0;
      response.data.forEach((item) => {
        item.buy_trades.forEach((trade) => {
          count += trade.count;
        });
        item.sell_trades.forEach((trade) => {
          count -= trade.count;
        });
      });

      component.setState((prv) => {
        const lotsize = component.state.form.item?.lotsize || 1;
        prv.form.current_count = Money.format(lotsize * count, 8);
        prv.form.lotsize = lotsize;

        CalcHelper.calcSum(prv);
        return prv;
      });
    } else {
      component.setState((prv) => {
        prv.form.current_count = 0;
        prv.form.lotsize = component.state.form.item?.lotsize || 1;
        CalcHelper.calcSum(prv);
        return prv;
      });
    }
  }

  /**
   *
   * @param component
   * @param item
   */
  static handleCurrentCount(component, item)
  {
    if(item)
    {
      SearchAllTypesHelper.handleCurrentCountQuery(component, item.type_id)

      component.setState((prv) => {
        prv.form.item_type = item.type_id;

        return prv;
      });
    }
  }
}