import {ActiveConstants} from "finhelper";

export const PENDING = 0;
export const STARTED = 1;
export const PROCESSING = 2;
export const FINISHED = 3;
export const FAILED = 4;

export function getTextByType(type) {
  switch (parseInt(type)) {
    case ActiveConstants.ZENMONEY:
      return 'Синхронизация с Дзенмани';
    case ActiveConstants.TINKOFF:
      return 'Синхронизация с "Тинькофф инвестиции"';
    case ActiveConstants.GOAL:
      return 'Идёт перерасчёт целей';
    case ActiveConstants.STRATEGY:
    case ActiveConstants.STRATEGY_CACHE:
      return 'Идёт рассчёт графика';
    case ActiveConstants.GOAL_RECALC:
      return 'Идёт перерассчёт целей';
    case ActiveConstants.ATON_FILE_PARSE:
    case ActiveConstants.ATON_CRM_PARSE:
    case ActiveConstants.BCS_FILE_PARSE:
      return 'Идёт загрузка брокерского отчета';
    default:
      return '';
  }
}
