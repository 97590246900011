import {all, fork} from 'redux-saga/effects'
import auth from './auth'
import interfaceComponents from './interface'
import scenario from './scenario'
import plan from './plan'
import jobs from './jobs'
import tactics from './tactics'
import trade from './trade'
import account from './account'

export default function* rootSaga() {
  return yield all([
    auth,
    interfaceComponents,
    scenario,
    plan,
    jobs,
    tactics,
    trade,
    account,
  ].map(fork))
}
