import {Api} from "laravel-request";
import {ActiveConstants, Money} from "finhelper";


/**
 *
 * @param catalogItem
 * @param clientId
 * @returns {ApiRequest | Builder}
 */
export const buildCurrentCountQuery = (catalogItem, clientId) => {
    let query = Api.get('active', 'index', {user_id: clientId});

    if(catalogItem)
    {
        if (ActiveConstants.GROUP_QUERY_CATALOG.includes(catalogItem.type_id) && catalogItem.ticker) {
            query.where('item_id', catalogItem.id)
                .where('item_type', catalogItem.ticker)
                .whereIn('type_id', ActiveConstants.GROUP_QUERY_CATALOG);
        } else if (ActiveConstants.GROUP_QUERY_CATALOG.includes(catalogItem.type_id)) {
            query.where('id', catalogItem.id)
                .whereIn('type_id', ActiveConstants.GROUP_QUERY_CATALOG)
                .whereNull('item_id');
        } else if (catalogItem.type_id === ActiveConstants.CURRENCY) {
            query.where('item_id', catalogItem.id)
                .where('type_id', ActiveConstants.CURRENCY);
        } else {
            query.where('id', catalogItem.id);
        }
    }

    return query;
}


/**
 *
 * @param setCurrentCount
 * @param catalogItem
 * @param clientId
 * @param fromAccountId
 * @param placeId
 */
export const handleCurrentCountQuery = (setCurrentCount, catalogItem, clientId, fromAccountId, placeId) => {
    let query = buildCurrentCountQuery(catalogItem, clientId);

    //TODO показываем количество бумаг по субсчету, когда нужно показывать количество всем субсчетам данного счета
    query.where((query) => {
        return query.whereDoesntHave('sell_trades')
            .orWhereDoesntHave('buy_trades');
    })
        .with('buy_trades', (query) => {
            query.where((query) => {
                return query.where('from_account_id', fromAccountId)
                    .orWhere('to_account_id', fromAccountId);
            });

            if (placeId) {
                query.where('place_id', placeId);
            }

            return query;
        })
        .with('sell_trades', (query) => {
            query.where((query) => {
                return query.where('from_account_id', fromAccountId)
                    .orWhere('to_account_id', fromAccountId);
            });

            if (placeId) {
                query.where('place_id', placeId);
            }

            return query;
        })
        .all((response) => {
            handleCurrentCountResponse(setCurrentCount, catalogItem, response);
        })
}

/**
 *
 * @param setCurrentCount
 * @param catalogItem
 * @param response
 */
export const handleCurrentCountResponse = (setCurrentCount, catalogItem, response) => {
    if (response && response.data.length) {
        let count = 0;
        response.data.forEach((item) => {
            item.buy_trades.forEach((trade) => {
                count += trade.count;
            });
            item.sell_trades.forEach((trade) => {
                count -= trade.count;
            });
        });

        const lotsize = catalogItem?.lotsize || 1;
        const currentCount = Money.format(lotsize * count, 8);

        setCurrentCount(currentCount)
    } else {
        setCurrentCount(0);
    }
}
