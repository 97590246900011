import React, {useCallback, useEffect, useState} from "react";
import ModalForm from "#app/components/ModalForm/ModalForm";
import {connect} from "react-redux";
import {Row} from "#app/pages/styles";
import {Button, DateTimeInput, GroupSearch, InputContainer, NumberInput, Select,} from "finform";
import DeleteButton from "#app/pages/Relations/components/DeleteButton";
import useTradeActions from "#app/pages/Relations/Trades/hooks/useTradeActions";
import {useTradeForm} from "#app/pages/Relations/Trades/hooks/useTradeForm";
import {ActiveConstants, TradeConstants} from "finhelper";
import InnerTab from "./components/InnerTabs/InnerTab";
import {TabWrapper} from "./components/InnerTabs/styles";
import useDebouncedSearch from "#app/pages/Relations/Trades/hooks/useDebouncedSearch";
import CommonFormFields from "#app/pages/Relations/Trades/form/parts/SumPriceCount/CommonFormFields";
import ObligationFormFields from "#app/pages/Relations/Trades/form/parts/SumPriceCount/ObligationFormFields";
import CommonAccounts from "#app/pages/Relations/Trades/form/parts/Accounts/CommonAccounts";
import CurrencyAccounts from "#app/pages/Relations/Trades/form/parts/Accounts/CurrencyAccounts";
import {places} from "#app/pages/Relations/Trades/components/Forms/CommonForm";
import GetAccount from "#app/pages/Relations/Trades/form/parts/Accounts/GetAccount";
import {handleCurrentCountQuery} from "#app/pages/Relations/Trades/form/utils/getCurrentCount";
import TradeCommissions from "#app/pages/Relations/TradeCommissions/TradeCommissions";
import TradePayments from "#app/pages/Relations/TradePayments/TradePayments";
import HiddenInput from "#app/pages/Relations/components/HiddenInput";

//TODO не показывает текущее кол-во бумаг при открытии на редактирование

// @ts-ignore
const TradeForm = ({
                       modalVisible,
                       setModalVisible,
                       form,
                       setForm,
                       callback,

                       client,
                       accounts = [],
}: any) => {

    if (!modalVisible) {
        return null;
    }

    if (!client) {
        return null;
    }

    const [search, setSearch] = useState('');

    const { isLoadingSearch, searchResults, setSearchResults, handleSearch } = useDebouncedSearch({
        debounceTime: 300,
    }, client ? client.id : '');

    const onSearchChange = useCallback(
        (value: any) => {
            handleSearch(value);
            setSearch(value);
        },
        [handleSearch, setSearch]
    );

    const [currentCount, setCurrentCount] = useState<number|string>('');
    const [lotSize, setLotsize] = useState<number|string>('');

    const [loading, setLoading] = useState(false);

    const {
        id, setId,
        userId, setUserId,
        typeId, setTypeId,
        catalogItem, setCatalogItem,
        currencyId, setCurrencyId,
        fromAccountId, setFromAccountId,
        getAccountId, setGetAccountId,
        toAccountId, setToAccountId,

        sum, handleSumChange,
        price, handlePriceChange,
        count, handleCountChange,
        accumulatedCoupon, handleAccumulatedCoupon,
        isSumBlocked,
        isPriceBlocked,
        isCountBlocked,

        tradeAt, setTradeAt,
        placeId, setPlaceId,
        activeId, setActiveId,

        formErrors, changeFormErrors,
        hasError, getError,
        closeForm, clearForm,
    } = useTradeForm(form, client, setModalVisible, setForm, lotSize, setSearch, setSearchResults);


    const { handleDelete, handleUpdate, handleCreate } = useTradeActions(
        id, setId,
        userId, typeId,
        currencyId, setCurrencyId,
        fromAccountId, getAccountId, toAccountId,
        price, sum, count,
        tradeAt, placeId,
        activeId, setActiveId,
        catalogItem,
        closeForm, callback,
        changeFormErrors, loading, setLoading
    );

    useEffect(() => {
        if(catalogItem)
        {
            handleCurrentCountQuery(setCurrentCount, catalogItem, client.id, fromAccountId, placeId);
            // @ts-ignore
            setLotsize(catalogItem?.lotsize || 1)
        }else{
            setCurrentCount(0);
            setLotsize(1)
        }
    }, [catalogItem, client, fromAccountId, placeId, typeId])

    console.log(catalogItem)

    const renderButtons = () => {
        if (id) {
            return <Row>
                <DeleteButton handleDelete={handleDelete} />
                <Row style={{ display: 'block', textAlign: 'center' }}>
                    <Button type="cancel" withMargin narrow onClick={closeForm} id='create-trade-cancel'>
                        Отмена
                    </Button>
                    <Button withMargin narrow loading={loading} onClick={handleUpdate} id='create-trade-save'>
                        Сохранить
                    </Button>
                </Row>
            </Row>
        } else {
            return <Row style={{ display: 'block', textAlign: 'center' }}>
                <Button type="cancel" withMargin narrow onClick={closeForm} id='create-trade-cancel'>
                    Отмена
                </Button>
                <Button withMargin narrow loading={loading} onClick={handleCreate} id='create-trade-save'>
                    Сохранить
                </Button>
            </Row>
        }
    }

    const renderAccounts = () =>
    {
        //@ts-ignore
        switch (catalogItem?.type_id)
        {
            case ActiveConstants.CURRENCY:
                return <CurrencyAccounts
                    fromAccountId={fromAccountId}
                    setFromAccountId={setFromAccountId}
                    toAccountId={toAccountId}
                    setToAccountId={setToAccountId}
                    accounts={accounts}
                    getError={getError}
                />
            default:
                return <CommonAccounts
                    fromAccountId={fromAccountId}
                    setFromAccountId={setFromAccountId}
                    accounts={accounts}
                    catalogItem={catalogItem}
                    getError={getError}
                />
        }
    }

    const renderSumPriceCount = () => {
        //@ts-ignore
        if(ActiveConstants.COUPON_GROUP.includes(catalogItem?.type_id)){
            return <ObligationFormFields
                sum={sum}
                setSum={handleSumChange}
                price={price}
                setPrice={handlePriceChange}
                count={count}
                setCount={handleCountChange}
                accumulatedCoupon={accumulatedCoupon}
                setAccumulatedCoupon={handleAccumulatedCoupon}
                isCountBlocked={isCountBlocked}
                isPriceBlocked={isPriceBlocked}
                isSumBlocked={isSumBlocked}
                catalogItem={catalogItem}
                getError={getError}
            />
        }else{
            return <CommonFormFields
                sum={sum}
                setSum={handleSumChange}
                price={price}
                setPrice={handlePriceChange}
                count={count}
                setCount={handleCountChange}
                isCountBlocked={isCountBlocked}
                isPriceBlocked={isPriceBlocked}
                isSumBlocked={isSumBlocked}
                getError={getError}
            />
        }
    }

    const renderPayments = () => {

        //только для покупок
        if(typeId === TradeConstants.BUY)
        {
            let title = '';
            // @ts-ignore
            if(ActiveConstants.COUPON_GROUP.includes(catalogItem?.type_id)){
                title = 'Выплаты купонов';
            // @ts-ignore
            }else if(ActiveConstants.DIVIDEND_GROUP.includes(catalogItem?.type_id)){
                title = 'Выплаты дивидендов';
            }

            // @ts-ignore
            return <TradePayments tradeId={id} activeId={activeId} title={title} />
        }
    }

    // @ts-ignore
    return <ModalForm
        header={
            typeId === TradeConstants.BUY
                ? 'Покупка финансового актива'
                : 'Продажа финансового актива'
        }
        modalVisible={modalVisible}
        closeForm={closeForm}
        width='886px'
        id='trade-form'
    >
        <>
            <Row>
                <HiddenInput id='active-trade-id' value={id}/>
                <HiddenInput id='active-id' value={activeId}/>
                <InputContainer error={getError('item_id')}>
                    <GroupSearch
                        name={'item'}
                        placeholder={'Поиск актива'}
                        loading={isLoadingSearch}
                        search={search}
                        id={'item'}
                        onSearch={onSearchChange}
                        onChange={(selectedItem: any) => {
                            if (selectedItem)
                            {
                                setCatalogItem(selectedItem)
                                setSearch(selectedItem.name)
                            }
                        }}
                        options={searchResults}
                    />
                </InputContainer>
                <Row>
                    <InputContainer>
                        <NumberInput
                            id='lotsize'
                            value={lotSize}
                            disabled={true}
                            placeholder={'Бумаг в 1 лоте'}
                        />
                    </InputContainer>
                    <InputContainer>
                        <NumberInput
                            id='current_count'
                            value={currentCount}
                            disabled={true}
                            placeholder={'Текущее количество бумаг'}
                        />
                    </InputContainer>
                </Row>
                {renderAccounts()}
                <TabWrapper style={{
                    width: '100%',
                    margin: '15px 10px'
                }}>
                    <InnerTab
                        className="style1"
                        id='button-buy'
                        style={{
                            width: '50%'
                        }}
                        onClick={() => {
                            setTypeId(TradeConstants.BUY);
                            // this.setState((prv) => {
                            //     prv.form.type = TradeConstants.getItemById(TradeConstants.BUY);
                            //     prv.form.type_id = TradeConstants.BUY;
                            //
                            //     return prv;
                            // }, () => {
                            //     if(typeof this.trackPrice === 'function')
                            //     {
                            //         this.trackPrice();
                            //     }
                            //     SearchAllTypesHelper.handleCurrentCount(this, this.state.form.item)
                            // });
                        }} active={typeId === TradeConstants.BUY}>Купить</InnerTab>
                    <InnerTab
                        className="style2"
                        id='button-sell'
                        style={{
                            width: '50%'
                        }}
                        onClick={() => {
                            setTypeId(TradeConstants.SELL);
                            // this.setState((prv) => {
                            //     prv.form.type = TradeConstants.getItemById(TradeConstants.SELL);
                            //     prv.form.type_id = TradeConstants.SELL;
                            //
                            //     return prv;
                            // }, () => {
                            //     if(typeof this.trackPrice === 'function')
                            //     {
                            //         this.trackPrice();
                            //     }
                            //     SearchAllTypesHelper.handleCurrentCount(this, this.state.form.item)
                            // });
                        }} active={typeId === TradeConstants.SELL}>Продать</InnerTab>
                </TabWrapper>
                {renderSumPriceCount()}
                <Row>
                    <InputContainer error={getError('trade_at')}>
                        <DateTimeInput
                            id='trade_at'
                            value={tradeAt}
                            onChange={setTradeAt}
                            placeholder={
                                typeId === TradeConstants.BUY
                                    ? 'Дата и время покупки'
                                    : 'Дата и время продажи'
                            }
                        />
                    </InputContainer>
                    <GetAccount
                        accounts={accounts}
                        typeId={typeId}
                        fromAccountId={fromAccountId}
                        getAccountId={getAccountId}
                        setGetAccountId={setGetAccountId}
                        catalogItem={catalogItem}
                        getError={getError}
                    />
                </Row>
                <Row>
                    <InputContainer style={{border: 'none'}} error={getError('place_id')}>
                        <Select
                            value={placeId}
                            onChange={(item: any) => {
                                if(item){
                                    setPlaceId(item.id)
                                }
                            }}
                            options={places}
                            placeholder={'Место хранения (депозитарий)'}
                        />
                    </InputContainer>
                </Row>
            </Row>
        </>
        <TradeCommissions tradeId={id} title={'Комиссии'}/>
        {renderPayments()}
        {renderButtons()}
    </ModalForm>
}

const enhance = connect(
    (state: any) => ({
        client: state.interfaceComponents.client,
        currencies: state.interfaceComponents.currencies,
        accounts: state.account.accounts,
    }),
    {}
);

export default enhance(TradeForm);