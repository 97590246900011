import React, {useEffect} from 'react';
import {handleTitle} from "../../../actions/interface";
import {connect} from "react-redux";
import {CenteredWrapper, Container} from '../../../pages/styles';
import {Img, ImgContainer, NotFoundContainer, Text, Title} from './styles';
import {Button} from "finform/old";
import {useLocation} from "react-router-dom";

const UnexpectedError = (props) => {
  const location = useLocation();

  useEffect(() => {
    props.handleTitle('Ошибка');
    // window.scrollTo(0, 0); // Если нужно, раскомментируйте
  }, []);

  return (
    <Container style={{
      margin: '20px auto 0 auto'
    }}>
      <CenteredWrapper>
        <NotFoundContainer>
          <ImgContainer>
            <Img src={require('./images/swan.svg').default} />
          </ImgContainer>
          <Title>Ошибка</Title>
          <Text>Сообщите нам об ошибке info@whiteswan.finance</Text>
          <Text></Text>
          <Button className='style1' type='href' href={location.pathname.indexOf('/cabinet') !== -1 ? '/cabinet' : '/'}>
            На главную
          </Button>
        </NotFoundContainer>
      </CenteredWrapper>
    </Container>
  );
};

const enhance = connect(
  (state) => ({}),
  { handleTitle }
);

export default enhance(UnexpectedError);
