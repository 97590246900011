// src/themes.ts
import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
    inputContainerBackground: "#fff",
    inputContainerBorder: "#D7DBE0",
    inputTextColor: "#000000",
    inputPlaceholderTextColor: "#6F7080",

    inputDisabledContainerBackground: "#FBFBFC",
    inputDisableTextColor: "#7F818D",

    checkboxColor: "#4378FF",
    checkMarkColor: "#fff",

    selectBackgroundColor: "#EFF2F5",
    selectTextColor: "#4378FF",
    selectArrowColor: "#4378FF",
    selectShadowColor: "transparent",
    selectScrollBarColor: "#333443",
    selectScrollThumbColor: "rgba(10, 10, 10, 0.2)",
    selectWrapperOptionBackgroundColor: "#EFF2F5",
    selectOptionBackgroundHovererColor: "#E1E6EC",
    selectOptionTextColor: "#4378FF",

    mainButtonBackgroundColor: '#3C6CE6',
    mainButtonTextColor: '#fff',
    mainButtonDisabledBackgroundColor: '#EFF2F5',
    mainButtonDisabledTextColor: '#7F818D',

    cancelButtonBackgroundColor: '#EFF2F5',
    cancelButtonTextColor: '#4C89FE',
    cancelButtonDisabledBackgroundColor: '#7F818D',

    blockButtonBackgroundColor: '#333443',
    blockButtonTextColor: '#4C89FE',
    blockButtonDisabledBackgroundColor: '#333443',

    transparentButtonTextColor: '#4C89FE',


    groupSelectBackground: '#EFF2F5', // Фон группы
    groupSelectTitleColor: '#333333', // Цвет заголовка группы
    subItemsContainerBackground: '#f9f9f9', // Фон контейнера подэлементов
    subItemTextColor: '#4C89FE', // Цвет текста подэлемента
    subItemHoverBackground: '#eaeaea', // Фон подэлемента при наведении
};

export default theme;
