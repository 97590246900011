import {IndexedDBCache} from "finhelper";

export default class CacheHelper
{

  static SERVER_DATA = 'server';
  static BALANCE = 'balance_{clientId}.{currencyId}';

  //вполне вероятно будут пересеченеия по активам, стоит использоть кеши всех активов
  static INVESTS = 'invests_{clientId}.{currencyId}';
  static ARCHIVE_INVESTS = 'archive_invests_{clientId}.{currencyId}';

  static PORTFOLIO = 'portfolio_data_{clientId}.{currencyId}';
  static TACTICS_MONTH = 'tactics_by_month_{clientId}.{startDate}.{endDate}.{currencyId}';
  static CURRENCIES = 'currencies';
  static COURSES = 'courses_{date}';

  /**
   *
   * @param pattern
   * @param params
   * @return {*}
   */
  static generateKey = (pattern, params) => {
    Object.keys(params).forEach(key => {
      // Replace all occurrences of {param} in the pattern with corresponding values
      pattern = pattern.replace(new RegExp(`{${key}}`, 'g'), params[key]);
    });

    return pattern;
  };

  /**
   *
   */
  static startAgain()
  {
    IndexedDBCache.clearAll();
  }

  /**
   *
   */
  static clearAll()
  {
    localStorage.clear();
    IndexedDBCache.clearAll();
  }

}