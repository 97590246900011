import React, {Fragment} from 'react';
import {Sign} from '../../deprecated/styles'
import {Row} from "#app/pages/styles";
import InnerTab from "../../../../../components/InnerTabs/InnerTab";
import {TabWrapper} from "../../../../../components/InnerTabs/styles";
import {
    Account,
    AccountConstants,
    Active,
    ActiveConstants,
    TradeConstants,
    TransferConstants,
    UserNotificationConstants
} from "finhelper"
import selectAccountHelper from "#app/helpers/getaccount"
import {Body} from "#app/components/Text/styles";
import AddButtonSvg from "#app/assets/icons/ic_add.svg";
import DeleteButtonSvg from "#app/assets/icons/delete.svg";
import EqualSvg from "#app/assets/icons/equal.svg";
import MultiSvg from "#app/assets/icons/multi.svg";
import SearchAllTypesHelper from "#app/helpers/SearchAllTypesHelper";
import CalcHelper from "#app/helpers/CalcHelper";

export const places = [
  {id: 1, name: 'ПРСД-Райф-Euroclear'},
  {id: 2, name: 'ПРСД-Райф-RBI'},
  {id: 3, name: 'ПРСД-НРД'},
  {id: 4, name: 'ПРСД-Райф-ASTANA-Exchange'},
  {id: 5, name: 'ПРСД-Райф-ARM'},
  {id: 6, name: 'Meta X Markets - BTA Finance Limited - Euroclear'},
]

export const getPlaceById = (id) => {
  return places.find(place => place.id === id) || null;
}

/**
 * @class TradesCreate
 * @param Base
 * @return {CommonForm}
 */
export default function commonForm(Base)
{
  class CommonForm extends Base
  {
    sumChanged(prv)
    {
      if(prv.form.sum === '')
      {
        prv.sumChanged = false;
      }else{
        prv.sumChanged = true;
      }

      if(prv.sumChanged && prv.priceChanged)
      {
        prv.countBlocked = true;
        CalcHelper.calcCount(prv);

        return prv;
      }

      if(prv.sumChanged && (prv.countChanged))
      {
        prv.priceBlocked = true;
        CalcHelper.calcPrice(prv);

        return prv;
      }

      prv.countBlocked = false;
      prv.priceBlocked = false;

      return prv;
    }

    priceChanged(prv)
    {
      if(prv.form.price === '')
      {
        prv.priceChanged = false;
      }else{
        prv.priceChanged = true;
      }

      if(prv.priceChanged && (prv.sumChanged))
      {
        prv.countBlocked = true;
        CalcHelper.calcCount(prv);

        return prv;
      }

      if(prv.priceChanged && (prv.countChanged))
      {
        prv.sumBlocked = true;
        CalcHelper.calcSum(prv);

        return prv;
      }

      prv.countBlocked = false;
      prv.sumBlocked = false;

      return prv;
    }

    countChanged(prv)
    {
      if(prv.form.count === '')
      {
        prv.countChanged = false;
      }else{
        prv.countChanged = true;
      }

      if(prv.countChanged && (prv.priceChanged))
      {
        prv.sumBlocked = true;
        CalcHelper.calcSum(prv);

        return prv;
      }

      if(prv.countChanged && prv.sumChanged)
      {
        prv.priceBlocked = true;
        CalcHelper.calcPrice(prv);

        return prv;
      }

      prv.sumBlocked = false;
      prv.priceBlocked = false;

      return prv;
    }

    /**
     *
     * @param field
     * @param condition
     * @return {boolean}
     */
    isDisabled(field = null, condition = false)
    {
      if(this.props.notificationType === UserNotificationConstants.ERROR_COUNT_STOCK)
      {
        switch (field)
        {
          case 'count':
          case 'from_account':
            return false;
        }
      }

      //если передали связь с уведомлением, то редактировать инструмент нельзя, можно только выбрать счет
      if(this.props.notificationIds?.length)
      {
        return true
      }

      return condition;
    }

    renderAction()
    {
      let parentAccount = AccountConstants.getAccountBySubAccountId(this.state.form.from_account?.id)

      if(this.isApiTinkoff(this.state, parentAccount))
      {
        return <Fragment>
          {this.renderSelectStyle1({
            field: 'action',
            text: 'Тип заявки',
            defaultText: 'Тип заявки',
            items: TradeConstants.actions(),
            disabled: !!this.state.form.id,
            callback: () => {
              if(typeof this.trackPrice === 'function')
              {
                this.trackPrice();
              }
            }
          })}
        </Fragment>
      }else{
        return null;
      }
    }

    renderChooseType()
    {
      return <Row>
        <TabWrapper style={{
          width: '100%',
          margin: '15px 10px'
        }}>
          <InnerTab
            className="style1"
            id='button-buy'
            style={{
              width: '50%'
            }}
            onClick={() => {
              this.setState((prv) => {
                prv.form.type = TradeConstants.getItemById(TradeConstants.BUY);
                prv.form.type_id = TradeConstants.BUY;

                return prv;
              }, () => {
                if(typeof this.trackPrice === 'function')
                {
                  this.trackPrice();
                }
                SearchAllTypesHelper.handleCurrentCount(this, this.state.form.item)
              });
            }} active={this.state.form.type && this.state.form.type_id === TradeConstants.BUY}>Купить</InnerTab>
          <InnerTab
            className="style2"
            id='button-sell'
            style={{
              width: '50%'
            }}
            onClick={() => {
              this.setState((prv) => {
                prv.form.type = TradeConstants.getItemById(TradeConstants.SELL);
                prv.form.type_id = TradeConstants.SELL;

                return prv;
              }, () => {
                if(typeof this.trackPrice === 'function')
                {
                  this.trackPrice();
                }
                SearchAllTypesHelper.handleCurrentCount(this, this.state.form.item)
              });
            }} active={this.state.form.type && this.state.form.type_id === TradeConstants.SELL}>Продать</InnerTab>
        </TabWrapper>
      </Row>
    }

    /**
     *
     * @return {JSX.Element}
     */
    renderFields()
    {
      return <Row>
        {this.renderInputNumber({
          field: 'price',
          text: (this.state.form.action_id === TradeConstants.MARKET) ? 'Текущая цена' : 'Цена',
          disabled: (this.state.form.action_id === TradeConstants.MARKET) ? true : this.state.priceBlocked,
          callback: () => {
            this.setState((prv) => {
              return this.priceChanged(prv)
            });
          }
        })}
        <Sign><img src={MultiSvg} alt=''/></Sign>
        {this.renderInputNumber({
          field: 'count',
          text: 'Количество лотов',
          disabled: this.isDisabled('count', this.state.countBlocked),
          callback: () => {
            this.setState((prv) => {
              return this.countChanged(prv);
            });
          }
        })}
        <Sign><img src={EqualSvg} alt=''/></Sign>
        {this.renderInputNumber({
          field: 'sum',
          text: 'Сумма',
          disabled: this.state.sumBlocked,
          callback: () => {
            this.setState((prv) => {
              return this.sumChanged(prv);
            });
          }
        })}
      </Row>
    }

    /**
     *
     * @param state
     * @param parentAccount
     * @return {*|boolean}
     */
    isApiTinkoff(state, parentAccount)
    {
      return state?.form?.from_account && state.form.from_account.is_api && parentAccount && parentAccount.bank_id === AccountConstants.TINKOFF_BROKER
    }

    renderSelectPlace()
    {
      return this.renderSelect({
        field: 'place',
        text: 'Место хранения (депозитарий)',
        defaultText: 'Место хранения (депозитарий)',
        items: places,
        callback: () => {
          SearchAllTypesHelper.handleCurrentCount(this, this.state.form.item)
        }
      })
    }

    renderCommonDateTimeInput()
    {
      let parentAccount = AccountConstants.getAccountBySubAccountId(this.state.form.from_account?.id)

      if(this.isApiTinkoff(this.state, parentAccount))
      {
        if(this.state.form.id)
        {
          return this.renderDateTimeInput({
            field: 'trade_at',
            text: 'Дата и время ' + CalcHelper.getTextByType(this),
          })
        }
      }else{
        return this.renderDateTimeInput({
          field: 'trade_at',
          text: 'Дата и время ' + CalcHelper.getTextByType(this),
        })
      }
    }

    getAccounts()
    {
      let accounts = [];

      this.props.accounts.map((account, key) => {
        accounts[key] = {...account};
        accounts[key].accounts = [];

        account.accounts.map((item) =>
        {
          //убрали фильтрацию по валюте актива, так как ограничивает действия пользователя при ручном вводе
          // if(this.state.form.item && this.state.form.item.currency_id && this.state.form.item.currency_id.length)
          // {
          //   if(this.state.form.item.currency_id.indexOf(item.currency_id) !== -1)
          //   {
          //     accounts[key].accounts.push(item);
          //   }
          // }else{
            accounts[key].accounts.push(item);
          // }
        });
      });

      return accounts;
    }

    renderFromAccount(fromAccounts, types)
    {
      return this.renderAccountGroupSelectStyle1({
        field: 'from_account',
        items: fromAccounts,
        text: 'Счёт',
        defaultText: 'Счёт',
        disabled: this.isDisabled('from_account'),
        accountAddAvailable: true,
        getUserAccounts: this.props.getUserAccounts,
        callback: (account) => {
          this.changeCurrency('from_account', 'currency');

          this.setState((prv) =>
          {
            if(account)
            {
              let parentAccount = AccountConstants.getAccountBySubAccountId(account?.id)

              if(account && account.is_api && parentAccount && parentAccount.bank_id === AccountConstants.TINKOFF_BROKER){
                prv.form.action_id = TradeConstants.MARKET;
                prv.form.action = {id: TradeConstants.MARKET, name: 'Рыночная заявка'};
              }else{
                prv.form.action_id = '';
                prv.form.action = null;
              }
            }else
            {
              prv.form.action_id = '';
              prv.form.action = null;
            }

            //для валюты
            if (prv.form.from_account && prv.form.to_account && prv.form.from_account.currency_id === prv.form.to_account.currency_id)
            {
                prv.form.to_account_id = '';
                prv.form.to_account = null;
            }

            //для облигаций и акций
            if(prv.getAccountChanged === false)
            {
                prv.form.get_account = prv.form.from_account;
                prv.form.get_account_id = prv.form.from_account_id;
            }

            return prv;
          }, () => {
            if(typeof this.trackPrice === 'function')
            {
              this.trackPrice();
            }
            SearchAllTypesHelper.handleCurrentCount(this, this.state.form.item)
          });
        },
        types: types,
        textLength: this.state.form.item_type === ActiveConstants.CURRENCY || this.state.form.action_type_id === TransferConstants.CHANGE ? 20 : 50,//если это валюта, то будет два выбора счета, количество символов нужно уменшить
        ...selectAccountHelper()
      })
    }

    /**
     *
     * @return {JSX.Element}
     */
    renderSearchSpending()
    {
      if(this.state.form?.item?.ticker === ActiveConstants.CUSTOM_CATALOG)
      {
          return <div onClick={() => {
            this.setState({
              showSelectSpendingModal: true
            })
          }} style={{
            display: 'flex',
            alignItems: 'center',
            padding: '0px 14px',
            cursor: 'pointer'
          }}>
            <img style={{
              marginRight: '15px',
              width: '24px',
            }} src={AddButtonSvg} alt=''/>
            <Body>Связать с расходом</Body>
          </div>
        // }
      }
    }

    /**
     *
     * @returns {unknown[]}
     */
    renderSelectedSpend()
    {
      if(this.state.form?.item?.ticker === ActiveConstants.CUSTOM_CATALOG && this.state.form?.operations && this.state.form?.operations?.length)
      {
        return this.state.form.operations.map((operation, key) => {

          return <Row key={key}>
            <img style={{
              margin: '0 20px',
              cursor: 'pointer'
            }} onClick={() => {
              this.setState((prv) => {
                prv.form.operations.splice(key, 1);

                return prv;
              });
            }} src={DeleteButtonSvg}/>
            {this.renderSelect({
              // disabled: true,
              textLength: 80,
              field: 'operations.' + key + '.operation',
              text: 'Связанный расход',
              defaultText: 'Связанный расход',
              items: [
                {id: operation.id, name: operation.name}
              ],
            })}
          </Row>
        })
      }
    }

    /**
     *
     * @param stocks
     * @param method
     * @returns {*|React.ReactElement<{}>}
     */
    renderSearchItem(stocks, method = SearchAllTypesHelper.ALL_TYPES)
    {
        return this.renderGroupRemoteSearch({
          field: 'item',
          items: stocks,
          text: 'Поиск актива',
          defaultText: 'Поиск актива',
          disabled: this.isDisabled('item'),
          onSearch: (search) => {
            SearchAllTypesHelper.onSearch(this, search, method)
          },
          callback: (item) => {
            if(typeof this.trackPrice === 'function')
            {
              this.trackPrice();
            }

            SearchAllTypesHelper.handleCurrentCount(this, item);
          },
          loading: this.state.stockLoading
        });
    }

    renderCommon()
    {
      let accounts = this.getAccounts()

      let stocks = Active.getGroup(this.state.stocks);

      let fromAccounts = [];
      let types = [];

      switch (this.state.form.item_type)
      {
        case ActiveConstants.CRYPTO:
          types = [AccountConstants.DIGIT_MONEY];
          fromAccounts = Account.getFiltered(this.state.form.from_account_id, accounts, types);
          break;
        default:
          types = [AccountConstants.BROKER_ACCOUNT, AccountConstants.BANK_ACCOUNT];
          fromAccounts = Account.getFiltered(this.state.form.from_account_id, accounts, types);
          break;
      }

      return <Fragment>
        <Row>
          {this.renderSearchItem(stocks)}
          {this.renderSearchSpending()}
        </Row>
        {this.renderSelectedSpend()}
        <Row>
          {this.renderInputNumber({
            field: 'lotsize',
            text: 'Бумаг в 1 лоте',
            disabled: true
          })}
          {this.renderInputNumber({
            field: 'current_count',
            text: 'Текущее количество бумаг',
            disabled: true
          })}
        </Row>
        <Row>
          {this.renderFromAccount(fromAccounts, types)}
          {this.renderAction()}
        </Row>
        {this.renderChooseType()}
        {this.renderFields()}
        <Row>
          {this.renderCommonDateTimeInput()}
          {this.renderEmpty()}
        </Row>
        <Row>
          {this.renderSelectPlace()}
        </Row>
      </Fragment>
    }
  }

  return CommonForm;
}

