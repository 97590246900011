import {Money, TradeConstants} from "finhelper";

export default class CalcHelper
{
  static calcCount(prv) {
    let sum = Money.toDigits(prv.form.sum);
    let price = Money.toDigits(prv.form.price);
    let lotsize = prv.form.lotsize ? Money.toDigits(prv.form.lotsize) : 1;

    if (sum > 0 && price > 0) {
      prv.form.count = Money.format(Math.round(sum / price / lotsize * 100000000) / 100000000, 8);
    }
  }

  static calcPrice(prv) {
    let sum = Money.toDigits(prv.form.sum);
    let count = Money.toDigits(prv.form.count);
    let lotsize = prv.form.lotsize ? Money.toDigits(prv.form.lotsize) : 1;

    if (sum > 0 && count > 0) {
      prv.form.price = Money.format(Math.round(sum / count / lotsize * 100000000) / 100000000, 8);
    }
  }

  static calcSum(prv) {
    let count = Money.toDigits(prv.form.count);
    let price = Money.toDigits(prv.form.price);
    let lotsize = prv.form.lotsize ? Money.toDigits(prv.form.lotsize) : 1;

    if (count > 0 && price > 0) {
      prv.form.sum = Money.format(Math.round(count * price * lotsize * 100000000) / 100000000, 8);
    }
  }


  static getTextByType(self)
  {
    if(self.state.form)
    {
      switch (self.state.form.type_id)
      {
        case TradeConstants.BUY:
          return 'списания';
        case TradeConstants.SELL:
          return 'зачисления';
        default:
          return '';
      }
    }
  }
}