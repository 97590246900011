import React, {useEffect, useState} from 'react';
import Layout from './../../pagesRouter/Layout/Layout';
import HeaderLanding from '../../interface/landing/header/Header';
import UnexpectedError from './UnexpectedError';
import {Api} from "laravel-request";
import {Url} from "finhelper";

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);
  const [errorInfo, setErrorInfo] = useState(null);

  // Эффект для отправки ошибки на сервер
  useEffect(() => {
    if (errorInfo) {
      const logError = async () => {
        try {
          const url = Url.getCurrentUrl();
          await Api.post('error-log', 'store', {
            message: errorInfo.error.message,
            trace: errorInfo.error.stack,
            url: url,
            info: errorInfo.info
          }).call();
        } catch (e) {
          console.error('Failed to log error:', e);
        }
      };
      logError();
    }
  }, [errorInfo]);

  // Static getDerivedStateFromError аналог
  const handleError = (error, info) => {
    setHasError(true);
    setErrorInfo({ error, info });
  };

  if (hasError) {
    return (
        <Layout>
          <HeaderLanding />
          <UnexpectedError errorInfo={errorInfo} />
        </Layout>
    );
  }

  return children;
};

// Обертка для перехвата ошибок
ErrorBoundary.getDerivedStateFromError = (error) => {
  return { hasError: true };
};

export default (props) => (
    <ErrorBoundary {...props}>
      {props.children}
    </ErrorBoundary>
);