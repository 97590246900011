import React from 'react';
import {Input, InputContainer} from "finform";

const HiddenInput = ({
                                  id,
                                  value
                              }: any) => {

    return <InputContainer style={{display: 'none'}}>
        <Input
            id={id}
            name={id}
            value={value}
        />
    </InputContainer>
}

export default HiddenInput