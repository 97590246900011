import {createReducer} from 'redux-act'

import * as actions from "#app/actions/tradeCommission";

let tradeCommission = {
  modalVisible: false,
  form: {},
  tradeId: null
};

const setTradeCommissionModalVisible = (state, bool) => {
  return {
    ...state,
    modalVisible: bool,
  }
}

const setTradeId = (state, tradeId) => {
  return {
    ...state,
    tradeId: tradeId,
  }
}

const setTradeCommissionForm = (state, form) => {
  return {
    ...state,
    form: form
  }
}

const reducer = createReducer({
  [actions.setTradeCommissionModalVisible]: setTradeCommissionModalVisible,
  [actions.setTradeCommissionForm]: setTradeCommissionForm,
  [actions.setTradeId]: setTradeId,
}, tradeCommission)

export default reducer
