import styled from 'styled-components'
import devices from '../../../devices'

export const Container = styled.div`
  //position: relative;
  margin-right: 30px;
  margin-left: 30px;
  
  @media ${devices.mobileL} { 
      margin-right: 3px;
      margin-left: 3px;
  }
  @media ${devices.tablet} { 
      margin-right: 3px;
      margin-left: 3px;
  }
  @media ${devices.laptop} { 
      margin-right: 5px;
      margin-left: 5px;
  }
  
  a{
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0;
    text-align: right;
    color: #4378FF;
    padding: 11px 24px;
    border-radius: 8px;
  }
  
  a.active{
    color: #000000;
  }
  
  a:hover{
    background-color: #EFF2F5;
  }
`

export const Title = styled.div`
  color: #4378FF;
  cursor: pointer;
  height: 56px;
  display: flex;
  align-items: center;
  position: relative;
  top: 0;
  font-size: 14px;
  font-weight: 700;
  
  &:hover{
    opacity: 0.7;
  }

  @media (max-width: 1030px) {
    font-size: 11px;
    margin: 0 10px;
  }
  
  &.active{
    color: #000;
  }

`

export const Popup = styled.div`
  box-shadow: 0 5px 32px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  display: ${props => props.open ? 'block' : 'none'};
  width: ${props => props.width};
  position: absolute;
  background-color: #fff;
  z-index: 1000000;
  
  h3 {
    font-size: 14px;
    line-height: 18px;
    opacity: 0.5;
  }
`

export const RelativePopup = styled.div`
  box-shadow: 0 5px 32px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  display: ${props => props.open ? 'block' : 'none'};
  position: relative;
  background-color: #fff;
  z-index: 1000000;
  
  h3 {
    font-size: 14px;
    line-height: 18px;
    opacity: 0.5;
  }
`

export const Links = styled.div`
  padding: 10px 20px;

  a {
    text-align: left;
    font-size: 14px;
    color: #4378FF;
    margin-bottom: 10px;
    display: block;
    text-decoration: none;

    &.active{
      color: #000000;
    }
    
    &:last-child {
      margin-bottom: 0;
    }
  }
`


