import React, {useCallback, useEffect, useState} from 'react';
import {connect} from "react-redux";
import TradePaymentRow from './components/TradePaymentRow/TradePaymentRow';
import {Block, Container} from './styles';
import {BodyBold} from './../../../components/Text/styles';
import {setPaymentForm, setPaymentModalVisible} from "#app/actions/payment";
import {Api} from "laravel-request";

const TradePayments = ({
                      tradeId,
                      title,
                      client,

                      paymentModalVisible,
                      setPaymentModalVisible,
                      setPaymentForm,
}) => {
    if (!client) {
        return null;
    }

    if (!tradeId) {
        return null;
    }

    const [items, setItems] = useState([]);

    const getItems = (tradeId) => {
        Api.get('active-payment', 'trade-payment-index', {
            user_id: client.id,
            trade_id: tradeId
        })
            .call((response) => {
                const updatedItems = response.data.map((item) => ({
                    ...item,
                    randomId: Math.random().toString(36).substr(2, 9), // Генерируем уникальный randomId
                }));
                setItems(updatedItems)
            })
    }

    useEffect(() => {
        //пока просто решение грузим на закрытие модального окна
        getItems(tradeId)
    }, [tradeId, paymentModalVisible])

    const callbackItem = (updatedItem) => {
        setItems((prevItems) => {
            // Создаем новый массив items с обновленной записью
            return prevItems.map((item) => {
                // Определяем, является ли текущая запись той, которую нужно обновить
                const isMatch = item.randomId === updatedItem.randomId; // Иначе сравниваем по randomId

                // Если запись совпадает, заменяем её на обновленную
                return isMatch ? { ...item, ...updatedItem } : item;
            });
        });
    }

    const handleClick = useCallback((item) => {
        setPaymentModalVisible(true)
        setPaymentForm(item)
    }, []);

    return <Block>
        <BodyBold style={{padding: '5px 0px 5px 10px'}} left>
            {title ?? 'Платежи'}
        </BodyBold>
        <Container>
            {items && items.length > 0 && items.map((item) => {
                return <TradePaymentRow
                    key={item.randomId}
                    clientId={client.id}
                    item={item}
                    callbackItem={callbackItem}
                    onClick={handleClick}
                />
            })}
        </Container>
    </Block>
};

const enhance = connect(
    (state) => ({
        i18n: state.i18n,
        client: state.interfaceComponents.client,

        paymentModalVisible: state.payment.modalVisible,
        paymentForm: state.payment.form,
    }),
    {
        setPaymentModalVisible,
        setPaymentForm,
    }
);

export default enhance(TradePayments);
