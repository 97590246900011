import React, {Fragment, useState} from "react";
import ModalForm from "#app/components/ModalForm/ModalForm";
import {connect} from "react-redux";
import {Row} from "#app/pages/styles";
import {Button, DateTimeInput, GroupSelect, InputContainer, NumberInput} from "finform";
import {AccountConstants} from "finhelper";
import AddAccountButton from "#app/pages/Relations/components/AddAccountButton";
import useTradePaymentActions from "#app/pages/Relations/TradePayments/hooks/useTradePaymentActions";
import {useTradePaymentForm} from "#app/pages/Relations/TradePayments/hooks/useTradePaymentForm";
import SelectAccountWrapper from "#app/pages/Relations/components/SelectAccountWrapper";
import {getAccounts} from "#app/pages/Relations/Trades/form/utils/accounts";

// @ts-ignore
const TradePaymentForm = ({
                       modalVisible,
                       setModalVisible,
                       form,
                       setForm,
                       callback,
                       client,

                       accounts = [],//from redux
}: any) => {

    if (!modalVisible) {
        return null;
    }

    if (!client) {
        return null;
    }


    const [loading, setLoading] = useState(false);


    const {
        id, setId,
        userId, setUserId,
        accountId, setAccountId,
        sum, setSum,
        paidAt, setPaidAt,

        formErrors, changeFormErrors,
        hasError, getError,
        closeForm, clearForm,
    } = useTradePaymentForm(form, client, setModalVisible, setForm);

    const { handleDelete, handleUpdate, handleCreate } = useTradePaymentActions(
        id, setId,
        userId,
        accountId,
        paidAt,
        sum,
        closeForm, callback,
        changeFormErrors, loading, setLoading
    );


    const renderButtons = () => {
        if (id) {
            return <Row>
                {/*<DeleteButton handleDelete={handleDelete} />*/}
                <Row style={{ display: 'block', textAlign: 'center' }}>
                    <Button type="cancel" withMargin narrow onClick={closeForm}>
                        Отмена
                    </Button>
                    <Button withMargin narrow loading={loading} onClick={handleUpdate}>
                        Сохранить
                    </Button>
                </Row>
            </Row>
        } else {
            return <Row style={{ display: 'block', textAlign: 'center' }}>
                <Button type="cancel" withMargin narrow onClick={closeForm}>
                    Отмена
                </Button>
                <Button withMargin narrow loading={loading} onClick={handleCreate}>
                    Сохранить
                </Button>
            </Row>
        }
    }

    let groupedAccounts = getAccounts(accounts)

    // @ts-ignore
    return <ModalForm
        header={
            id
                ? 'Редактирование платежа'
                : 'Новый платёж'
        }
        modalVisible={modalVisible}
        closeForm={closeForm}
        width='886px'
    >
        <>
            <Row>
                <SelectAccountWrapper
                    types={AccountConstants.types().map(type => type.id)}
                    placeholder='Счет'
                    id={'payment-account'}
                    value={accountId}
                    onChange={(item: any) => {
                        if(item)
                        {
                            setAccountId(item.id)
                        }
                    }}
                    options={groupedAccounts}
                    error={getError('account_id')}
                />
            </Row>
            <Row>
                <InputContainer error={getError('sum')}>
                    <NumberInput
                        id='trade-commission-sum'
                        value={sum}
                        onChange={setSum}
                        placeholder={'Сумма'}
                    />
                </InputContainer>
                <InputContainer error={getError('paid_at')}>
                    <DateTimeInput
                        id='payment-paid_at'
                        value={paidAt}
                        onChange={setPaidAt}
                        placeholder='Дата и время'
                    />
                </InputContainer>
            </Row>
        </>
        {renderButtons()}
    </ModalForm>
}


const enhance = connect(
    (state: any) => ({
        client: state.interfaceComponents.client,
        currencies: state.interfaceComponents.currencies,
        accounts: state.account.accounts,
    }),
    {}
);

export default enhance(TradePaymentForm);