import Pusher from 'pusher-js';
import {NotifyManager} from "notify-component";
import * as jobsActions from "../actions/jobs";
import LocalStorageHelper from "#app/helpers/Cache/LocalStorageHelper";
import {FAILED, FINISHED, getTextByType, PENDING, PROCESSING, STARTED} from "#app/middlewares/utils/webSocketHelper";


async function pending(store, job) {
  let state = store.getState();

  if (typeof state.jobs[job.job_id] === 'undefined') {
    NotifyManager.loadingOnce(job.job_id, '', getTextByType(job.type_id) + ', задача в очереди на выполнение.');
    store.dispatch(jobsActions.handleCreateJob(job.job_id));
  }
}

async function started(store, job) {
  let state = store.getState();

  if (typeof state.jobs[job.job_id] === 'undefined') {
    NotifyManager.loadingOnce(job.job_id, '', getTextByType(job.type_id));
    store.dispatch(jobsActions.handleCreateJob(job.job_id));
  }
}

async function failed(store, job) {
  let state = store.getState();

  if (typeof state.jobs[job.job_id] !== 'undefined') {
    NotifyManager.error('Ошибка', 'Произошла ошибка при выполнении операции');
    NotifyManager.delete(job.job_id);
    store.dispatch(jobsActions.handleRemoveJob(job.job_id));
  }
}

async function processing(store, job) {
  let state = store.getState();

  if (typeof state.jobs[job.job_id] === 'undefined') {
    NotifyManager.loadingOnce(job.job_id, '', getTextByType(job.type_id));
    store.dispatch(jobsActions.handleCreateJob(job.job_id));
    NotifyManager.update(job.job_id, parseFloat(job.percent));
  } else {
    NotifyManager.update(job.job_id, parseFloat(job.percent));
  }
}

async function finished(store, job) {
  let state = store.getState();

  if (typeof state.jobs[job.job_id] !== 'undefined') {
    NotifyManager.info('Успешно', 'Операция завершена');
    NotifyManager.delete(job.job_id);
    store.dispatch(jobsActions.handleRemoveJob(job.job_id));
  }
}

const eventQueue = [];
let isProcessingQueue = false;

export function enqueueEvent(store, job) {
  return new Promise((resolve) => {
    eventQueue.push({ store, job, resolve });
    processQueue();
  });
}

async function processQueue() {
  if (isProcessingQueue) return;
  isProcessingQueue = true;

  while (eventQueue.length > 0) {
    const { store, job, resolve } = eventQueue.shift();

    if (job.status) {
      switch (job.status) {
        case PENDING:
          await pending(store, job);
          break;
        case STARTED:
          await started(store, job);
          break;
        case PROCESSING:
          await processing(store, job);
          break;
        case FAILED:
          await failed(store, job);
          break;
        case FINISHED:
          await finished(store, job);
          break;
      }
    }
    resolve();
  }

  isProcessingQueue = false;
}

const socketMiddleware = () => {
  let pusher = null;
  let jobsChannel = null;

  return store => next => action => {
    switch (action.type) {
      case 'SOCKETS_CONNECTING':
        const api_token = localStorage.getItem(LocalStorageHelper.API_TOKEN);
        const headers = { 'Authorization': api_token };

        if (!pusher) {
          pusher = new Pusher('d80a938aded21b41c19d', {
            cluster: 'eu',
            channelAuthorization: {
              endpoint: process.env.REACT_APP_API_URL + "/broadcasting/auth",
              transport: "ajax",
              headers: headers,
              customHandler: null,
            },
          });

          jobsChannel = pusher.subscribe('private-jobs-' + process.env.REACT_APP_ENV + '-' + action.payload.role + '-' + action.payload.userId);
          jobsChannel.bind('jobs', (job) => {
            enqueueEvent(store, job);
          });
        }

        break;

      case 'SOCKETS_DISCONNECTING':
        if (pusher) {
          if (jobsChannel) {
            jobsChannel.unbind_all();
            jobsChannel.unsubscribe();
            jobsChannel = null;
          }
          pusher.disconnect();
          pusher = null;
        }
        break;

      default:
        return next(action);
    }
  };
};

export default socketMiddleware();
