import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {ButtonRow, Row} from '../../styles'
import {Block, Container} from './styles'
import TradeCommissionRow from './components/TradeCommissionRow/TradeCommissionRow'
import {BodyBold} from "#app/components/Text/styles";
import {Api} from "laravel-request";
import {setTradeCommissionForm, setTradeCommissionModalVisible, setTradeId} from "#app/actions/tradeCommission";

const TradeCommissions = ({
                              tradeId,
                              title,
                              client,

                              tradeCommissionModalVisible,
                              setTradeCommissionModalVisible,
                              setTradeCommissionForm,
                              setTradeId
                          }) => {

    if (!client) {
        return null;
    }

    if(!tradeId)
    {
        return null;
    }

    const [items, setItems] = useState([]);

    const getItems = (tradeId) => {
        Api.get('active-trade-commission', 'index', {user_id: client.id})
            .where('active_trade_id', tradeId)
            .with('currency')
            .all(({data}) => {
                setItems(data)
            });
    }

    useEffect(() => {
        //пока просто решение грузим на закрытие модального окна
        getItems(tradeId)
    }, [tradeId, tradeCommissionModalVisible])


    const handleClick = (id) => {
        Api.get('active-trade-commission', 'index', {user_id: client.id})
            .where('id', id)
            .with('currency')
            .first(({data}) => {
                setTradeCommissionModalVisible(true)
                setTradeCommissionForm(data)
                setTradeId(tradeId)
            });
    }

    return <Block>
        <BodyBold style={{padding: '5px 0px 5px 10px'}} left>{title ?? 'Коммисии'}</BodyBold>
        <Container>
            {items?.map((item) => {
                return <TradeCommissionRow
                    key={item.id}
                    item={item}
                    onClick={handleClick}
                />
            })}
        </Container>
        <ButtonRow flex>
            <Row style={{margin: '10px 0 10px 10px'}}>
                <img onClick={() => {
                    setTradeCommissionModalVisible(true)
                    setTradeId(tradeId)
                }} style={{marginRight: '15px', width: '24px', cursor: 'pointer'}}
                     src={require('./../../../assets/icons/ic_add.svg').default} alt=''/>
                <BodyBold id='create-active-trade-commission' onClick={() => {
                    setTradeCommissionModalVisible(true)
                    setTradeId(tradeId)
                }} style={{color: '#4378FF', cursor: 'pointer'}}>Добавить комиссию</BodyBold>
            </Row>
        </ButtonRow>
    </Block>
}

const enhance = connect(
    (state) => ({
        i18n: state.i18n,
        client: state.interfaceComponents.client,

        tradeCommissionModalVisible: state.tradeCommission.modalVisible,
        tradeCommissionForm: state.tradeCommission.form,
    }),
    {
        setTradeCommissionModalVisible,
        setTradeCommissionForm,
        setTradeId,
    }
)

export default enhance(TradeCommissions);
