import {useEffect, useState} from 'react';
import moment from 'moment';
import {Active, ActiveConstants, Money, TradeConstants} from "finhelper";
import DateConstants from "#app/helpers/DateConstants";
import {useSumPriceCount} from "#app/pages/Relations/Trades/hooks/useSumPriceCount";

export const useTradeForm = (
    form: any,
    client: any,
    setModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
    setForm: React.Dispatch<React.SetStateAction<any>>,
    lotSize: number|string,
    setSearch: (search: string) => void,
    setSearchResults: (options: any) => void,
) => {
  const [id, setId] = useState(null);
  const [catalogItem, setCatalogItem] = useState(null);
  const [userId, setUserId] = useState(client ? client.id : '');
  const [typeId, setTypeId] = useState(TradeConstants.BUY);
  const [currencyId, setCurrencyId] = useState('');
  const [fromAccountId, setFromAccountId] = useState('');
  const [getAccountId, setGetAccountId] = useState('');
  const [toAccountId, setToAccountId] = useState('');
  const [tradeAt, setTradeAt] = useState<Date|null>(null);
  const [placeId, setPlaceId] = useState('');
  const [activeId, setActiveId] = useState('');

  const [formErrors, changeFormErrors] = useState<any>({});

  // 3) Хук для sum / price / count
  const sumPriceCount = useSumPriceCount(lotSize);

  useEffect(() => {
    if (form) {
      setId(form.id || null);
      setTypeId(form.type_id || TradeConstants.BUY);
      setCurrencyId(form.currency_id || null);
      setFromAccountId(form.from_account_id || null);
      setGetAccountId(form.get_account_id || null);
      setToAccountId(form.to_account_id || null);
      setPlaceId(form.place_id || null);

      const tradeAt = moment(form.trade_at_datetime, DateConstants.dateTimeUserFormat);
      setTradeAt(tradeAt.isValid() ? tradeAt.toDate() : null);

      if(form?.active)
      {
        setActiveId(form?.active.id || null);

        const activeName = Active.getName(form.active);
        const activeTypeId = form.active.type_id;
        const activeGroupName = ActiveConstants.getTypeById(activeTypeId)?.name;
        // @ts-ignore
        setSearch(activeName)

        if(form?.active?.item)
        {
          //перед сохранением проверим что есть type_id в form.active.item
          //TODO это не совсем верно, нужно будет на беке сразу возвращать а не тут
          //TODO не совпадает интерфейс поиска и итема
          form.active.item.type_id = form?.active.type_id;

          setCatalogItem(form.active.item);

          setSearchResults([
              {id: activeTypeId, name: activeGroupName, items: [
                  {
                    ...form.active.item,
                    id: form.active.item.id,
                    name: activeName
                  }
              ]}
          ]);
        }
      }
      // @ts-ignore
      sumPriceCount.setSum(form.sum ? Money.format(form.sum) : "");
      // @ts-ignore
      sumPriceCount.setPrice(form.price ? Money.format(form.price) : "");
      // @ts-ignore
      sumPriceCount.setCount(form.count ? Money.format(form.count) : "");
      // @ts-ignore
      sumPriceCount.setAccumulatedCoupon(form.accumulated_coupon ? Money.format(form.accumulated_coupon) : "");

      // Поскольку поля заполнены, можно включить флаги «изменено»
      sumPriceCount.setIsSumChanged(false);
      sumPriceCount.setIsPriceChanged(false);
      sumPriceCount.setIsCountChanged(false);

      // Сбрасываем блокировки
      sumPriceCount.setIsSumBlocked(false);
      sumPriceCount.setIsPriceBlocked(false);
      sumPriceCount.setIsCountBlocked(false);

    }
  }, [form]);

  // Функция для очистки формы
  const clearForm = () => {
    setUserId('');
    setTypeId(TradeConstants.BUY);
    setCurrencyId('');
    setFromAccountId('');
    setGetAccountId('');
    setToAccountId('');
    setTradeAt(null);
    setPlaceId('');
    setActiveId('');
    setCatalogItem(null)

    // Сбросить логику sum/price/count
    sumPriceCount.resetSumPriceCount();
  };

  const closeForm = () => {
    clearForm();
    changeFormErrors({});
    setModalVisible(false);
    setForm(null);
  };

  const hasError = (fieldName: string) => {
    if (formErrors && formErrors[fieldName] && formErrors[fieldName].length > 0) {
      return true;
    }
    return false;
  };

  const getError = (fieldName: string) => {
    if (formErrors && typeof formErrors[fieldName] !== 'undefined' && formErrors[fieldName].length > 0) {
      return formErrors[fieldName][0];
    }
    return '';
  };


  return {
    id, setId,
    catalogItem, setCatalogItem,
    userId, setUserId,
    typeId, setTypeId,
    currencyId, setCurrencyId,
    fromAccountId, setFromAccountId,
    getAccountId, setGetAccountId,
    toAccountId, setToAccountId,
    tradeAt, setTradeAt,
    placeId, setPlaceId,
    activeId, setActiveId,

    // Далее возвращаем всё, что касается sum/price/count
    // (либо мы можем возвращать весь объект sumPriceCount целиком, либо выборочно)
    sum: sumPriceCount.sum,
    price: sumPriceCount.price,
    count: sumPriceCount.count,
    accumulatedCoupon: sumPriceCount.accumulatedCoupon,

    handleSumChange: sumPriceCount.handleSumChange,
    handlePriceChange: sumPriceCount.handlePriceChange,
    handleCountChange: sumPriceCount.handleCountChange,
    handleAccumulatedCoupon: sumPriceCount.handleAccumulatedCoupon,

    // Если нужны флаги/блокировки снаружи:
    isSumBlocked: sumPriceCount.isSumBlocked,
    isPriceBlocked: sumPriceCount.isPriceBlocked,
    isCountBlocked: sumPriceCount.isCountBlocked,

    formErrors, changeFormErrors,
    hasError, getError,
    closeForm, clearForm
  };
};