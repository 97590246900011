import {Api} from "laravel-request";
import moment from 'moment';

const useTradeActions = (
    id: any,
    setId: (id: any) => void,
    userId: string,
    typeId: string | number | undefined,
    currencyId: string,
    setCurrencyId: (id: any) => void,
    fromAccountId: string,
    getAccountId: string,
    toAccountId: string,
    price: string,
    sum: string,
    count: string,
    tradeAt: Date | null,
    placeId: string,
    activeId: string,
    setActiveId: (id: any) => void,

    catalogItem: any,
    closeForm: () => void,
    callback: (data: any) => void,
    changeFormErrors: (errors: any) => void,
    loading: boolean,
    setLoading: (loading: boolean) => void
) => {

    const handleDelete = () => {
        setLoading(true);
        Api.delete('active-trade', 'destroy', id)
            .call(
                () => {
                    setLoading(false);
                    closeForm();
                    callback(null);
                },
                (xhr: any, responseData: any) => {
                    if (responseData?.meta?.errors) {
                        changeFormErrors(responseData?.meta?.errors);
                    }
                    setLoading(false);
                }
            );
    };

    const handleUpdate = () => {
        setLoading(true);

        const data = {
            id: id,
            user_id: userId,
            type_id: typeId,
            currency_id: currencyId,
            from_account_id: fromAccountId,
            get_account_id: getAccountId,
            to_account_id: toAccountId,
            price: price,
            sum: sum,
            count: count,
            trade_at: tradeAt ? moment(tradeAt).format('YYYY-MM-DD HH:mm:ss') : null,
            place_id: placeId,
            active_id: activeId ?? null,

            item_id: catalogItem?.id,
            item_type: catalogItem?.type_id,
            item: catalogItem ? {
                id: catalogItem.id + '-' + catalogItem?.type_id + '-' + catalogItem.ticker,
                ticker: catalogItem.ticker,
                type_id: catalogItem?.type_id,
                lotsize: catalogItem.lotsize
            } : {},
            active: {
                item: {
                    lotsize: catalogItem.lotsize
                }
            }
        };

        Api.putArg('active-trade', 'update', id, data)
            .call(
                (response: any) => {
                    callback(response);
                    changeFormErrors({});
                    setLoading(false);
                },
                (xhr: any, responseData: any) => {
                    if (responseData?.meta?.errors) {
                        changeFormErrors(responseData?.meta?.errors);
                    }
                    setLoading(false);
                }
            );
    };

    const handleCreate = () => {
        setLoading(true);

        const data = {
            user_id: userId,
            type_id: typeId,
            currency_id: currencyId,
            from_account_id: fromAccountId,
            get_account_id: getAccountId,
            to_account_id: toAccountId,
            price: price,
            sum: sum,
            count: count,
            trade_at: tradeAt ? moment(tradeAt).format('YYYY-MM-DD HH:mm:ss') : null,
            place_id: placeId,

            item_id: catalogItem?.id,
            item_type: catalogItem?.type_id,
            item: catalogItem ? {
                id: catalogItem.id + '-' + catalogItem?.type_id + '-' + catalogItem.ticker,
                ticker: catalogItem.ticker,
                type_id: catalogItem?.type_id,
                lotsize: catalogItem.lotsize
            } : {},
            active: {
                item: {
                    lotsize: catalogItem.lotsize
                }
            }
        };


        Api.post('active-trade', 'store', data)
            .call(
                (response: any) => {
                    // closeForm();
                    setId(response.meta.id);
                    setCurrencyId(response.data.currency_id);

                    if(response?.data?.active?.id)
                    {
                        setActiveId(response?.data?.active?.id)
                    }

                    callback(response);
                    changeFormErrors({});
                    setLoading(false);
                },
                (xhr: any, responseData: any) => {
                    if (responseData?.meta?.errors) {
                        changeFormErrors(responseData?.meta?.errors);
                    }
                    setLoading(false);
                }
            );
    };

    return { loading, handleDelete, handleUpdate, handleCreate };
};

export default useTradeActions;