import React, {Suspense} from 'react';
import {getUserAccounts, setAccountForm, setAccountModalVisible, setAvailableTypes} from "#app/actions/account";
import {
    AuthLayout,
    Browser,
    CallbackNotification,
    DemoSidebar,
    HeaderCabinet,
    Notification,
    Scenario,
    SidebarCabinet
} from './LazyLoadedPages';
import InvestsProvider from "#app/contexts/InvestsProvider";
import TradeForm from "#app/pages/Relations/Trades/form/TradeForm";
import {connect} from "react-redux";
import {setTradeForm, setTradeModalVisible} from "#app/actions/trade";
import AccountForm from "#app/pages/Relations/Accounts/form/AccountForm";
import {AccountConstants} from "finhelper";
import TradeCommissionForm from "#app/pages/Relations/TradeCommissions/form/TradeCommissionForm";
import {setTradeCommissionForm, setTradeCommissionModalVisible, setTradeId} from "#app/actions/tradeCommission";
import TradePaymentForm from "#app/pages/Relations/TradePayments/form/TradePaymentForm";
import {setPaymentForm, setPaymentModalVisible} from "#app/actions/payment";

const CabinetLayout = ({
                           isLoaded,
                           client,
                           demo,
                           scenarioName,

                           tradeModalVisible,
                           setTradeModalVisible,
                           tradeForm,
                           setTradeForm,

                           paymentModalVisible,
                           setPaymentModalVisible,
                           paymentForm,
                           setPaymentForm,

                           tradeId,
                           setTradeId,
                           tradeCommissionModalVisible,
                           setTradeCommissionModalVisible,
                           tradeCommissionForm,
                           setTradeCommissionForm,

                           accountModalVisible,
                           accountForm,
                           getUserAccounts,
                           setAccountModalVisible,
                           setAccountForm,
                           setAvailableTypes
}) => (
    <Suspense>
        <HeaderCabinet/>
        {isLoaded && <InvestsProvider>
            {!!client && demo && scenarioName === 'demo' ? <DemoSidebar/> : <SidebarCabinet/>}
        </InvestsProvider>}
        <AuthLayout/>
        <Scenario/>
        <Browser/>
        <Notification/>
        <CallbackNotification/>

        <TradeForm
            setModalVisible={setTradeModalVisible}
            modalVisible={tradeModalVisible}
            form={tradeForm}
            setForm={setTradeForm}
            callback={() => {
            }}
        />
        <TradeCommissionForm
            setModalVisible={setTradeCommissionModalVisible}
            modalVisible={tradeCommissionModalVisible}
            form={tradeCommissionForm}
            setForm={setTradeCommissionForm}
            callback={() => {
                setTradeId(null)
            }}
            tradeId={tradeId}
        />
        <AccountForm
            setModalVisible={setAccountModalVisible}
            modalVisible={accountModalVisible}
            form={accountForm}
            setForm={setAccountForm}
            callback={() => {
                getUserAccounts();
                //вернем все типы для выбора
                setAvailableTypes(AccountConstants.types().map(type => type.id))
            }}
        />
        <TradePaymentForm
            setModalVisible={setPaymentModalVisible}
            modalVisible={paymentModalVisible}
            form={paymentForm}
            setForm={setPaymentForm}
            callback={() => {

            }}
        />
    </Suspense>
);

const enhance = connect(
    (state) => ({
        tradeCommissionModalVisible: state.tradeCommission.modalVisible,
        tradeCommissionForm: state.tradeCommission.form,
        tradeId: state.tradeCommission.tradeId,

        tradeModalVisible: state.trade.modalVisible,
        tradeForm: state.trade.form,

        paymentModalVisible: state.payment.modalVisible,
        paymentForm: state.payment.form,

        accountModalVisible: state.account.modalVisible,
        accountForm: state.account.form,

    }),
    {
        setTradeModalVisible,
        setTradeForm,

        setPaymentModalVisible,
        setPaymentForm,

        setTradeCommissionModalVisible,
        setTradeCommissionForm,
        setTradeId,

        setAccountModalVisible,
        setAccountForm,
        getUserAccounts,
        setAvailableTypes
    }
)

export default enhance(CabinetLayout);