import {css} from 'styled-components'


export const scrollVerticalSharedStyle = css`
  ::-webkit-scrollbar {
    width: 4px;
  }
  
  ::-webkit-scrollbar-track {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #F5F5F5;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #D2D1D1;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #D2D1D1;
  }
`