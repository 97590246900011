import {useCallback, useEffect, useRef, useState} from "react";
import {calcCount, calcPrice, calcSum} from "#app/pages/Relations/Trades/form/utils/calculate";

/**
 *
 * @param lotSize
 * @returns {{sum: string, price: string, count: string, accumulatedCoupon: string, setSum: (value: (((prevState: string) => string) | string)) => void, setPrice: (value: (((prevState: string) => string) | string)) => void, setCount: (value: (((prevState: string) => string) | string)) => void, setAccumulatedCoupon: (value: (((prevState: string) => string) | string)) => void, isSumBlocked: boolean, isPriceBlocked: boolean, isCountBlocked: boolean, setIsSumBlocked: (value: (((prevState: boolean) => boolean) | boolean)) => void, setIsPriceBlocked: (value: (((prevState: boolean) => boolean) | boolean)) => void, setIsCountBlocked: (value: (((prevState: boolean) => boolean) | boolean)) => void, handleSumChange: ((function(*): void)|*), handlePriceChange: ((function(*): void)|*), handleCountChange: ((function(*): void)|*), handleAccumulatedCoupon: ((function(*): void)|*), resetSumPriceCount: ((function(): void)|*), isSumChanged: boolean, isPriceChanged: boolean, isCountChanged: boolean, setIsSumChanged: (value: (((prevState: boolean) => boolean) | boolean)) => void, setIsPriceChanged: (value: (((prevState: boolean) => boolean) | boolean)) => void, setIsCountChanged: (value: (((prevState: boolean) => boolean) | boolean)) => void}}
 */
export function useSumPriceCount(lotSize)
{
    const [sum, setSum] = useState("");
    const [price, setPrice] = useState("");
    const [count, setCount] = useState("");
    const [accumulatedCoupon, setAccumulatedCoupon] = useState("");

    const [isSumBlocked, setIsSumBlocked] = useState(false);
    const [isPriceBlocked, setIsPriceBlocked] = useState(false);
    const [isCountBlocked, setIsCountBlocked] = useState(false);

    const [isSumChanged, setIsSumChanged] = useState(false);
    const [isPriceChanged, setIsPriceChanged] = useState(false);
    const [isCountChanged, setIsCountChanged] = useState(false);

    const sumRef = useRef(sum);
    const countRef = useRef(count);
    const priceRef = useRef(price);
    const lotSizeRef = useRef(lotSize);

    const sumChangedRef = useRef(isSumChanged);
    const countChangedRef = useRef(isCountChanged);
    const priceChangedRef = useRef(isPriceChanged);

    useEffect(() => {
        lotSizeRef.current = lotSize;
    }, [lotSize]);

    useEffect(() => {
        sumRef.current = sum;
    }, [sum]);

    useEffect(() => {
        countRef.current = count;
    }, [count]);

    useEffect(() => {
        priceRef.current = price;
    }, [price]);

    useEffect(() => {
        sumChangedRef.current = isSumChanged;
    }, [isSumChanged]);

    useEffect(() => {
        countChangedRef.current = isCountChanged;
    }, [isCountChanged]);

    useEffect(() => {
        priceChangedRef.current = isPriceChanged;
    }, [isPriceChanged]);

    // Проверка, является ли значение "пустым" (пустая строка, "0" или "0.0")
    const isEmptyValue = (value) => {
        return value === "" || value === "0" || value === "0.0" || null || undefined;
    };


    // Обработчик изменения суммы
    const handleSumChange = useCallback((newValue) => {
        setSum(newValue);
        setIsSumChanged(!isEmptyValue(newValue));

        if(!isEmptyValue(newValue))
        {
            const priceChanged = priceChangedRef.current;
            const countChanged = countChangedRef.current;

            if (priceChanged && countChanged) {
                return;
            }

            if (priceChanged) {
                const calculatedCount = calcCount(newValue, priceRef.current, lotSizeRef.current);
                setCount(calculatedCount);
                setIsCountBlocked(true);
                return;
            } else if (countChanged) {
                const calculatedPrice = calcPrice(newValue, countRef.current, lotSizeRef.current);
                setPrice(calculatedPrice);
                setIsPriceBlocked(true);
                return;
            }
        }

        setIsPriceBlocked(false);
        setIsCountBlocked(false);
    }, []);

    // Обработчик изменения цены
    const handlePriceChange = useCallback((newValue) => {
        setPrice(newValue);
        setIsPriceChanged(!isEmptyValue(newValue));

        if(!isEmptyValue(newValue))
        {
            const sumChanged = sumChangedRef.current;
            const countChanged = countChangedRef.current;

            if (sumChanged && countChanged) {
                return;
            }

            if (sumChanged) {
                const calculatedCount = calcCount(sumRef.current, newValue, lotSizeRef.current);
                setCount(calculatedCount);
                setIsCountBlocked(true);
                return;
            } else if (countChanged) {
                const calculatedSum = calcSum(countRef.current, newValue, lotSizeRef.current);
                setSum(calculatedSum);
                setIsSumBlocked(true);
                return;
            }
        }

        setIsSumBlocked(false);
        setIsCountBlocked(false);
    }, []);

    // Обработчик изменения количества
    const handleCountChange = useCallback((newValue) => {
        setCount(newValue);
        setIsCountChanged(!isEmptyValue(newValue));

        if(!isEmptyValue(newValue)){
            const sumChanged = sumChangedRef.current;
            const priceChanged = priceChangedRef.current;

            if (sumChanged && priceChanged) {
                return;
            }

            if (sumChanged) {
                const calculatedPrice = calcPrice(sumRef.current, newValue, lotSizeRef.current);
                setPrice(calculatedPrice);
                setIsPriceBlocked(true);
                return;
            } else if (priceChanged) {
                const calculatedSum = calcSum(priceRef.current, newValue, lotSizeRef.current);
                setSum(calculatedSum);
                setIsSumBlocked(true);
                return;
            }
        }

        setIsSumBlocked(false);
        setIsPriceBlocked(false);
    }, []);

    // Обработчик изменения накопительного купона
    const handleAccumulatedCoupon = useCallback((newValue) => {
        setAccumulatedCoupon(newValue);
    }, []);

    // Сброс всех полей
    const resetSumPriceCount = useCallback(() => {
        setSum("");
        setPrice("");
        setCount("");
        setAccumulatedCoupon("");

        setIsSumChanged(false);
        setIsPriceChanged(false);
        setIsCountChanged(false);

        setIsSumBlocked(false);
        setIsPriceBlocked(false);
        setIsCountBlocked(false);
    }, []);

    return {
        sum,
        price,
        count,
        accumulatedCoupon,

        setSum,
        setPrice,
        setCount,
        setAccumulatedCoupon,

        isSumBlocked,
        isPriceBlocked,
        isCountBlocked,

        setIsSumBlocked,
        setIsPriceBlocked,
        setIsCountBlocked,

        handleSumChange,
        handlePriceChange,
        handleCountChange,
        handleAccumulatedCoupon,
        resetSumPriceCount,

        isSumChanged,
        isPriceChanged,
        isCountChanged,

        setIsSumChanged,
        setIsPriceChanged,
        setIsCountChanged,
    };
}
