//функция для группировки счетов
import {AccountConstants, CurrencyConstants, Money} from "finhelper";

export const getAccounts = (passAccounts) =>
{
    let accounts = [];

    passAccounts?.map((account, key) => {
        accounts[key] = {...account};
        accounts[key].name = AccountConstants.textByType(account)
        accounts[key].items = [];

        account.accounts.map((item) =>
        {
            let sign = CurrencyConstants.getCurrencySignById(item.currency_id)
            let subAccountName = (item.name ? item.name : 'Счёт без названия');

            accounts[key].items.push({
                ...item,
                name: subAccountName + ' - (' + Money.format(item.sum) + ' ' + sign + ')'
            });
        });
    });

    return accounts;
}