import {useEffect, useState} from 'react';
import moment from 'moment';
import {TradeCommissionConstants} from "finhelper";
import DateConstants from "#app/helpers/DateConstants";

export const useTradeCommissionForm = (
    form: any,
    client: any,
    setModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
    setForm: React.Dispatch<React.SetStateAction<any>>,
) => {
  const [id, setId] = useState(null);
  const [userId, setUserId] = useState(client ? client.id : '');
  const [accountId, setAccountId] = useState('');
  const [paidAt, setPaidAt] = useState<Date|null>(null);
  const [typeId, setTypeId] = useState(TradeCommissionConstants.FIX);
  const [reasonId, setReasonId] = useState('');
  const [sum, setSum] = useState('');
  const [percent, setPercent] = useState('');


  const [formErrors, changeFormErrors] = useState<any>({});


  useEffect(() => {
    if (form) {
      setId(form.id || null);
      setAccountId(form.account_id || '');

      const paidAt = moment(form.paid_at_datetime, DateConstants.dateTimeUserFormat);
      setPaidAt(paidAt.isValid() ? paidAt.toDate() : null);

      setTypeId(form.type_id || TradeCommissionConstants.FIX);
      setReasonId(form.reason_id || '');
      setSum(form.sum || '');
      setPercent(form.percent || '');
    }
  }, [form]);

  // Функция для очистки формы
  const clearForm = () => {
    setId(null);
    setUserId(client ? client.id : '');
    setAccountId('');
    setPaidAt(null);
    setTypeId(TradeCommissionConstants.FIX);
    setReasonId('');
    setSum('');
    setPercent('');
  };

  const closeForm = () => {
    clearForm();
    changeFormErrors({});
    setModalVisible(false);
    setForm(null);
  };

  const hasError = (fieldName: string) => {
    if (formErrors && formErrors[fieldName] && formErrors[fieldName].length > 0) {
      return true;
    }
    return false;
  };

  const getError = (fieldName: string) => {
    if (formErrors && typeof formErrors[fieldName] !== 'undefined' && formErrors[fieldName].length > 0) {
      return formErrors[fieldName][0];
    }
    return '';
  };


  return {
    id, setId,
    userId, setUserId,
    accountId, setAccountId,
    paidAt, setPaidAt,
    typeId, setTypeId,
    reasonId, setReasonId,
    sum, setSum,
    percent, setPercent,

    formErrors, changeFormErrors,
    hasError, getError,
    closeForm, clearForm
  };
};