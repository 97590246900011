import {Api} from "laravel-request";
import {CurrencyConstants, Tracker} from "finhelper";
import AccountsData from "#app/pages/Accounting/Accounts/data/accounts";
import {NotifyManager} from "notify-component";

const useAccountDemoActions = (
        id: any,
        setId: (id: any) => void,
        userId: any,
        typeId: any,
        setTypeId: () => void,
        name: any,
        setName: () => void,
        bankId: any,
        setBankId: (bankId: any) => void,
        customBankId: any,
        setCustomBankId: (bankId: any) => void,
        subaccounts: any,
        setSubaccounts: () => void,
        searchBank: any,
        closeForm: () => void,
        callback: (data: any) => void,
        changeFormErrors: (errors: any) => void,
        loading: boolean,
        setLoading: (loading: boolean) => void
    ) => {

        const addSubAccount = () => {
            let rubCurrency = CurrencyConstants.getCurrencyById(CurrencyConstants.RUBBLE_ID);

            // Создаем новый подаккаунт
            const newSubaccount = {
                account_id: id,
                currency: rubCurrency, // Берем первую валюту из списка
                //@ts-ignore
                currency_id: rubCurrency.id, // ID первой валюты
                sum: 0, // Начальная сумма
            };

            // Обновляем список подаккаунтов через setSubaccounts
            //@ts-ignore
            setSubaccounts((prevSubaccounts: any) => [...prevSubaccounts, newSubaccount]);
        };

        // Delete SubAccount Logic
        const deleteSubAccount = (key: number) => {
            //@ts-ignore
            setSubaccounts((prevSubaccounts) => {
                // Создаем новый массив, исключая элемент с индексом key
                //@ts-ignore
                const updatedSubaccounts = prevSubaccounts.filter((_, index) => index !== key);
                return updatedSubaccounts;
            });
        };


        const handleBankStore = () => {
            return new Promise<{ bankId: number | null, customBankId: number | null }>((resolve, reject) => {
                    resolve({bankId: bankId, customBankId: customBankId}); // Возвращаем текущие значения
            });
        };


        const handleDelete = () => {
            setLoading(true);

            let foundKey = AccountsData.data.findIndex((account) => account.id === id);

            if(foundKey !== -1)
            {
                AccountsData.data.splice(foundKey, 1);
            }

            closeForm()

            NotifyManager.info('Успешно', 'Счёт удален')
        };

        const handleRestore = () => {
            setLoading(true);


        };

        const handleUpdate = async () => {
            setLoading(true);

            const { bankId: updatedBankId, customBankId: updatedCustomBankId } = await handleBankStore();

            const data = {
                id: id,
                bank_id: updatedBankId,
                custom_bank_id: updatedCustomBankId,
                type_id: typeId,
                name: name,
                accounts: subaccounts
            };

            Api.putArg('user-account', 'validate-update', id, data)
                .call((response) => {
                    const accountIndex = AccountsData.data.findIndex(account => account.id === id);

                    if (accountIndex !== -1) {
                        // Создаем новый объект аккаунта с обновленными данными
                        // @ts-ignore
                        AccountsData.data[accountIndex] = data;
                    }

                    NotifyManager.info('Успешно', 'Счёт создан')

                    setLoading(false);
                }, (xhr: any, responseData: any) => {
                    if (responseData?.meta?.errors) {
                        changeFormErrors(responseData?.meta?.errors);
                    }
                    setLoading(false);
                });
        };

        const handleCreate = async () => {
            setLoading(true);

            const { bankId: updatedBankId, customBankId: updatedCustomBankId } = await handleBankStore();

            const data = {
                user_id: userId,
                bank_id: updatedBankId,
                custom_bank_id: updatedCustomBankId,
                type_id: typeId,
                name: name,
                accounts: subaccounts
            };

            Api.post('user-account', 'validate-store', data)
                .call((response) => {
                    let newData = {...data}

                    //@ts-ignore
                    newData.id = AccountsData.lastId;
                    AccountsData.lastId++;

                    //@ts-ignore
                    AccountsData.data.push(data);

                    Tracker.reachGoal('account_created', {
                        //user_id: this.props.client ? this.props.client.id : null
                    });


                    NotifyManager.info('Успешно', 'Счёт отредактирован')

                    callback(data);

                    setLoading(false);
                }, (xhr: any, responseData: any) => {
                    if (responseData?.meta?.errors) {
                        changeFormErrors(responseData?.meta?.errors);
                    }
                    setLoading(false);
                });

        };

        return {
            loading,
            handleDelete,
            handleRestore,
            handleUpdate,
            handleCreate,
            addSubAccount,
            deleteSubAccount,
            handleBankStore
        };
    }
;

export default useAccountDemoActions;