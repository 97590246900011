
export default class LocalStorageHelper
{
  static API_TOKEN = 'api_token'
  static AUTH_EMAIL = 'auth_email'
  static ACCOUNTS_EXTENDED = 'accounts_extended'
  static LANGUAGE = 'language_id'
  static INVEST_CURRENCY = 'invests_currency'
  static INVEST_GROUP_TYPE = 'invests_group_type'

  static TRADE_FORM_MODE_FOR_PRICE = 'trade_form_mode_for_price'

  static NOTIFICATION_PER_PAGE = 'notifications_per_page'

  static SALARY_PLAN_ZOOM_START = 'salary_plan_zoomed_start'
  static SALARY_PLAN_ZOOM_END = 'salary_plan_zoomed_end'
  static SALARY_PLAN_TYPE = 'salary_plan_type'
  static SALARY_PLAN_TAB = 'salary_plan_tab'
  static SALARY_PLAN_IS_SHORT = 'salary_plan_is_short'

  static CASE_GROUP_TYPE = 'сase_group_type'

  static generateKey = (pattern, params) => {
    Object.keys(params).forEach(key => {
      // Replace all occurrences of {param} in the pattern with corresponding values
      pattern = pattern.replace(new RegExp(`{${key}}`, 'g'), params[key]);
    });

    return pattern;
  };

  static startAgain()
  {



  }

}