import React from 'react';
import {Row} from './styles'
import {CurrencyConstants, Money, TradeCommissionConstants} from "finhelper"

const TradeCommissionRow = ({
                              item,
                              onClick
                            }) => {

  //если даты нет, то берем из трейда
  const getValue = (item) => {
    switch (item.type_id) {
      case TradeCommissionConstants.FIX:
        return Money.format(item.sum) + ' ' + CurrencyConstants.getCurrencySignById(item.currency_id);
      case TradeCommissionConstants.PERCENT:
        return item.percent + '%';
    }
  }

  const getDate = (item) =>
  {
    if(item.paid_at)
    {
      return item.paid_at
    }
  }

  return <Row id={'trade-commission-' + item.id} onClick={() => {
    onClick(item)
  }}>
    <div>{item.type_text}</div>
    <div>{item.reason_text}</div>
    <div>{getDate(item)}</div>
    <div>{getValue(item)}</div>
  </Row>;
}

export default TradeCommissionRow;
