import {createAction} from 'redux-act'

export const setAccountModalVisible = createAction('SET_ACCOUNT_MODAL_VISIBLE')
export const setAccountForm = createAction('SET_ACCOUNT_FORM')
export const setAvailableTypes = createAction('SET_AVAILABLE_TYPES')
export const getUserAccounts = createAction('GET_USER_ACCOUNTS')
export const handleUserAccounts = createAction('HANDLE_USER_ACCOUNTS')



