import {Api} from "laravel-request";
import moment from 'moment';

const useTradeCommissionActions = (
    id: any,
    setId: (id: any) => void,
    tradeId: any,
    userId: any,
    accountId: any,
    paidAt: any,
    typeId: any,
    reasonId: any,
    sum: any,
    percent: any,

    closeForm: () => void,
    callback: (data: any) => void,
    changeFormErrors: (errors: any) => void,
    loading: boolean,
    setLoading: (loading: boolean) => void
) => {

    const handleDelete = () => {
        setLoading(true);
        Api.delete('active-trade-commission', 'destroy', id)
            .call(
                () => {
                    setLoading(false);
                    closeForm();
                    callback(null);
                },
                (xhr: any, responseData: any) => {
                    if (responseData?.meta?.errors) {
                        changeFormErrors(responseData?.meta?.errors);
                    }
                    setLoading(false);
                }
            );
    };

    const handleUpdate = () => {
        setLoading(true);
        const data = {
            id: id,
            active_trade_id: tradeId,
            type_id: typeId,
            reason_id: reasonId,
            account_id: accountId,
            paid_at: paidAt ? moment(paidAt).format('YYYY-MM-DD HH:mm:ss') : null,
            sum: sum,
            percent: percent,
        };

        Api.putArg('active-trade-commission', 'update', id, data)
            .call(
                (response: any) => {
                    closeForm();
                    callback(response);
                    changeFormErrors({});
                    setLoading(false);
                },
                (xhr: any, responseData: any) => {
                    if (responseData?.meta?.errors) {
                        changeFormErrors(responseData?.meta?.errors);
                    }
                    setLoading(false);
                }
            );
    };

    const handleCreate = () => {
        setLoading(true);

        const data = {
            active_trade_id: tradeId,
            type_id: typeId,
            reason_id: reasonId,
            account_id: accountId,
            paid_at: paidAt ? moment(paidAt).format('YYYY-MM-DD HH:mm:ss') : null,
            sum: sum,
            percent: percent,
            user_id: userId
        };


        Api.post('active-trade-commission', 'store', data)
            .call(
                (response: any) => {
                    closeForm();
                    setId(response.meta.id);
                    callback(response);
                    changeFormErrors({});
                    setLoading(false);
                },
                (xhr: any, responseData: any) => {
                    if (responseData?.meta?.errors) {
                        changeFormErrors(responseData?.meta?.errors);
                    }
                    setLoading(false);
                }
            );
    };

    return { loading, handleDelete, handleUpdate, handleCreate };
};

export default useTradeCommissionActions;