import React, {Fragment, useEffect, useState} from "react";
import ModalForm from "#app/components/ModalForm/ModalForm";
import {connect} from "react-redux";
import {Row} from "#app/pages/styles";
import {Button, DateTimeInput, GroupSelect, InputContainer, NumberInput, Search, Select} from "finform";
import DeleteButton from "#app/pages/Relations/components/DeleteButton";
import {Account, AccountConstants, TradeCommissionConstants} from "finhelper";
import {useTradeCommissionForm} from "#app/pages/Relations/TradeCommissions/hooks/useTradeCommissionForm";
import useTradeCommissionActions from "#app/pages/Relations/TradeCommissions/hooks/useTradeCommissionActions";
import {Api} from "laravel-request";
import {getAccounts} from "#app/pages/Relations/Trades/form/utils/accounts";
import SelectAccountWrapper from "#app/pages/Relations/components/SelectAccountWrapper";

// @ts-ignore
const TradeCommissionForm = ({
                       modalVisible,
                       setModalVisible,
                       form,
                       setForm,
                       callback,
                       client,

                       tradeId,
                       accounts = [],//from redux
}: any) => {

    if (!modalVisible) {
        return null;
    }

    if (!client) {
        return null;
    }

    const [types, setTypes] = useState([]);
    const [reasons, setReasons] = useState([]);
    const [loading, setLoading] = useState(false);

    const [searchReason, setSearchReason] = useState('');

    useEffect(() => {
        Api.get('active-trade-commission', 'types').call((response: any) => {
            setTypes(response.data)
        });
    }, []);

    useEffect(() => {
        Api.get('active-trade-commission', 'reasons').call((response: any) => {
            setReasons(response.data)
        });
    }, []);

    const {
        id, setId,
        userId, setUserId,
        accountId, setAccountId,
        paidAt, setPaidAt,
        typeId, setTypeId,
        reasonId, setReasonId,
        sum, setSum,
        percent, setPercent,

        formErrors, changeFormErrors,
        hasError, getError,
        closeForm, clearForm,
    } = useTradeCommissionForm(form, client, setModalVisible, setForm);

    const { handleDelete, handleUpdate, handleCreate } = useTradeCommissionActions(
        id, setId,
        tradeId,
        userId,
        accountId,
        paidAt,
        typeId,
        reasonId,
        sum,
        percent,
        closeForm, callback,
        changeFormErrors, loading, setLoading
    );

    const renderField = (typeId: number)=>
    {
        switch (typeId)
        {
            case TradeCommissionConstants.FIX:
                return renderFixForm();
            case TradeCommissionConstants.PERCENT:
                return renderPercentForm();
        }
    }

    const renderFixForm = () =>
    {
        return <Fragment>
            <InputContainer error={getError('sum')}>
                <NumberInput
                    id='trade-commission-sum'
                    value={sum}
                    //@ts-ignore
                    onChange={setSum}
                    placeholder={'Сумма'}
                />
            </InputContainer>
        </Fragment>
    }

    const renderPercentForm = () =>
    {
        return <Fragment>
            <InputContainer error={getError('percent')}>
                <NumberInput
                    id='trade-commission-percent'
                    value={percent}
                    onChange={setPercent}
                    placeholder={'Процент'}
                />
            </InputContainer>
            <div style={{flex: '1'}}/>
        </Fragment>
    }


    const renderButtons = () => {
        if (id) {
            return <Row>
                <DeleteButton handleDelete={handleDelete} />
                <Row style={{ display: 'block', textAlign: 'center' }}>
                    <Button type="cancel" withMargin narrow onClick={closeForm}>
                        Отмена
                    </Button>
                    <Button withMargin narrow loading={loading} onClick={handleUpdate}>
                        Сохранить
                    </Button>
                </Row>
            </Row>
        } else {
            return <Row style={{ display: 'block', textAlign: 'center' }}>
                <Button type="cancel" withMargin narrow onClick={closeForm}>
                    Отмена
                </Button>
                <Button withMargin narrow loading={loading} onClick={handleCreate}>
                    Сохранить
                </Button>
            </Row>
        }
    }

    let groupedAccounts = getAccounts(accounts)
    let filteredAccounts = Account.getFiltered(accountId, groupedAccounts, AccountConstants.TYPES);

    // @ts-ignore
    return <ModalForm
        header={
            id
                ? 'Редактирование комиссии'
                : 'Новая комиссия'
        }
        modalVisible={modalVisible}
        closeForm={closeForm}
        width='886px'
    >
        <>
            <Row>
                <SelectAccountWrapper
                    types={AccountConstants.types().map(type => type.id)}
                    placeholder='Счет'
                    id={'trade-commission-account'}
                    value={accountId}
                    onChange={(item: any) => {
                        if(item)
                        {
                            setAccountId(item.id)
                        }
                    }}
                    options={filteredAccounts}
                    error={getError('account_id')}
                />
            </Row>
            <Row>
                <InputContainer error={getError('reason_id')}>
                    <Search
                        name="reason_id"
                        id='trade-commission-reason'
                        placeholder='Вид комиссии'
                        options={reasons}
                        value={reasonId}
                        onChange={(searchInput: any): void => {
                            if (searchInput) {
                                setReasonId(searchInput.id)
                                setSearchReason(searchInput.name)
                            }
                        }}
                        search={searchReason}
                        onSearch={setSearchReason}
                    />
                </InputContainer>
                <InputContainer style={{border: 'none'}}>
                    <Select
                        id='trade-commission-type'
                        //@ts-ignore
                        value={typeId}
                        onChange={(item: any) => {
                            if(item){
                                setTypeId(item.id)
                            }
                        }}
                        options={types}
                        placeholder={'Тип комиссии'}
                    />
                </InputContainer>
            </Row>
            <Row>
                {renderField(typeId)}
                <InputContainer error={getError('trade_at')}>
                    <DateTimeInput
                        id='trade-commission-paid_at'
                        value={paidAt}
                        onChange={setPaidAt}
                        placeholder='Дата и время'
                    />
                </InputContainer>
            </Row>
        </>
        {renderButtons()}
    </ModalForm>
}


const enhance = connect(
    state => ({
        // @ts-ignore
        client: state.interfaceComponents.client,
        // @ts-ignore
        currencies: state.interfaceComponents.currencies,
        // @ts-ignore
        accounts: state.account.accounts,
    }),
    {}
);

export default enhance(TradeCommissionForm);