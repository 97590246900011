import React from 'react';
import {Sign} from '../../../deprecated/styles'
import {Row} from "#app/pages/styles";
import EqualSvg from "#app/assets/icons/equal.svg";
import MultiSvg from "#app/assets/icons/multi.svg";
import {InputContainer, NumberInput} from "finform";


//TODO не учитывает лотность
const CommonFormFields = ({
                              sum, setSum,
                              price, setPrice,
                              count, setCount,
                              isSumBlocked,
                              isPriceBlocked,
                              isCountBlocked,
                              getError,
                          }) => {
    return <Row>
        <InputContainer error={getError('price')}>
            <NumberInput
                id='price'
                placeholder='Цена'
                value={price}
                onChange={setPrice}
                disabled={isPriceBlocked}
            />
        </InputContainer>
        <Sign><img src={MultiSvg} alt=''/></Sign>
        <InputContainer error={getError('count')}>
            <NumberInput
                id='count'
                placeholder='Количество лотов'
                value={count}
                onChange={setCount}
                disabled={isCountBlocked}
                min={0}
            />
        </InputContainer>
        <Sign><img src={EqualSvg} alt=''/></Sign>
        <InputContainer error={getError('sum')}>
            <NumberInput
                id='sum'
                placeholder='Сумма'
                value={sum}
                onChange={setSum}
                disabled={isSumBlocked}
            />
        </InputContainer>
    </Row>
}

export default CommonFormFields

