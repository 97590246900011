import React, {useState} from "react";
import {Popup} from "#app/interface/cabinet/header/Dropdown/styles";

const InfoPopup = ({ content, id }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    return (
        <div
            onMouseOver={() => setIsPopupOpen(true)}
            onMouseLeave={() => setIsPopupOpen(false)}
        >
            <img
                style={{
                    width: '20px',
                    cursor: 'pointer',
                }}
                src={require('src/assets/icons/info.svg').default}
                alt=""
            />
            <Popup
                open={isPopupOpen}
                id={id}
                className="asset-popup"
                top={'-10px'}
                left={'24px'}
                width={'400px'}
            >
                <div style={{ padding: '10px 20px', minWidth: 'fit-content' }}>
                    Баланс по счету выведен на дату {content}. Учет баланса будет вестись с этой даты.
                </div>
            </Popup>
        </div>
    );
};

export default InfoPopup;