import {lazy} from 'react';

// Ленивая загрузка страниц
export const MainPage = lazy(() => import('./../pages/MainPage/MainPage'));
export const Dashboard = lazy(() => import('src/pages/Dashboard/Dashboard'));
export const OldBalance = lazy(() => import('src/pages/Dashboard/OldBalance'));
export const Dynamic = lazy(() => import('../pages/Accounting/Dynamic/Dynamic'));
export const Invests = lazy(() => import('src/pages/Investing/Invests/Invests'));
export const Case = lazy(() => import('../pages/Investing/Case/Case'));
export const ArchiveInvests = lazy(() => import('src/pages/Investing/Invests/ArchiveInvests'));
export const Incomes = lazy(() => import('../pages/IncomeAndExpenses/Incomes/Incomes'));
export const Goals = lazy(() => import('../pages/Planing/Goals/Goals'));
export const Plan = lazy(() => import('../pages/Planing/Plan/Plan'));
export const SalaryPlan = lazy(() => import('../pages/Planing/SalaryPlan/SalaryPlan'));
export const Spendings = lazy(() => import('../pages/IncomeAndExpenses/Spendings/Spendings'));
export const Tactics = lazy(() => import('../pages/IncomeAndExpenses/Tactics/Tactics'));
export const Logs = lazy(() => import('../pages/Accounting/Logs/Logs'));
export const Clients = lazy(() => import('../pages/Clients/Clients'));
export const Insurances = lazy(() => import('../pages/Accounting/Insurances/Insurances'));
export const RoundDiagramsAndGroups = lazy(() => import('../pages/Investing/RoundDiagramsAndGroups'));
export const TradingViewChart = lazy(() => import('../pages/Investing/TradingViewChart/TradingViewChart'));
export const RoundDiagramView = lazy(() => import('../pages/Investing/RoundDiagrams/RoundDiagramView'));
export const CommonProperty = lazy(() => import('../pages/Accounting/Common'));
export const CommonConfig = lazy(() => import('../pages/Config/Common'));
export const Notifications = lazy(() => import('../pages/NotificationPage/Notifications'));
export const Page1 = lazy(() => import("../pages/RegisterScenario/Page1/Page1"));
export const Forbidden = lazy(() => import("../interface/error/Forbidden/index"));
export const UnexpectedError = lazy(() => import("../interface/error/UnexpectedError/index"));
export const Ui = lazy(() => import("../interface/Ui/Ui"));
export const Quizes = lazy(() => import("./../pages/QuizPage/Quizes"));

// Ленивая загрузка компонентов
export const HeaderCabinet = lazy(() => import('../interface/cabinet/header/Header'));
export const DemoSidebar = lazy(() => import('../interface/cabinet/sidebar/DemoSidebar'));
export const SidebarCabinet = lazy(() => import('../interface/cabinet/sidebar/Sidebar'));
export const AuthLayout = lazy(() => import('./AuthLayout/AuthLayout'));
export const Scenario = lazy(() => import('../interface/scenario/Scenario'));
export const Browser = lazy(() => import('../interface/browser/Browser'));
export const Notification = lazy(() => import('../interface/notification/Notification'));
export const CallbackNotification = lazy(() => import('../interface/notification/CallbackNotification'));