import {Component} from 'react';

class Form extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            load: false,
            recaptchaLoaded: false,
            formErrors: {},
        };
    }

    componentDidMount() {

    }

    asyncScriptOnLoad = () => {
        this.setState({ recaptchaLoaded: true });
    };

    onCaptchaChange = val => {
        if(val !== null)
        {
            this.setState({
                captchaValue: val
            }, () => {
                this.submitForm();
            });
        }
    };

    title()
    {

    }

    submitForm()
    {

    }

    getError = (fieldName) => {
        if (this.state.formErrors && typeof this.state.formErrors[fieldName] !== 'undefined' && this.state.formErrors[fieldName].length > 0) {
            return this.state.formErrors[fieldName][0];
        }
    }
}

export default Form