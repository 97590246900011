import {call, put, select, takeLatest} from 'redux-saga/effects'
import * as accountActions from "#app/actions/account";
import AccountsData from "#app/pages/Accounting/Accounts/data/accounts";
import {Api} from "laravel-request";

function* getUserAccounts(action) {
    try {
        const state = yield select();

        if(state.scenario.tutorial)
        {
            yield put(accountActions.handleUserAccounts({result: 'success', data: AccountsData.data}));
        }else{
            if(state.interfaceComponents.client)
            {
                const promise = new Promise((resolve, reject) => {
                    Api.get('user-account', 'index', {user_id: state.interfaceComponents.client.id})
                        .where('is_visible', 1)
                        .with('accounts')
                        .with('accounts.blocked')
                        .with('accounts.blocked.operation')
                        .with('accounts.blocked.operation.active_relation')
                        .with('accounts.blocked.operation.active_relation.buy_trades')
                        .orderBy('type_id')
                        .all((response) => {
                            resolve(response);
                        }, () => {
                            reject();
                        }, (

                        ) => {

                        })
                });

                let response = yield promise;
                yield put(accountActions.handleUserAccounts(response));
            }else{
                yield put(accountActions.handleUserAccounts({result: 'success', data: []}));
            }
        }

        if(action.payload && typeof action.payload.callback === "function")
        {
            yield call(action.payload.callback);
        }
    } catch (err) {
        console.error(err);
    }
}

export default function* watcher() {
    yield takeLatest(accountActions.getUserAccounts, getUserAccounts)
}
