import React from 'react';
import {Row} from "#app/pages/styles";
import {GroupSelect, InputContainer} from "finform";
import {getAccounts} from "#app/pages/Relations/Trades/form/utils/accounts";
import {Account, AccountConstants} from "finhelper";
import AddAccountButton from "#app/pages/Relations/components/AddAccountButton";
import SelectAccountWrapper from "#app/pages/Relations/components/SelectAccountWrapper";

const CurrencyAccounts = ({
                            accounts,
                            fromAccountId,
                            setFromAccountId,
                            toAccountId,
                            setToAccountId,
                            getError,
                        }) => {

    let groupedAccounts = getAccounts(accounts)

    let fromAccounts = Account.getFiltered(fromAccountId, groupedAccounts, AccountConstants.TYPES);
    let toAccounts = Account.getFiltered(toAccountId, groupedAccounts, AccountConstants.TYPES);

    return <Row>
        <SelectAccountWrapper
            types={AccountConstants.types().map(type => type.id)}
            placeholder='Счет списания'
            id={'from_account'}
            value={fromAccountId}
            onChange={(item) => {
                if(item)
                {
                    setFromAccountId(item.id);
                }
            }}
            options={fromAccounts}
            error={getError('from_account_id')}
        />
        <SelectAccountWrapper
            types={AccountConstants.types().map(type => type.id)}
            placeholder='Счет зачисления'
            id={'to_account'}
            value={toAccountId}
            onChange={(item) => {
                if(item)
                {
                    setToAccountId(item.id);
                }
            }}
            options={toAccounts}
            error={getError('to_account_id')}
        />
    </Row>
}

export default CurrencyAccounts;

