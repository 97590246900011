import React from 'react';
import {Modal as ModalStyled, ModalClose, ModalContent, ModalFooter, ModalHeader, Overlay, Wrapper} from './styles';
import Portal from "../portal/Portal";
import ModalLoader from "./ModalLoader";

type ModalSize = 'extra-small' | 'very-small' | 'small' | 'medium' | 'large' | 'extra-large' | 'avatar';

interface ModalProps {
  size?: ModalSize;
  show: boolean;
  name?: string;
  trigger?: React.ReactNode;
  children: React.ReactNode;
}

interface ModalState {
  show: boolean;
  fade: boolean;
}

class Modal extends React.PureComponent<ModalProps, ModalState> {
  static modals: { [key: string]: Modal } = {};
  static pageYOffset = 0;

  constructor(props: ModalProps) {
    super(props);

    this.state = {
      show: props.show,
      fade: props.show
    }

    if (props.name) {
      Modal.modals[props.name] = this;
    }
  }

  componentDidUpdate(prevProps: ModalProps) {
    if (this.props.show !== prevProps.show) {
      this.props.show ? this.show() : this.hide();
    }
  }

  static show(name: string) {
    if (this.modals[name]) {
      this.modals[name].show(() => this.handleShow());
    }
  }

  static hide(name: string) {
    if (this.modals[name]) {
      this.handleClose();
      this.modals[name].hide();
    }
  }

  show(callback: () => void = () => {}) {
    this.setState({
      show: true
    }, callback);
  }

  hide() {
    this.setState({
      show: false
    });
  }

  static handleShow() {
    if (Modal.pageYOffset === 0) {
      Modal.pageYOffset = window.scrollY;
    }

    const modalElement = document.getElementById('modal');
    if (modalElement) {
      modalElement.classList.add("visible");
    }
  }

  static handleClose() {
    const modalElement = document.getElementById('modal');
    if (modalElement) {
      modalElement.classList.remove("visible");
    }
  }

  render() {
    if (this.state.show) {
      return (
          <>
            {this.props.trigger}
            <Portal id={'modal'}>
              <Overlay className='modal'>
                <Wrapper>
                  {/*@ts-ignore*/}
                  <ModalStyled style={this.props.style} id={this.props.id} size={this.props.size} onClick={(e) => {
                    e.stopPropagation();
                  }}>
                    <div>
                      {this.props.children}
                    </div>
                  </ModalStyled>
                </Wrapper>
              </Overlay>
            </Portal>
          </>
      );
    } else {
      return this.props.trigger ?? null;
    }
  }
}

export default Modal;

export { Modal, ModalHeader, ModalContent, ModalFooter, ModalClose, ModalLoader };