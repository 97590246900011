import styled, {keyframes} from 'styled-components'
import zindex from "#app/interface/zindex";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
export const Overlay = styled.div.attrs({className: 'overlay-container'})`
  overflow-y: auto;
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  z-index: ${zindex.modal};
  background-color: rgba(0, 0, 0, .7);
  overflow-x: hidden;

  & > div {
    position: absolute;
    height: fit-content;
    min-height: 1400px;
    top: 0 !important;
    left: 0 !important;
  }
`

export const Wrapper = styled.div`
  position: relative;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100vh;
  line-height: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: ${zindex.modal};
`

export const Modal = styled.div`
  background-color: #fff;
  display: inline-block;
  position: relative;
  transition: opacity 1s;
  max-width: 786px;
  border-radius: 24px;
`

export const ModalHeader = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: 20px 20px 0 40px;
  position: relative;
  color: #363636;
  flex-grow: 1;
  font-size: 24px;
  line-height: 1;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  font-weight: 600;
  height: 60px;

  & > div {
    width: 100%;
    text-align: left;
    color: #000;
  }
`


export const ModalFooter = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-start;
  padding: 5px 20px 20px 20px;
  position: relative;
  color: #363636;
  flex-grow: 1;
  font-size: 1.5rem;
  flex-wrap: wrap;
  line-height: 1;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;

  ${props => props.type === 'scroll' && `
    overflow: auto;
    height: 600px;
  `}
`

export const ModalClose = styled.span`
  background-color: #fff;
  padding: 4px;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 72px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  line-height: 20px;
`

export const ModalLoader = styled.span`
  position: absolute;
  top: 30px;
  left: 30px;
  animation: ${rotate} 2s linear infinite;
`
