import {css} from 'styled-components'

export const minWidth = '1500px';

export const widthContentStyle = css`
  @media (min-width: 750px) {
    min-width: 690px;
  }
  @media (min-width: 900px) {
    min-width: 800px;
    max-width: 840px;
  }
  @media (min-width: 1000px) {
    min-width: 940px;
    max-width: 940px;
  }
  @media (min-width: 1100px) {
    min-width: 1040px;
    max-width: 1040px;
  }
  @media (min-width: 1200px) {
    min-width: 1050px;
    max-width: 1050px;
  }
  @media (min-width: 1330px) {
    min-width: 1100px;
    max-width: 1100px;
  }
  @media (min-width: 1430px) {
    min-width: 1180px;
    max-width: 1180px;
  }
  @media (min-width: 1540px) {
    min-width: 1300px;
    max-width: 1350px;
  }
  @media (min-width: 1600px) {
    min-width: 1300px;
    max-width: 1300px;
  }
  @media (min-width: 1900px) {
    min-width: 1380px;
    max-width: 1380px;
  }
  @media (min-width: 2100px) {
    min-width: 1550px;
    max-width: 1550px;
  }
`

export const widthHeaderStyle = css`
  @media (min-width: 750px) {
    min-width: 690px;
    margin-left: 30px;
    margin-right: 30px;
  }
  @media (min-width: 900px) {
    min-width: 840px;
    margin-left: 30px;
    margin-right: 30px;
  }
  @media (min-width: 1000px) {
    min-width: 900px;
    margin-left: 30px;
    margin-right: 30px;
  }
  @media (min-width: 1330px) {
    min-width: 1180px;
    margin: 0 auto;
  }
  @media (min-width: 1430px) {
    min-width: 1360px;
    margin: 0 auto;
  }
  @media (min-width: 1500px) {
    min-width: 1430px;
    margin: 0 auto;
  }
  @media (min-width: 1900px) {
    min-width: 1600px;
    margin: 0 auto;
  }
`

export const marginStyle = css`
  @media (min-width: 700px) {
    //margin-left: 30px;
    //margin-right: 30px;
  }
  @media (min-width: 1000px) {
    //margin-left: 30px;
    //margin-right: 30px;
  }
  @media (min-width: 1330px) {
    //margin-left: 30px;
    //margin-right: 30px;
  }
  @media (min-width: 1430px) {
    //margin-left: 30px;
    //margin-right: 30px;
  }
  @media (min-width: 1500px) {
    margin: 0 auto;
  }
  @media (min-width: 1900px) {
    margin: 0 auto;
  }
`

export const fontSizeStyle = css`
  @media (max-width: 1430px) {
    font-size: 10em;
  }
`